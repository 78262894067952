import viewerCesiumNavigationMixin from "@cmcleese/cesium-navigation";
import tokml from "geojson-to-kml";
import { unionBy, debounce } from "lodash";
import secureLocalStorage from "react-secure-storage";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import earcut from "earcut";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import Slider from "@material-ui/core/Slider";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CameraIcon from "@material-ui/icons/Camera";
import vrCardboard from "@iconify/icons-fa6-solid/vr-cardboard";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MapIcon from "@material-ui/icons/Map";
import ErrorIcon from "@material-ui/icons/Error";
import FormatShapesIcon from "@material-ui/icons/FormatShapes";
import LayersIcon from "@material-ui/icons/Layers";
import MenuIcon from "@material-ui/icons/Menu";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import SyncIcon from "@material-ui/icons/Sync";
import UndoIcon from "@material-ui/icons/Undo";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Progress, Radio } from "antd";
import {
  EllipsoidGeodesic,
  ImageMaterialProperty,
  Transforms,
  Ray,
  HeadingPitchRoll,
  CameraEventType,
  createOsmBuildingsAsync,
  NearFarScalar,
  PointPrimitiveCollection,
  KmlDataSource,
  PolylineCollection,
  Material,
  Model,
  DirectionalLight,
  defined,
  CallbackProperty,
  HeadingPitchRange,
  PointGraphics,
  BoundingSphere,
  Cartesian2,
  Cartesian3,
  Cesium3DTileset,
  Color,
  Ion,
  Rectangle,
  TileMapServiceImageryProvider,
  Cartographic,
  Matrix4,
  sampleTerrainMostDetailed,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Cesium3DTileStyle,
  HeightReference,
  ImageryLayer as ImageryLayerCesium,
  WebMapServiceImageryProvider,
  WebMapTileServiceImageryProvider,
  Resource,
  Viewer,
  CesiumTerrainProvider,
  SplitDirection,
  WebMercatorTilingScheme,
  Ellipsoid,
  HorizontalOrigin,
  VerticalOrigin,
  Math as CesMath,
  SceneTransforms,
  Entity,
  JulianDate,
} from "cesium";
import clsx from "clsx";
import { fromBlob } from "geotiff";
import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  forwardRef,
} from "react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import { GithubPicker } from "react-color";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";
import {
  addNewFolder,
  addNewLayer,
  addNewMedia,
  addNewProject,
  addNewSubFolder,
  deleteFolder,
  deleteLayer,
  deleteMedia,
  deleteProject,
  disableTips,
  getFeatureAttr,
  getFeatureMedia,
  getProjectsDetails,
  getSignedUrl,
  getSingleLayer,
  moveFolder,
  moveLayer,
  updateFolder,
  updateFolderDateCollected,
  updateLayer,
  updateLayerAttributes,
  updatePointCloudLayerAttributes,
  updateLayerShow,
  updatePointIcon,
  updateProject,
  updateFolderShow,
  updateVectorStyles,
  enableEula,
  getUserDetail,
  getBulkLayers,
  getImagesFolder,
  updateLayerAttributesFolder,
  getFeatureAttrFolder,
  postImgTag,
  editVector,
  updateLayerDateCollected,
  downloadFolder,
  fetchUrlMeta,
} from "../../services/user.service";
import {
  API_URL,
  config,
  findPath,
  getYoutubeId,
  insertItemInProject,
  removeItemFromProject,
  updateItemInProject,
  updateItemInProjectTileSet,
  updateItemInProjectImages,
  updateFolderInProjectdateCollected,
  validURL,
  ICON_URL,
  BASEMAP_INDEX,
  sortFnc,
} from "./../common/common";
import UserSupport from "./UserSupport";
import { authHeader, getUserId } from "../../services/auth-header";
import { ColorLensOutlined, SettingsSystemDaydream } from "@material-ui/icons";
import {
  point,
  buffer,
  bbox,
  polygon,
  area,
  pointGrid,
  lineString,
  length,
} from "@turf/turf";
import "../styles/customMap.css";
import Draggable from "react-draggable";
import { logout } from "../../actions/auth";
import RenderChildrenComp from "./subcomponents/Map/LayerPanel/RenderChildrenComp";
import InfoPanelComp from "./subcomponents/Map/InfoPanel/InfoPanelComp";
import MapConfigs from "./subcomponents/Map/Configs/Configs";
import MetaUploader from "./subcomponents/Map/Uploader/Uploader";
import MoveMenu from "./subcomponents/Map/Menus/MoveMenu";
import FileActionMenu from "./subcomponents/Map/Menus/FileActionMenu";
import ProjectActionMenu from "./subcomponents/Map/Menus/ProjectActionMenu";
import DialogComp from "./subcomponents/Map/Dialogs/DialogComp";
import { DataContext } from "../assets/store/DataContext";
import MapTopButtons from "../components/subcomponents/Map/MapButtons/MapTopButtons";
import MapExpandButtons from "../components/subcomponents/Map/MapButtons/MapExpandButtons";
import ImageAnnotate from "../components/subcomponents/Map/ImageAnnotate/ImageAnnotate";
import Image360 from "../components/subcomponents/Map/Image360/Image360";
import SplitScreen from "./subcomponents/Map/SplitScreen/SplitScreen";
import pinkMarker from "../images/pinkIcon.png";
import IconStyles from "./icons-style";
import CustomPicker from "./subcomponents/ColorPicker/ColorPicker";
import { Autocomplete } from "@material-ui/lab";
import { Button, Chip, Input, TextField } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import HoverIframe from "./subcomponents/Map/HoverIframe/HoverIframe";
import {
  FlyToHeadingPitchRange,
  FrustumFlyToFn,
} from "./subcomponents/Map/MapFunctions/CesiumFunctions";

var AWS = require("aws-sdk");

Ion.defaultAccessToken =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyNDJiYTZmYy1lOWIzLTQxOGMtODEwZC03NTgyZjAzNjc3NTciLCJpZCI6MTY2NzYxLCJpYXQiOjE2OTQ4MjIwNzB9.L7lHp3f1fj2ehfV68Z8-fxBP5xeWMT5wfiY6eISrN-I";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
function filter(array, fn) {
  return array.reduce((r, o) => {
    var children = filter(o.children || [], fn);
    if (fn(o) || children.length)
      r.push(Object.assign({}, o, children.length && { children }));
    return r;
  }, []);
}

const tileLoadEventListnerForPointCloudArray = [];
let leftKeyPressed = false;
let cloneprojid, clonefolderid;
let rightKeyPressed = false;
let escKeyPressed = false;
let infoLayerSelectedVar = null;
let vectorLayerId = null;
let measureSelected2 = false;
let editSelected2 = false;
let isMouseHover = false;
let infoHoverTimeout = false;
let popoverTimeout = false;
let infoLayerId = null;
let totalDist = 0;
let infoLayerFeatureId = null;
let selectedTileSetsCamerChange = [];
let selectedKmlCamerChange = [];
let selectedPrimitive;
let tileset, locationInterval;
let pickedEntity;
let dragDetected = false;
let waypointPosition;
let allEntitiesDrag = [];
let routeEntity;
// let vectorName = "";
let selectedItem = null;
let reloadCheck = null;
const drawerWidth = window.matchMedia("(max-width: 767px)").matches ? 260 : 350;
let allFolders = [];
let initialPositions = [];
let initialPositionsNew = [];
let openAccordians1 = [];
let openAccordians2 = [];
let openAccordiansUploader = [];
let currentSection = "";
let initialMeasurePositions = [];
let initialPoints = [];
let pendingImagery = [];
let pendingShareDownloads = [];
let loadedFiles = [];
let loadedEntitties = [];
let pendingVectors = [];
let initialMeasurePoints = [];
var earthRadiusMeters = 6371000.0;
var radiansPerDegree = Math.PI / 180.0;
var degreesPerRadian = 180.0 / Math.PI;
let metersToUSSurveyFeetConversion = 3.2808333333;
let nestedLayerArray = [];
let imageIndex = 0;
let allLayers = [];
let infoOffset = 0;
let imgArray = [];
var measureEllipsoid = Ellipsoid.WGS84;
var geodesic = new EllipsoidGeodesic();
let shareTileSetZoom = false;
var newMeasurePoints = [];
var labels = [];
var point1, point2;
var point1GeoPosition, point2GeoPosition, point3GeoPosition;
var polylines = [];
var polyline1, polyline2, polyline3;
var distanceLabel, verticalLabel, horizontalLabel;
var LINEPOINTCOLOR = Color.RED;
let mapFeatures = [];
let tilesetLayerId = [];
let temImgArray = [];
let editCheck = false;
let tempPointForLine = null;

var label = {
  font: "14px monospace",
  showBackground: true,
  horizontalOrigin: HorizontalOrigin.CENTER,
  verticalOrigin: VerticalOrigin.CENTER,
  pixelOffset: new Cartesian2(0, 0),
  eyeOffset: new Cartesian3(0, 0, -50),
  fillColor: Color.WHITE,
  backgroundColor: Color.WHITE,
};
var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
function SphericalPolygonAreaMeters(points) {
  var totalAngle = 0;
  for (var i = 0; i < points.length; i++) {
    var j = (i + 1) % points.length;
    var k = (i + 2) % points.length;
    totalAngle += Angle(points[i], points[j], points[k]);
  }
  var planarTotalAngle = (points.length - 2) * 180.0;
  var sphericalExcess = totalAngle - planarTotalAngle;
  if (sphericalExcess > 420.0) {
    totalAngle = points.length * 360.0 - totalAngle;
    sphericalExcess = totalAngle - planarTotalAngle;
  } else if (sphericalExcess > 300.0 && sphericalExcess < 420.0) {
    sphericalExcess = Math.abs(360.0 - sphericalExcess);
  }
  return (
    sphericalExcess * radiansPerDegree * earthRadiusMeters * earthRadiusMeters
  );
}

function Bearing(from, to) {
  var lat1 = from.x * radiansPerDegree;
  var lon1 = from.y * radiansPerDegree;
  var lat2 = to.x * radiansPerDegree;
  var lon2 = to.y * radiansPerDegree;
  var angle = -Math.atan2(
    Math.sin(lon1 - lon2) * Math.cos(lat2),
    Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2)
  );
  if (angle < 0) {
    angle += Math.PI * 2.0;
  }
  angle = angle * degreesPerRadian;
  return angle;
}

function Angle(p1, p2, p3) {
  var bearing21 = Bearing(p2, p1);
  var bearing23 = Bearing(p2, p3);
  var angle = bearing21 - bearing23;
  if (angle < 0) {
    angle += 360;
  }
  return angle;
}

const styles = makeStyles({
  tooltip: {
    fontSize: "1rem",
    fontWeight: "600",
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  radio: {
    "&$checked": {
      color: "#4B8DF8",
    },
  },
  checked: {},
  root1: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "absolute",
    top: 0,
    left: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  menuPaper: {
    scale: 0.8,
    //  backgroundColor:"red",
  },
  drawerHeader: {
    marginTop: 100,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
  backdrop: {
    zIndex: 999999999999999999,
    color: "#fff",
  },

  backdropImage: {
    zIndex: 999999999999999999,
    color: "#fff",
    // pointerEvents: "none",
  },
  backdropImageSetting: {
    margin: "10% 30%",
  },

  dragItem: {
    padding: "0px",
    marginBottom: "0px",
    border: 0,
    margin: "0px !important",
    boxShadow: "none",
    borderRadius: "4px",
  },
  iconButton: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const sortableOptions = {
  fallbackOnBody: true,
  swapThreshold: 0.65,
  ghostClass: "ghost",
  group: "shared",
  animation: 200,
  delayOnTouchStart: true,
  delay: 10,
  onEnd: function(/**Event*/ evt) {
    let itemEl = evt.item; // dragged HTMLElement
    let to = evt.to; // target list
    let from = evt.from; // previous list
    let f = evt.oldIndex; // element's old index within old parent
    let e = evt.newIndex; // element's new index within new parent
    let d = evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
    let c = evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
    let b = evt.clone; // the clone element
    let a = evt.pullMode; // when item is in another sortable: `"clone"` if cloning, `true` if moving
    if (to.id === from.id) {
      const fromObject = JSON.parse(itemEl.id);
      fromObject.to = e;
      // fromObject.from = f;
      if (f !== e) {
        // getIdFromString(fromObject);
        console.log(`Testing same list`, fromObject);
      }
    } else {
      const fromObject = JSON.parse(itemEl.id);
      fromObject.toObject = JSON.parse(to.offsetParent?.id);
      fromObject.action = fromObject.action + "-cross";
      fromObject.to = e;
      // fromObject.from = f;
      // getIdFromString(fromObject);
      console.log(`Testing different list`, fromObject);
    }
    // console.log(`Testing Moved : \nItem : ${itemEl.id} \nTo :${to.id}\nTop :${to.offsetParent?.id}`);
  },

  onMove: function(/**Event*/ evt, /**Event*/ originalEvent) {
    let to = evt.to; // target list
    let from = evt.from; // previous list
    let itemEl = evt.item;
    // console.log("Testing", to.id, from.id);
    if (to.id === from.id) {
      return true;
    }
    try {
      const itemObject = JSON.parse(evt.dragged.id);
      const toObject = JSON.parse(to.offsetParent?.id);

      if (itemObject.projectId === toObject.projectId) {
        if (itemObject.action === "layer-order") {
          if (toObject.action === "project-order") {
            return false;
          }
        }
        return true;
      }
    } catch (e) {
      return false;
    }

    return false;
  },
};
let actionState = {
  project: null,
  item: null,
  anchorPosition: {},
};
let actionStateProj = {
  project: null,
  item: null,
  anchorPosition: {},
};

const iconStyle = { fontSize: 25, color: "#901da1", marginRight: "10px" };

export const MapFn = (props) => {
  let history = useHistory();
  // const { state, setState } = props;

  const { appState, setAppState } = props;

  if (sessionStorage.getItem("reloadMap") == "1") {
    sessionStorage.setItem("reloadMap", 0);
    window.location.reload();
  }
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.message);
  const theme = useTheme();

  const mapRef = useRef();
  const childRef = useRef(null);
  const childRef1 = useRef(null);
  const measureRef = useRef(null);
  const coordinatesRef = useRef({ lat: 0, lng: 0 });
  const { isOrganizationAdmin } = useSelector((state) => state.auth);
  const [viewer, setViewer] = useState(null);
  const [sortType, setSortType] = useState(
    localStorage.getItem("sortType") ? localStorage.getItem("sortType") : 1
  );
  const [linkIframe, setLinkIframe] = useState(null);
  const [pendingDownCount, setPendingDownCount] = useState(0);
  const [markerURL, setMarkerURL] = useState(
    API_URL == "https://devapi.metageo.io/"
      ? "https://dev.metageo.io/images/greenMarker.png"
      : "https://map.metageo.io/images/greenMarker.png"
  );
  const [searchVal, setSearchVal] = useState(null);
  const [device, setDevice] = useState();
  const [resizeBtn, setResizeBtn] = useState(true);
  const [showTerrain, setShowTerrain] = useState(true);
  const [open, setOpen] = useState(false);
  const [locationIntervalFlag, setLocationIntervalFlag] = useState(false);
  const [buildings, setBuildings] = useState(false);
  const [imageViewer, setImageViewer] = useState(false);
  const [imageViewer360, setImageViewer360] = useState(false);
  const [imageFolderArray, setImageFolderArray] = useState([]);
  const [progress, setProgress] = useState(null);
  const [mediaAccordian, setMediaAccordian] = useState(true);
  const [linkAccordian, setLinkAccordian] = useState(true);
  const [attrAccordian, setAttrAccordian] = useState(true);
  const [VRenable, setVRenable] = useState(false);
  const [vectorEditted, setVectorEditted] = useState(false);
  const [uploadMediaDrawer, setUploadMediaDrawer] = useState(false);
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const [dateCollectedDrawer, setDateCollectedDrawer] = useState({
    open: false,
    confirm: false,
  });
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [uploadFiles, setUploadfiles] = useState([]);
  const [doneUploads, setDoneUploads] = useState([]);
  const [failedUploads, setFailedUploads] = useState([]);
  const [eventHandler, seteventHandler] = useState(null);
  const [eventHandlerSlider, seteventHandlerSlider] = useState(null);
  const [uploadFilesImagery, setUploadFilesImagery] = useState([]);
  const [newMediaUploadFiles, setNewMediaUploadfiles] = useState([]);
  const [sideNav, setSideNav] = useState(false);
  const [rightDrawer, setRightDrawer] = useState(0);
  const [measureUnit, setMeasureUnit] = useState({
    length: "m",
    area: "m",
    lengthCoe: 1,
    areaCoe: 1,
  });
  const [anchorElLayer, setAnchorElLayer] = useState(null);
  const openLayer = Boolean(anchorElLayer);
  const [editSelected, setEditSelected] = useState(false);
  const [editSelectedDouble, setEditSelectedDouble] = useState(false);
  const [measureSelected, setMeasureSelected] = useState(false);
  const [layerTypeSelected, setLayerTypeSelected] = useState("");
  const [editEntity, setEditEntity] = useState(null);
  const [section, setSection] = useState("");
  const [tipsDialog, setTipsDialog] = useState(false);
  const [eulaDialog, setEulaDialog] = useState(false);
  const [storageDialog, setStorageDialog] = useState(false);
  const [addWMSDialog, setAddWMSDialog] = useState(false);
  const [layerPanelSelected, setLayerPanelSelected] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [infoLayerProperties, setInfoLayerProperties] = useState([]);
  const [infoLayerSelected, setInfoLayerSelected] = useState("");
  const [editableVector, setEditableVector] = useState(false);
  const [dateCreated, setDateCreated] = useState("");
  const [dateInfoPanel, setDateInfoPanel] = useState({
    dateCreated: null,
    dateUpdated: null,
    dateCollected: null,
  });

  const [infoLayerSelectedWMSName, setInfoLayerSelectedWMSName] = useState("");
  const [infoLayerSelectedId, setInfoLayerSelectedId] = useState("");
  const [infoLayerSelectedFeatureId, setInfoLayerSelectedFeatureId] = useState(
    ""
  );
  const [infoLayerSelectedMedia, setInfoLayerSelectedMedia] = useState([]);
  const [infoLayerSelectedName, setInfoLayerSelectedName] = useState("");
  const [fillColor, setFillColor] = useState("");
  const [pointCloudSize, setPointCloudSize] = useState(0);
  const [tileSetHeight, setTileSetHeight] = useState(null);
  const [tileSetHeightDefault, setTileSetHeightDefault] = useState(0);
  const [strokeColor, setStrokeColor] = useState("");
  const [strokeTrans, setStrokeTrans] = useState("");
  const [positions, setPositions] = useState([]);
  const [distance, setDistance] = useState(0);
  const [area, setArea] = useState(0);
  const [altMode, setAltMode] = useState("absolute");
  const [points, setPoints] = useState([]);
  const [measurePositions, setMeasurePositions] = useState([]);
  const [infoLayerTags, setInfoLayerTags] = useState([]);
  const [infoLayerLinks, setInfoLayerLinks] = useState([]);
  const [infoLayerDes, setInfoLayerDes] = useState("");
  const [measurePoints, setMeasurePoints] = useState([]);
  const [polygonBackground, setPolygonBackground] = useState({
    hex: "#ffffff",
    setColor: "#ffffff",
    a: 1,
    b: 255,
    g: 255,
    r: 255,
  });
  const [fillTrans, setFillTrans] = useState(1);
  const [lineColor, setLineColor] = useState({
    hex: "#ffffff",
    setColor: "#ffffff",
  });
  const [lineWidth, setLineWidth] = useState(3);

  // *** New State
  const [newUploadFiles, setNewUploadfiles] = useState([]);
  const [newFolderFiles, setFolderfiles] = useState([]);
  const [filePath, setFilePath] = useState("");
  // const [editType, setEditType] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElProj, setAnchorElProj] = useState(null);
  const [anchorElProjBtn, setAnchorElProjBtn] = useState(null);
  const [styleClassEdit, setStyleClassEdit] = useState({
    active: false,
    index: null,
    color: null,
  });
  const [chkPointCloudClassStyle, setChkPointCloudClassStyle] = useState(true);
  const [isShownClassList, setIsShownClassList] = useState({
    index: 1,
    show: false,
  });
  const heightColorList = MapConfigs.infoPanel.heightColorList;
  const [selectedColorPallet, setSelectedColorPallet] = useState(
    heightColorList[0]
  );
  const defaultStyleArray = MapConfigs.infoPanel.defaultStyleArray;
  const [styleClass, setStyleClass] = useState([...defaultStyleArray]);
  const [styleMode, setStyleMode] = useState("rgb");
  const [heightSliderVal, setHeightSliderVal] = useState([0, 0.7]);
  const [displayItems, setDisplayItems] = useState(
    {
      name: "",
      editType: "",
      menuType: "",
      moveMenuDisplay: false,
      openFolderUploader: false,
      openFileUploader: false,
      openFolderDialog: false,
      openEditDialog: false,
      openProjectDialog: false,
      openActionDialog: false,
    },
    shallowEqual
  );

  const [eulaBanner, setEulaBanner] = useState(false);

  const [showPointCloudStyleChkBx, setShowPointCloudStyleChkBx] = useState(
    false
  );

  const [minzMaxz, setminzMaxz] = useState([0, 1]);
  const [selectedAltitudeMode, setSelectedAltitudeMode] = useState(
    "clampToGround"
  );
  const [selectedAltitudeModeKml, setSelectedAltitudeModeKml] = useState(
    "absolute"
  );

  const [imageIndexState, setImageIndexState] = useState(0);
  const [polygonGraphics, setPolygonGraphics] = useState(null);
  const [polygonGraphicsMeasure, setPolygonGraphicsMeasure] = useState(null);
  const [polyLineGraphics, setPolyLineGraphics] = useState([]);
  const [polyLineGraphicsMeasure, setPolyLineGraphicsMeasure] = useState(null);
  const [billBoardGraphics, setBillBoardGraphics] = useState(null);
  const [pointGraphics, setPointGraphics] = useState([]);
  const [showCompBtn, setShowCompBtn] = useState(false);
  const [rayCastLoader, setRayCastLoader] = useState(false);
  const [selectedImgViewerId, setSelectedImgViewerId] = useState(null);
  const [transparencyEnabled, setTransparencyEnabled] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  let lastUpdatedImageId;

  var options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const addDistanceLabel = (point1, point2, height) => {
    point1.cartographic = measureEllipsoid.cartesianToCartographic(
      point1.position
    );
    point2.cartographic = measureEllipsoid.cartesianToCartographic(
      point2.position
    );
    point1.longitude = parseFloat(CesMath.toDegrees(point1.position.x));
    point1.latitude = parseFloat(CesMath.toDegrees(point1.position.y));
    point2.longitude = parseFloat(CesMath.toDegrees(point2.position.x));
    point2.latitude = parseFloat(CesMath.toDegrees(point2.position.y));
    label.text = getHorizontalDistanceString(point1, point2);
    totalDist = totalDist + parseFloat(getDistanceStringMeters(point1, point2));
    setDistance(totalDist);
    label.fillColor = Color.ORANGE;
    horizontalLabel = viewer.entities.add({
      position: getMidpoint(point1, point2, point1GeoPosition.height),
      label: label,
    });
    labels.push(horizontalLabel);
    label.fillColor = Color.RED;
    label.text = getDistanceString(point1, point2);
    distanceLabel = viewer.entities.add({
      position: getMidpoint(point1, point2, height),
      label: label,
    });
    labels.push(distanceLabel);
    label.fillColor = Color.BLUE;
    label.text = getVerticalDistanceString();
    verticalLabel = viewer.entities.add({
      position: getMidpoint(point2, point2, height),
      label: label,
    });
    labels.push(verticalLabel);
  };

  function getHorizontalDistanceString(point1, point2) {
    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    var meters = geodesic.surfaceDistance.toFixed(1);
    meters =
      measureRef.current.value == "m"
        ? meters
        : measureRef.current.value == "km"
        ? (meters / 1000).toFixed(1)
        : measureRef.current.value == "miles"
        ? (meters / 1609.34).toFixed(1)
        : measureRef.current.value == "ft"
        ? (meters * 3.2808).toFixed(1)
        : meters;
    // if (meters >= 1000) {
    //   return (meters / 1000).toFixed(1) + " км";
    // }
    return meters + " " + measureRef.current.value;
  }
  function getDistanceStringMeters(point1, point2) {
    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    var horizontalMeters = geodesic.surfaceDistance.toFixed(2);
    var heights = [point1GeoPosition.height, point2GeoPosition.height];
    var verticalMeters =
      Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    var meters = Math.pow(
      Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2),
      0.5
    );
    return meters.toFixed(3);
  }

  function getVerticalDistanceString() {
    var heights = [point1GeoPosition.height, point2GeoPosition.height];
    var meters = Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    meters =
      measureRef.current.value == "m"
        ? meters.toFixed(1)
        : measureRef.current.value == "km"
        ? (meters / 1000).toFixed(1)
        : measureRef.current.value == "miles"
        ? (meters / 1609.34).toFixed(1)
        : measureRef.current.value == "ft"
        ? (meters * 3.2808).toFixed(1)
        : meters.toFixed(1);
    // if (meters >= 1000) {
    //   return (meters / 1000).toFixed(1) + " км";
    // }
    return meters + " " + measureRef.current.value;
  }

  function getDistanceString(point1, point2) {
    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    var horizontalMeters = geodesic.surfaceDistance.toFixed(2);
    var heights = [point1GeoPosition.height, point2GeoPosition.height];
    var verticalMeters =
      Math.max.apply(Math, heights) - Math.min.apply(Math, heights);
    var meters = Math.pow(
      Math.pow(horizontalMeters, 2) + Math.pow(verticalMeters, 2),
      0.5
    );
    meters =
      measureRef.current.value == "m"
        ? meters.toFixed(1)
        : measureRef.current.value == "km"
        ? (meters / 1000).toFixed(1)
        : measureRef.current.value == "miles"
        ? (meters / 1609.34).toFixed(1)
        : measureRef.current.value == "ft"
        ? (meters * 3.2808).toFixed(1)
        : meters.toFixed(1);
    // if (meters >= 1000) {
    //   return (meters / 1000).toFixed(1) + " км";
    // }
    return meters + " " + measureRef.current.value;
  }

  function getMidpoint(point1, point2, height) {
    var scratch = new Cartographic();
    geodesic.setEndPoints(point1.cartographic, point2.cartographic);
    var midpointCartographic = geodesic.interpolateUsingFraction(0.5, scratch);
    return Cartesian3.fromRadians(
      midpointCartographic.longitude,
      midpointCartographic.latitude,
      height
    );
  }
  const handleChangeComplete = (color) => {
    const setColor =
      "rgba(" +
      color.rgb.r +
      "," +
      color.rgb.g +
      "," +
      color.rgb.b +
      "," +
      fillTrans +
      ")";
    color.rgb["hex"] = color.hex;
    color.rgb["setColor"] = setColor;
    setPolygonBackground(color.rgb);
  };
  const handleChangeCompleteLine = (color) => {
    const setColor =
      "rgba(" +
      color.rgb.r +
      "," +
      color.rgb.g +
      "," +
      color.rgb.b +
      "," +
      "1" +
      ")";
    color.rgb["hex"] = color.hex;
    color.rgb["setColor"] = setColor;
    setLineColor(color.rgb);
  };
  const SortIcon1 = ({ style }) => (
    <svg style={style} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m10.22 15.97-1.22 1.22v-12.19c0-.41-.34-.75-.75-.75s-.75.34-.75.75v12.19l-1.22-1.22c-.29-.29-.77-.29-1.06 0s-.29.77 0 1.06l2.5 2.5c.07.07.15.12.24.16s.19.06.29.06.19-.02.29-.06c.09-.04.17-.09.24-.16l2.5-2.5c.29-.29.29-.77 0-1.06s-.77-.29-1.06 0z" />
      <path d="m18.96 18.25-1.71-4.79c-.17-.43-.56-.71-1-.71s-.83.28-1 .73l-1.7 4.77c-.14.39.06.82.45.96s.82-.06.96-.45l.28-.78h2.03l.28.78c.11.31.4.5.71.5.08 0 .17-.01.25-.04.39-.14.59-.57.45-.96zm-3.19-1.77.48-1.34.48 1.34z" />
      <path d="m13.83 10.54c.19.44.59.71 1.05.71h3.13c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-2.39l2.82-2.93c.34-.36.44-.89.24-1.35-.19-.44-.59-.71-1.05-.71h-3.11c-.41 0-.75.34-.75.75s.34.75.75.75h2.39l-2.83 2.95c-.34.36-.43.88-.24 1.34z" />
    </svg>
  );
  const SortIcon2 = ({ style }) => (
    <svg style={style} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m8.78 4.47c-.07-.07-.15-.12-.24-.16-.18-.08-.39-.08-.57 0-.09.04-.17.09-.24.16l-2.5 2.5c-.29.29-.29.77 0 1.06s.77.29 1.06 0l1.22-1.22v12.19c0 .41.34.75.75.75s.75-.34.75-.75v-12.19l1.22 1.22c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06l-2.5-2.5z" />
      <path d="m18.96 18.25-1.71-4.79c-.17-.43-.56-.71-1-.71s-.83.28-1 .73l-1.7 4.77c-.14.39.06.82.45.96s.82-.06.96-.45l.28-.78h2.03l.28.78c.11.31.4.5.71.5.08 0 .17-.01.25-.04.39-.14.59-.57.45-.96zm-3.19-1.77.48-1.34.48 1.34z" />
      <path d="m13.83 10.54c.19.44.59.71 1.05.71h3.13c.41 0 .75-.34.75-.75s-.34-.75-.75-.75h-2.39l2.82-2.93c.34-.36.44-.89.24-1.35-.19-.44-.59-.71-1.05-.71h-3.11c-.41 0-.75.34-.75.75s.34.75.75.75h2.39l-2.83 2.95c-.34.36-.43.88-.24 1.34z" />
    </svg>
  );
  const SortIcon3 = ({ style }) => (
    <svg style={style} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m21 17h3l-4 4-4-4h3v-14h2zm-13-1h3v-3h-3zm5-11h-1v-2h-2v2h-4v-2h-2v2h-1c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h10c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2m-10 13v-7h10v7z" />
    </svg>
  );
  const SortIcon4 = ({ style }) => (
    <svg style={style} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="m19 7h-3l4-4 4 4h-3v14h-2zm-11 9h3v-3h-3zm5-11h-1v-2h-2v2h-4v-2h-2v2h-1c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h10c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2m-10 13v-7h10v7z" />
    </svg>
  );
  function success(pos) {
    var crd = pos?.coords;

    // console.log("Your current position is:");
    // console.log(`Latitude : ${crd.latitude}`);
    // console.log(`Longitude: ${crd.longitude}`);
    locationInterval = setInterval(async () => {
      if (!isIOS) {
        if (navigator.geolocation) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(function(result) {
              if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(successInterval);
              } else if (result.state === "prompt") {
                navigator.geolocation.getCurrentPosition(
                  successInterval,
                  errorsInterval,
                  options
                );
              } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
              }
              result.onchange = function() {
                console.log(result.state);
              };
            });
        } else {
          // alert("Sorry Not available!");
        }
      } else {
        navigator.geolocation.getCurrentPosition(
          successInterval,
          errorsInterval,
          options
        );
      }
    }, 1000);
    setLocationIntervalFlag(!locationIntervalFlag);
    // setMyLocation({ x: crd.longitude, y: crd.latitude, show: true });
    //const viewer = mapRef.current.cesiumElement;
    let entity1 = viewer.entities._entities._array.filter(
      (locEnt) => locEnt._name == "MYLOCATION"
    )[0];
    let entity2 = viewer.entities._entities._array.filter(
      (locEnt) => locEnt._name == "MYLOCATION1"
    )[0];
    entity1.show = true;
    entity1.position = Cartesian3.fromDegrees(crd.longitude, crd.latitude);
    entity2.show = true;
    entity2.position = Cartesian3.fromDegrees(crd.longitude, crd.latitude);
    viewer.camera.flyTo({
      destination: Cartesian3.fromDegrees(crd.longitude, crd.latitude, 300),
      duration: 1,
    });
    // viewer.camera.flyToBoundingSphere(
    //   new BoundingSphere(Cartesian3.fromDegrees(crd.longitude, crd.latitude), 100),
    //   { duration: 1 }
    // );
  }
  function getNullFeature(arr) {
    let index = arr.findIndex((x) => x.featureId === null);
    return index;
  }
  function successInterval(pos) {
    var crd = pos?.coords;
    // const viewer = mapRef.current.cesiumElement;
    let entity1 = viewer.entities._entities._array.filter(
      (locEnt) => locEnt._name == "MYLOCATION1"
    )[0];
    viewer.entities._entities._array.filter(
      (locEnt) => locEnt._name == "MYLOCATION"
    )[0].position = Cartesian3.fromDegrees(crd.longitude, crd.latitude);
    entity1.position = Cartesian3.fromDegrees(crd.longitude, crd.latitude);
    if (entity1.ellipse.material._color._value.red == 0.5647058823529412) {
      entity1.ellipse.material = Color.fromCssColorString("#CA7FD5");
    } else {
      entity1.ellipse.material = Color.fromCssColorString("#901DA1");
    }
  }
  function errors(err) {
    alert("Please change location permission settings in your system");
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  function errorsInterval(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  const handleSelectedIconValue = (event) => {
    console.log("event.target.value", event.target.value);
    updatePointIcon(event.target.value, {
      layer: infoLayerSelectedWMSName,
      userID: Number(getUserId()),
    }).then((res) => {
      if (res.data.message == "style updated successfully") {
        let refreshItem;
        let newfiles = uploadFilesImagery.filter((delItem) => {
          if (delItem.id === layerPanelSelected) {
            refreshItem = delItem;
            return false;
          }
          return true;
        });
        setUploadFilesImagery(newfiles);
        newfiles.push(refreshItem);
        setUploadFilesImagery(newfiles);
        setVectorEditted(!vectorEditted);
      }
    });
  };
  const handleInfoDrawerOpen = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      Sabar2();
    }
    setOpenInfoDrawer(true);
  };
  const handleInfoDrawerClose = () => {
    setRightDrawer(0);
    document.getElementsByClassName("cesium-viewer-toolbar")[0].style.right =
      "5px";
    setOpenInfoDrawer(false);
    // addEventListnerPointCloud();
    //setTileSetHeight(null);
  };
  const handleCloseLayer = (event) => {
    event.preventDefault();
    setAnchorElLayer(null);
  };
  const handleUploadClose = () => {
    setDisplayItems({
      ...displayItems,
      openFolderUploader: false,
      openFileUploader: false,
    });
    setNewUploadfiles([]);
  };
  const handleMediaUploadClose = () => {
    setUploadMediaDrawer(false);
  };
  const handleCloseDeleteDialog = () => {
    setDeleteDrawer(false);
  };

  const handleDateCollectedDrawer = (passProps) => {
    setDateCollectedDrawer(passProps);
  };
  const handleSubmitEdit = (param) => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    if (displayItems.editType === "project") {
      updateProject(param.name, appState.projectId)
        .then((res) => {
          // *** verify it as i removed code
          setDisplayItems({ ...displayItems, openEditDialog: false });
          setAppState({ ...appState, reloadProjects: true });
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    }
    if (displayItems.editType === "folder") {
      updateFolder(param.name, appState.projectId, appState.folderId)
        .then((res) => {
          // *** verify it as i removed code
          setDisplayItems({ ...displayItems, openEditDialog: false });
          setAppState({ ...appState, reloadProjects: true });
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    }
    if (displayItems.editType === "layer") {
      updateLayer(param.name, appState.folderId, appState.fileId)
        .then((res) => {
          // *** verify it as i removed code
          setDisplayItems({
            ...displayItems,
            openEditDialog: false,
          });
          setAppState({ ...appState, reloadProjects: true });
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    }
  };

  const infoPanelDateCollectedHandler = (name, dateCollected) => {
    let check = infoLayerSelectedId.toString().includes("folder_");

    if (check) {
      updateFolderDateCollected(
        name,
        appState.projectId,
        appState.folderId,
        dateCollected
      )
        .then((res) => {
          //Update Layer Array
          const tempAppStateLayerArray = appState.layerArray;
          tempAppStateLayerArray.forEach((k) => {
            if (k.folderId === appState.folderId) {
              k["dateCollected"] = dateCollected;
            }
          });
          let appStateTemp = appState;
          //Update Folder in Project
          appStateTemp.projects.forEach((project) => {
            if (project.id === appState.projectId) {
              updateFolderInProjectdateCollected(
                project.children,
                appState.folderId,
                dateCollected
              );
            }
          });
          // const tempAppStateProjectsArray = appState.projects;
          // tempAppStateProjectsArray.forEach((k) => {
          //   if (k.projectId === appState.projectsId) {
          //     k["dateCollected"] = dateCollected;
          //   }
          // });
          console.log("tempAppStateProjectsArray", appStateTemp);

          setAppState({
            ...appState,
            layerArray: tempAppStateLayerArray,
            projects: appStateTemp.projects,
          });
          setDateInfoPanel({
            ...dateInfoPanel,
            dateCollected: dateCollected,
          });
          errorDisplay("success", "Folder date collected updated");
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    } else {
      updateLayerDateCollected(
        name,
        appState.folderId,
        appState.fileId,
        dateCollected
      )
        .then((res) => {
          const tempAppStateLayerArray = appState.layerArray;
          const foundObject = tempAppStateLayerArray.find(
            (item) => item.id === appState.fileId
          );

          if (foundObject) {
            foundObject["dateCollected"] = dateCollected;
            setAppState({ ...appState, layerArray: tempAppStateLayerArray });
            setDateInfoPanel({
              ...dateInfoPanel,
              dateCollected: dateCollected,
            });
            //console.log(`Updated ${propertyName} of object with ID ${id}`);
            console.log(
              "RespChk",
              name,
              appState.folderId,
              appState.fileId,
              dateCollected,
              res,
              "foundObject",
              foundObject,
              "tempAppStateLayerArray",
              tempAppStateLayerArray
            );
            errorDisplay("success", "Layer date collected updated");
          }
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    }
  };
  const handleSubmitFolder = (val) => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    if (displayItems.editType === "folder") {
      addNewSubFolder(appState.projectId, {
        name: val.name,
        parentId: appState.folderId,
      })
        .then((res) => {
          if (res?.data?.status === 200) {
            setAppState({ ...appState, reloadProjects: true });
            setDisplayItems({
              ...displayItems,
              name: "",
              openFolderDialog: false,
            });
          } else {
            errorDisplay("error", res?.data?.message || "Request Unsuccessful");
          }
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    } else {
      addNewFolder(appState.projectId, {
        name: val.name,
      })
        .then((res) => {
          if (res?.data?.status === 200) {
            setAppState({ ...appState, reloadProjects: true });
            setDisplayItems({
              ...displayItems,
              name: "",
              openFolderDialog: false,
            });
          } else {
            errorDisplay("error", res?.data?.message || "Request Unsuccessful");
          }
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response ? error.response.data : "Request Unsuccessful"
          );
        });
    }
  };
  const handleSubmitProject = (val) => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });

    addNewProject({ name: val.name })
      .then((res) => {
        if (res?.data?.status === 200) {
          addNewFolder(res.data.project.id, {
            name: "data",
          })
            .then((res) => {
              if (res?.data?.status === 200) {
                setAppState({ ...appState, reloadProjects: true });
                setDisplayItems({
                  ...displayItems,
                  openProjectDialog: false,
                  name: "",
                });
              } else {
                errorDisplay(
                  "error",
                  res?.data?.message || "Request Unsuccessful"
                );
              }
            })
            .catch((error) => {
              errorDisplay(
                "error",
                error.response ? error.response.data : "Request Unsuccessful"
              );
            });
        }
        // else {
        //   errorDisplay("error", res?.data?.message || "Projects create error");
        // }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 400) {
            errorDisplay(
              "error",
              error.response.data.message
                ? error.response.data.message
                : "Project creation error, please contact support."
            );
          } else {
            errorDisplay(
              "error",
              error.response
                ? error.response.data?.message
                : "Request Unsuccessful"
            );
          }
        }
      });
  };
  const flyToCords = (x, y) => {
    //const viewer = mapRef.current.cesiumElement;
    x &&
      viewer.camera.flyToBoundingSphere(
        new BoundingSphere(Cartesian3.fromDegrees(x, y), 300),
        { duration: 1 }
      );
  };

  const updateMapFeatures = (id) => {
    mapFeatures = mapFeatures.filter(function(obj) {
      return obj.id !== id;
    });
  };

  const SplitScreenUnMountFn = () => {
    mapFeatures.forEach((k) => {
      k.entity.splitDirection = SplitDirection.NONE;
    });
  };

  const flyToEntity = (entity) => {
    viewer.flyTo(entity, {
      offset: FlyToHeadingPitchRange(),
    });
  };

  const flyToCustom = (item, attempt, entityShow) => {
    const entity = item.entity;
    const entityOrg = entityShow;
    entity.show = true;

    const boundingBox =
      item.hasOwnProperty("boundingBox") &&
      Array.isArray(item.boundingBox) &&
      item.boundingBox.length > 0
        ? item.boundingBox
        : null;
    let westSouth, eastNorth, diagonalDistance, rangeDynamic;

    if (boundingBox) {
      westSouth = Cartesian3.fromDegrees(boundingBox[0], boundingBox[1]);
      eastNorth = Cartesian3.fromDegrees(boundingBox[2], boundingBox[3]);

      diagonalDistance = Cartesian3.distance(westSouth, eastNorth);

      rangeDynamic = diagonalDistance * 1.5;
    }

    const flyToConfig = boundingBox
      ? {
          offset: new HeadingPitchRange(0, -CesMath.PI_OVER_TWO, rangeDynamic),
        }
      : { offset: new HeadingPitchRange(0, -CesMath.PI_OVER_FOUR, 100) };
    // console.log("entityBoundingSphere", entity, rangeDynamic);

    let flyPrmoise;
    if (entity.ellipsoid) {
      flyPrmoise = viewer.flyTo(entity, flyToConfig).then(() => {
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const height = ellipsoid.cartesianToCartographic(
          viewer.scene.camera.position
        ).height;
        if (height < 0 && attempt < 2) {
          // only attempt 50 times before giving up
          flyToCustom(entity, attempt + 1, entityOrg);
        } else {
          if (!entityOrg) {
            entity.show = false;
          }
        }
      });
    } else {
      flyPrmoise = viewer
        .flyTo(
          entity,

          flyToConfig
        )
        .then(() => {
          const ellipsoid = viewer.scene.globe.ellipsoid;
          const height = ellipsoid.cartesianToCartographic(
            viewer.scene.camera.position
          ).height;
          if (height < 0 && attempt < 2) {
            // only attempt 50 times before giving up
            flyToCustom(entity, attempt + 1, entityOrg);
          } else {
            if (!entityOrg) {
              entity.show = false;
            }
          }
        });
    }

    flyPrmoise.then(function(fullfilled, onRejected, onProgress) {
      if (onRejected) {
        console.log("Not Fullfilled");
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message:
              "There is a problem with the geolocation of this file, please check the file or contact support.",
            severity: "error",
            isOpen: true,
          },
        });
      }
    });
  };

  const flyToCordVectors = (item) => {
    // const viewer = mapRef.current.cesiumElement;
    console.log("entityflyTo", item);
    flyToCustom(item, 0, item.entity.show);
  };
  const flyToRectangle = (data) => {
    //const viewer = mapRef.current.cesiumElement;
    if (data.minx.toFixed(0).toString().length > 4) {
      viewer.camera.flyTo({
        destination: Rectangle.fromDegrees(
          toDegrees({
            x: data.minx,
            y: data.miny,
            z: 0,
          })[1],
          toDegrees({
            x: data.minx,
            y: data.miny,
            z: 0,
          })[0],
          toDegrees({
            x: data.maxx,
            y: data.maxy,
            z: 0,
          })[1],
          toDegrees({
            x: data.maxx,
            y: data.maxy,
            z: 0,
          })[0]
          // data.minx,
          // data.miny,
          // data.maxx,
          // data.maxy
        ),
      });
    } else {
      viewer.camera.flyTo({
        destination: Rectangle.fromDegrees(
          data.minx,
          data.miny,
          data.maxx,
          data.maxy
        ),
      });
    }
  };
  const handleSharingDialog = () => {
    setDisplayItems({ ...displayItems, name: "", openSharingDialog: false });
  };
  const handleCloseFolderDialog = () => {
    setDisplayItems({ ...displayItems, name: "", openFolderDialog: false });
  };
  const handleCloseEditDialog = () => {
    setDisplayItems({ ...displayItems, name: "", openEditDialog: false });
  };
  const handleCloseProjectDialog = () => {
    setDisplayItems({ ...displayItems, name: "", openProjectDialog: false });
  };
  const handleCloseTipsDialog = () => {
    setTipsDialog(false);
    secureLocalStorage.setItem("showTips", false);
  };

  const handleCloseEulaDialog = () => {
    // let history = useHistory();
    setEulaDialog(false);
    // history.push("/login");
    dispatch(logout());
    // history.push("/login");
    dispatch({
      type: SET_MESSAGE,
      payload: {
        message: "Accepting EULA is mandatory",
        severity: "error",
        isOpen: true,
      },
    });
    // history.push("/login");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    var element = document.getElementById("myDIV");
    element.classList.remove("mystyle");
  };
  const getLocationFromScreenXY = (x, y, obj) => {
    const scene = viewer?.scene;
    if (!scene) return;
    const ellipsoid = scene.globe.ellipsoid;
    var cartesian;

    if (obj instanceof Cesium3DTileset) {
      cartesian = scene.pickPosition(new Cartesian2(x, y));
    } else {
      var ray = scene.camera.getPickRay(new Cartesian2(x, y));
      cartesian = scene.globe.pick(ray, scene);
    }
    if (!cartesian) return;
    const { latitude, longitude, height } = ellipsoid.cartesianToCartographic(
      cartesian
    );
    return { latitude, longitude, height };
  };
  const handleSelectedEntityChanged = (cords) => {
    if (!mapRef.current || !viewer) {
      return;
    }

    // const viewer = mapRef.current.cesiumElement;
    const selectedEntity = viewer.selectedEntity;

    if (selectedEntity) {
      if (selectedEntity.position) {
        viewer.camera.flyToBoundingSphere(
          new BoundingSphere(
            selectedEntity.position.getValue(viewer.clock.currentTime),
            2000
          ),
          { duration: 1 }
        );
      } else {
        viewer.camera.flyToBoundingSphere(
          new BoundingSphere(
            Cartesian3.fromDegrees(cords.x, cords.y, cords.z),
            2000
          ),
          { duration: 1 }
        );
      }
    }
  };
  const radiansToDegrees = (radians) => {
    let pi = Math.PI;
    return radians * (180 / pi);
  };
  function toDegrees(cartesian3Pos) {
    let pos = Cartographic.fromCartesian(cartesian3Pos);
    return [(pos.longitude / Math.PI) * 180, (pos.latitude / Math.PI) * 180];
  }
  const handleMediaFilesChange = (files) => {
    setNewMediaUploadfiles(files);
    dispatch({ type: LOADING_MESSAGE, isLoading: false });
  };
  const handleDrawerClose = () => {
    setOpen(false);
    currentSection = "";
  };
  const Sabar = (section) => {
    currentSection = section;
    var element = document.getElementById("myDIV");
    element.classList.add("mystyle");
    setSection(section);
    setOpen(true);
  };
  const Sabar2 = () => {
    var element = document.getElementById("myDIV");
    element.classList.remove("mystyle");
    setOpen(false);
    currentSection = "";
  };

  const handleMediaDrawerUploadOk = () => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    addNewMedia(
      newMediaUploadFiles,
      infoLayerSelectedId,
      infoLayerSelectedFeatureId
    ).then((res) => {
      try {
        updateLocalStorage(
          res?.data?.storageDetail?.storageCapacity,
          res?.data?.storageDetail?.usedStorage
        );
        setUploadMediaDrawer(false);
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: "Media Added",
            severity: "success",
            isOpen: true,
          },
        });
        getFeatureMediaFunction(
          infoLayerSelectedId,
          infoLayerSelectedFeatureId
        );
      } catch (e) {
        console.log(e);
      }
    });
  };
  const handleDrawerUploadOk = async () => {
    try {
      const dataObject = {
        projectId: appState.projectId,
        parentId: appState.folderId,
        progress: 0,
      };
      dispatch({ type: LOADING_MESSAGE, isLoading: true });
      setProgress(5);
      for (let i = 0; i < newUploadFiles.length; i++) {
        try {
          let eachUploadedFile = newUploadFiles[i];
          let uniqueID = uuidv4();
          let file = eachUploadedFile;
          let newFileName = `${secureLocalStorage.getItem(
            "userId"
          )}/${filePath}/${file.name.replace(/\..+$/, "") + "." + uniqueID}`;
          let bucket = undefined;
          dataObject.progress = ((i + 1) * 100) / newUploadFiles.length;
          setProgress(dataObject.progress);
          if (eachUploadedFile.name.toLowerCase().includes(".kmz")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.kmz`;
            bucket = config.BUCKET_KML;
          } else if (eachUploadedFile.name.toLowerCase().includes(".tif")) {
            dataObject.layerType = 2;
            const file1 = await fromBlob(file);
            const image = await file1.getImage();
            if (
              image?.geoKeys?.GTCitationGeoKey ||
              image.fileDirectory.Software.includes("RealityCapture")
            ) {
              newFileName = `${newFileName}.tiff`;
              bucket = config.BUCKET_TIFF;
            } else {
              errorDisplay(
                "error",
                `We cannot upload the file ${file.name}.Please upload the correct file with valid CRS`
              );
              setProgress(null);
              return;
            }
          } else if (eachUploadedFile.name.toLowerCase().includes(".zip")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.zip`;
            bucket = config.BUCKET_ZIP;
          } else if (eachUploadedFile.name.toLowerCase().includes(".kml")) {
            dataObject.layerType = 4;
            newFileName = `${newFileName}.kml`;
            bucket = config.BUCKET_KML;
          } else if (eachUploadedFile.name.toLowerCase().includes(".png")) {
            dataObject.layerType = 5;
            newFileName = `${newFileName}.png`;
            bucket = config.BUCKET_PNG;
          } else if (eachUploadedFile.name.toLowerCase().includes(".las")) {
            dataObject.layerType = 6;
            newFileName = `${newFileName}.las`;
            bucket = config.BUCKET_LAS;
          } else if (eachUploadedFile.name.toLowerCase().includes(".laz")) {
            dataObject.layerType = 6;
            newFileName = `${newFileName}.laz`;
            bucket = config.BUCKET_LAS;
          } else if (
            eachUploadedFile.name.toLowerCase().includes(".jpeg") ||
            eachUploadedFile.name.toLowerCase().includes(".jpg")
          ) {
            dataObject.layerType = 5;
            bucket = config.BUCKET_JPG;
            if (eachUploadedFile.name.toLowerCase().includes(".jpeg")) {
              newFileName = `${newFileName}.jpeg`;
            } else if (eachUploadedFile.name.toLowerCase().includes(".jpg")) {
              newFileName = `${newFileName}.jpg`;
            }
          } else {
            setProgress(null);
            errorDisplay(
              "error",
              `We cannot upload the file ${file.name}.Please upload the correct file`
            );
          }

          if (bucket) {
            await uploadFile(bucket, uniqueID, newFileName, file, dataObject);
          }
        } catch (error) {
          console.log("Testing", "Catch block of structure file", error);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const uploadWMSLayer = async (obj) => {
    try {
      const dataObject = {
        projectId: appState.projectId,
        parentId: appState.folderId,
        progress: 0,
      };
      let uniqueID = uuidv4();
      dispatch({ type: LOADING_MESSAGE, isLoading: true });
      setProgress(50);
      dataObject.layerType = 7;
      const strObj = JSON.stringify(obj);
      //console.log("String objj", obj);
      const layerToBeAdded = [
        {
          name: obj.layerLabel,
          data: strObj,
          referenceID: 1,
          status: 1,
        },
      ];
      const layerResponse = await addNewLayer(
        layerToBeAdded,
        dataObject.projectId,
        dataObject.parentId,
        dataObject.layerType
      );

      setProgress(100);
      if (layerResponse && layerResponse.status === 200) {
        setProgress(null);
        // setAddWMSDialog(false);
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
        console.log("layerResponseWMS", layerResponse);
        if (layerResponse?.data?.layers.length > 0) {
          let appStateTemp = appState;
          appStateTemp.projects.forEach((project) => {
            if (project.id === dataObject.projectId) {
              console.log("layerResponse", layerResponse);
              console.log("appState", appState);
              layerResponse?.data?.layers.forEach((k, i) => {
                insertItemInProject(project.children, dataObject.parentId, k);
              });
            }
          });
          // setAppState(appStateTemp);
          setAppState({ ...appStateTemp });
          if (dataObject.layerType == 7) {
            console.log(
              "New Layer Alert",
              "layerResponse",
              layerResponse,
              "dataObject",
              dataObject
            );
            let oldImagery = uploadFilesImagery;
            layerResponse?.data?.layers.forEach((k, i) => {
              oldImagery.push({
                type: 7,
                id: k.id,
                projectId: dataObject.projectId,
                folderId: k.folderId,
                url: k.data,
                show: k.displayStatus ? true : false,
                name: k.name,
              });

              pendingImagery.push(k.id);
            });
            setUploadFilesImagery([...oldImagery]);
            dispatch({
              type: SET_MESSAGE,
              payload: {
                message: "WMS layers added ",
                severity: "success",
                isOpen: true,
              },
            });
          } else {
            pendingImagery.push(layerResponse?.data?.layers[0].id);
          }
        }
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: {
            message: "Layer registration failed",
            severity: "error",
            isOpen: true,
          },
        });
        setProgress(null);
        // setAddWMSDialog(false);
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
      }
      console.log("OBJ", obj, "dataObject", dataObject);
    } catch (e) {
      console.log(e);
    }
  };
  //Function to trigger when 3dtileset flyto is triggered
  const flyTo3dTilesetFunctions = (id) => {
    const tileset3dIds = [];
    const filterMapFeatureObjs3dTilesets = filterObjectsInIdList(
      mapFeatures,
      tilesetLayerId
    );

    filterMapFeatureObjs3dTilesets.forEach((k) => {
      if (k.type === 6 && k.entity instanceof Cesium3DTileset && id) {
        tileset3dIds.push(k.id);
        const primitives = viewer.scene.primitives._primitives;
        const check = containsObject(primitives, k.entity);

        if (!check) {
          viewer.scene.primitives.add(k.entity);
        } else {
          k.entity.show = k.show;
        }
      }
    });
    mapFeatures.forEach((k) => {
      if (k.type === "6a") {
        if (tileset3dIds.length > 0) {
          tileset3dIds.forEach((i) => {
            if (k.id === i) {
              k.entity.billboard.show = false;
              k.intersection = true;
            } else {
              k.entity.billboard.show = k.show;
              k.intersection = false;
            }
          });
        } else {
          k.entity.billboard.show = k.show;
          k.intersection = false;
        }
      }
    });
  };
  const uploadWmsLayerFn = (obj) => {
    setAddWMSDialog(false);

    if (obj.wmsLayerType) {
      //obj["layerNamesArray"] = [1, 2, 3, 4, 5];
      console.log("payLoadObj", obj);
      // const updatedObj = obj.layerNamesArray.forEach((k) => {
      //   k = obj.textValLayerName + "-" + k;
      // });
      uploadWMSLayer(obj);
    } else {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: "WMS url not supported",
          severity: "error",
          isOpen: true,
        },
      });
    }
  };

  const uploadVector = async (kml_file) => {
    try {
      const dataObject = {
        projectId: appState.projectId,
        parentId: appState.folderId,
        progress: 0,
      };
      let tempPath = "";
      if (editEntity) {
        let tempLayer = allLayers.filter(
          (item) => item.id == editableVector.id
        )[0];
        let tempProject = appState.projects.filter(
          (item) => item.id == tempLayer.projectId
        )[0];
        tempPath = `${tempProject.name}/${findPath(
          tempProject,
          "id",
          tempLayer.folderId
        )}`;
      }
      dispatch({ type: LOADING_MESSAGE, isLoading: true });
      setProgress(5);
      let eachUploadedFile = kml_file;
      let uniqueID = uuidv4();
      let file = eachUploadedFile;
      let newFileName = `${secureLocalStorage.getItem("userId")}/${
        editEntity ? "" : filePath
      }/${file.name.replace(/\..+$/, "") + "userGenerated" + "." + uniqueID}`;
      let bucket = undefined;
      dataObject.progress = ((0 + 1) * 100) / 1;
      setProgress(dataObject.progress);
      dataObject.layerType = 4;
      newFileName = `${newFileName}.kml`;
      bucket = config.BUCKET_KML;
      if (bucket) {
        await uploadFile(bucket, uniqueID, newFileName, file, dataObject);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const uploadFile = async (
    bucket,
    uniqueID,
    newFileName,
    file,
    dataObject
  ) => {
    var s3 = new AWS.S3({
      accessKeyId: secureLocalStorage.getItem("ACCESS_ID"),
      secretAccessKey: secureLocalStorage.getItem("SECRET_KEY"),
      sessionToken: secureLocalStorage.getItem("SessionToken"),
      region: config.REGION,
    });
    let params = {
      Bucket: bucket,
      Key: newFileName,
      Body: file,
    };
    let options = { partSize: 5 * 1024 * 1024, queueSize: 20 };
    try {
      const stored = await s3
        .upload(params, options)
        .on("httpUploadProgress", function(progress) {
          if (!newFileName.includes("userGenerated")) {
            let progressPercentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
            // console.log(progressPercentage);
            // setProgress(progressPercentage);
            document.getElementsByClassName(
              file.name.replace(" ", "_").replace(".", "_") + file.size
            )[0].innerHTML = `${progressPercentage}%`;
            document.querySelector(
              ".circle" +
                file.name.replace(" ", "_").replace(".", "_") +
                file.size +
                " svg circle"
            ).style["stroke-dasharray"] =
              // Number(
              //   document.querySelector(
              //     ".circle" +
              //       file.name.replace(" ", "_").replace(".", "_") +
              //       file.size +
              //       " svg circle"
              //   ).style["stroke-dasharray"]
              // )
              126.92 + Number(progressPercentage) * 1.27;
            // progressBar.style.width = progressPercentage + "%";
            // if (progressPercentage < 100) {
            // fileUpload.progressStatus = progressPercentage;
            // } else
            if (progressPercentage == 100) {
            }
          }
          // fileUpload.progressStatus = progressPercentage;

          // fileUpload.status = "Uploaded";
          // }
        })
        .promise();
      if (editEntity) {
        editVector(
          newFileName.split("/")[newFileName.split("/").length - 1],
          editableVector.id
        ).then((res) => {
          loadedEntitties = loadedEntitties.filter(
            (item) => item.id !== editableVector.id
          );
          let tempLayer = allLayers.filter(
            (item) => item.id == editableVector.id
          )[0];
          let oldImagery = uploadFilesImagery;
          oldImagery = oldImagery.filter(
            (itemVec) => itemVec.id != editableVector.id
          );
          oldImagery.push({
            type: 6,
            id: editableVector.id,
            projectId: tempLayer.projectId,
            folderId: tempLayer.folderId,
            url: res.data.data,
            show: true,
          });
          updateLayer(
            file.name.replace(".kml", ""),
            tempLayer.folderId,
            editableVector.id
          )
            .then((res) => {
              let dataToSend = {
                links: infoLayerLinks,
                altitudeMode: altMode,
              };
              updateLayerAttributes(dataToSend, editableVector.id, null).then(
                (res1) => {
                  setAppState({ ...appState, reloadProjects: true });
                  setInfoLayerSelectedName(file.name.replace(".kml", ""));
                  allLayers = allLayers.map((thisInfo) => {
                    if (thisInfo.id == editableVector.id) {
                      thisInfo.name = file.name.replace(".kml", "");
                      if (thisInfo.feature_attributes.length) {
                        thisInfo.feature_attributes[
                          getNullFeature(thisInfo.feature_attributes)
                        ].links = infoLayerLinks;
                        thisInfo.feature_attributes[
                          getNullFeature(thisInfo.feature_attributes)
                        ].tags = [];
                      } else {
                        thisInfo.feature_attributes = [
                          {
                            featureId: null,
                            description: "",
                            links: infoLayerLinks,
                            tags: [],
                          },
                        ];
                      }
                    }
                    return thisInfo;
                  });
                }
              );
            })
            .catch((error) => {
              errorDisplay(
                "error",
                error.response ? error.response.data : "Request Unsuccessful"
              );
            });
          viewer.dataSources._dataSources.forEach((k, i) => {
            if (k.itemId === editableVector.id) {
              viewer.dataSources.remove(k);
            }
          });
          setUploadFilesImagery(oldImagery);
        });
        setEditEntity(null);
      } else {
        const layerToBeAdded = [
          {
            attributes: {
              description: "",
              color: "",
              fillColor: "",
              strokeColor: "",
              transparency: "",
              strokeTransparency: "",
              fillTransparency: "",
              tags: [],
              links: infoLayerLinks,
              altitudeMode: altMode,
            },
            name: file.name,
            data: {
              url: stored.Location,
              // url: !newFileName.includes(".zip")
              // ? stored.Location
              // : stored.Location.split(".zip")[0] + ".kml",
              size: file.size / 1024 / 1024,
            },
            referenceID: uniqueID,
            status: {
              status:
                dataObject.layerType == 4 &&
                newFileName.includes("userGenerated")
                  ? "User Generated"
                  : "Processing",
            },
          },
        ];
        const layerResponse = await addNewLayer(
          layerToBeAdded,
          dataObject.projectId,
          dataObject.parentId,
          dataObject.layerType
        );
        if (layerResponse && layerResponse.status === 200) {
          // setProgress(dataObject.progress);
          updateLocalStorage(
            layerResponse?.data?.storageDetail?.storageCapacity,
            layerResponse?.data?.storageDetail?.usedStorage
          );

          if (layerResponse?.data?.layers.length > 0) {
            // *** warning: this will effect drag and drop order
            let appStateTemp = appState;
            appStateTemp.projects.forEach((project) => {
              if (project.id === dataObject.projectId) {
                insertItemInProject(
                  project.children,
                  dataObject.parentId,
                  layerResponse?.data?.layers[0]
                );
                if (
                  dataObject.layerType == 4 &&
                  newFileName.includes("userGenerated")
                ) {
                  allLayers.push({
                    id: layerResponse?.data?.layers[0].id,
                    name: file.name,
                    feature_attributes: [
                      layerResponse?.data.layers[0].attributes,
                    ],
                    feature_media: [],
                    folderId: layerResponse?.data?.layers[0].folderId,
                    folder_id: layerResponse?.data?.layers[0].folderId,
                    projectId: dataObject.projectId,
                  });
                  let oldImagery = uploadFilesImagery;
                  oldImagery.push({
                    type: 6,
                    id: layerResponse?.data?.layers[0].id,
                    projectId: dataObject.projectId,
                    folderId: layerResponse?.data?.layers[0].folderId,
                    url: layerResponse?.data?.layers[0].signedUrl,
                    show: layerResponse?.data?.layers[0].displayStatus
                      ? true
                      : false,
                    altitudeMode: altMode,
                  });
                  setUploadFilesImagery(oldImagery);
                  setAltMode("absolute");
                  pendingImagery.push(layerResponse?.data?.layers[0].id);
                } else {
                  pendingImagery.push(layerResponse?.data?.layers[0].id);
                }
              }
            });
            setAppState({ ...appStateTemp });
          }
        } else {
          console.log("Testing", "create layer error", layerResponse);
        }
      }
    } catch (error) {
      console.log("Testing", "Catch block s3", error);
      errorDisplay(
        "error",
        `We are facing problem in uploading this ${file.name} file`
      );
    }
    if (dataObject.progress === 100 && newFileName.includes("userGenerated")) {
      setProgress(null);
      handleUploadClose();
      // setAppState({ ...appState, reloadProjects: true });
      dispatch({ type: LOADING_MESSAGE, isLoading: false });
    }
  };
  const downloadFile = async (url, id) => {
    url = new URL(url);
    let bucket = url.hostname.split(".")[0];
    let fileKey = url.pathname.substring(1);
    fileKey = fileKey.replace(/%20/g, " ");
    let signedUrl = await getSignedUrl({
      bucket: bucket,
      fileKey: fileKey,
      expireTime: 300,
      signedMethod: "getObject",
    });
    if (signedUrl && signedUrl.data.status === 200) {
      signedUrl = signedUrl.data.signedUrl;
      let a = document.createElement("a");
      a.href = signedUrl;
      a.click();
      // let downloadLink = document.getElementById(id); //or grab it by tagname etc
      // downloadLink.href = signedUrl;
      // downloadLink.click();
    }
  };
  const getSignUrl = async (url) => {
    url = new URL(url);
    let bucket = url.hostname.split(".")[0];
    let fileKey = url.pathname.substring(1);
    fileKey = fileKey.replace(/%20/g, " ");
    let signedUrl = await getSignedUrl({
      bucket: bucket,
      fileKey: fileKey,
      expireTime: 30000,
      signedMethod: "getObject",
    });
    if (signedUrl && signedUrl.data.status === 200) {
      selectedPrimitive;
      signedUrl = signedUrl.data.signedUrl;
      return signedUrl;
    } else {
      return url;
    }
  };

  const getHeight3DTilesetValues = (style, asset) => {
    let selectedAltitudeMode;
    let tileSetHeightDefault = null;
    let tileSetHeight = null;
    let transparencyEnabled = false;
    // const tileLoadEventListnerForPointCloudArray = []; // Replace with actual reference
    // const viewer = {}; // Replace with actual reference

    const eventListenerHandler = (k, remove = true) => {
      if (remove) {
        viewer.scene.globe.tileLoadProgressEvent.removeEventListener(
          k.eventListner
        );
      } else {
        viewer.scene.globe.tileLoadProgressEvent.addEventListener(
          k.eventListner
        );
      }
    };

    switch (style) {
      case "clampToGround":
        selectedAltitudeMode = "clampToGround";
        tileLoadEventListnerForPointCloudArray.forEach((k) => {
          if (asset.layerid === k.id) {
            if (asset?.style?.rayCastCalculation) {
              tileSetHeightDefault = asset?.style.tileSetHeightDefault;
              tileSetHeight = asset?.style.tileSetHeight;
            } else {
              tileSetHeight = null;
              k.eventListner(true);
              eventListenerHandler(k);
              eventListenerHandler(k, false);
            }
          }
        });
        break;
      case "custom":
        selectedAltitudeMode = "custom";
        // console.log(
        //   "customStyleHandler",
        //   tileLoadEventListnerForPointCloudArray
        // );

        tileLoadEventListnerForPointCloudArray.forEach((k) => {
          if (asset.layerid === k.id) {
            eventListenerHandler(k);
            tileSetHeightDefault =
              asset?.style?.tileSetHeightDefault &&
              !asset?.style?.rayCastCalculation
                ? asset.style.tileSetHeightDefault
                : k.height;
            tileSetHeight = asset?.style?.tileSetHeight || 0;
          }
        });

        break;
      default:
        selectedAltitudeMode = "absolute";
        tileLoadEventListnerForPointCloudArray.forEach((k) => {
          if (asset.layerid === k.id) {
            eventListenerHandler(k);
            tileSetHeightDefault = k.nativeHeight;
            if (k.nativeHeight < 0) {
              transparencyEnabled = true;
            }
            tileSetHeight = 0;
          }
        });
    }

    return {
      selectedAltitudeMode,
      tileSetHeightDefault,
      tileSetHeight,
      transparencyEnabled,
    };
  };

  const setHeight3DTileset = (style) => {
    const asset = selectedPrimitive._asset;
    const {
      selectedAltitudeMode,
      tileSetHeightDefault,
      tileSetHeight,
      transparencyEnabled,
    } = getHeight3DTilesetValues(style, asset);

    setSelectedAltitudeMode(selectedAltitudeMode);
    setTileSetHeightDefault(tileSetHeightDefault);
    setTileSetHeight(tileSetHeight);
    setTransparencyEnabled(transparencyEnabled);
  };

  //New
  const getPointCloudStyleValues = (
    tempdefaultStyleArray,
    tilesetClassesArray,
    asset
  ) => {
    console.log("selectedPrimitiveTest", asset);

    const hasStyle = asset.hasOwnProperty("style");

    let heightSliderVal,
      styleClass,
      selectedColorPallet,
      pointCloudSize,
      styleMode,
      height3DTileset;
    let minzMaxz = [0, 1];
    let showPointCloudStyleChkBx = false;

    if (hasStyle) {
      const style = asset.style;
      heightSliderVal = style.heightSliderVal;
      styleClass = style.styleClass;
      selectedColorPallet = style.selectedColorPallet;
      pointCloudSize = style.pointCloudSize;
      height3DTileset = style.hasOwnProperty("altitudeMode")
        ? style.altitudeMode
        : "clampToGround";
      styleMode = style.styleMode;
    } else {
      const tempArray = tempdefaultStyleArray.filter((k) =>
        tilesetClassesArray.some((n) => parseFloat(n) === k.classId)
      );

      heightSliderVal = [0, 0.7];
      styleClass = tempArray;
      selectedColorPallet = heightColorList[0];
      pointCloudSize = 0;
      styleMode = "rgb";
      height3DTileset = "clampToGround";
    }

    if (asset.hasOwnProperty("minz") && asset.hasOwnProperty("maxz")) {
      minzMaxz = [Math.floor(asset.minz), Math.floor(asset.maxz)];
      showPointCloudStyleChkBx = true;
    }

    return {
      heightSliderVal,
      styleClass,
      selectedColorPallet,
      pointCloudSize,
      styleMode,
      height3DTileset,
      minzMaxz,
      showPointCloudStyleChkBx,
    };
  };
  const pointCloudSetStyle = (tempdefaultStyleArray, tilesetClassesArray) => {
    const asset = selectedPrimitive._asset;
    const {
      heightSliderVal,
      styleClass,
      selectedColorPallet,
      pointCloudSize,
      styleMode,
      height3DTileset,
      minzMaxz,
      showPointCloudStyleChkBx,
    } = getPointCloudStyleValues(
      tempdefaultStyleArray,
      tilesetClassesArray,
      asset
    );

    setHeightSliderVal(heightSliderVal);
    setStyleClass(styleClass);
    setSelectedColorPallet(selectedColorPallet);
    setPointCloudSize(pointCloudSize);
    setStyleMode(styleMode);
    setHeight3DTileset(height3DTileset);
    setminzMaxz(minzMaxz);
    setShowPointCloudStyleChkBx(showPointCloudStyleChkBx);
  };

  const handleImageLoad = (imageArray) => {
    setImageFolderArray([...imageArray]);
  };
  function createWaypoint(waypointPosition, viewer) {
    let entity = createDraggableWaypoint(waypointPosition, viewer);
    return entity;
  }
  function deleteDragPoints() {
    //const viewer = mapRef.current.cesiumElement;
    allEntitiesDrag.map((item) => {
      viewer.entities.remove(item);
    });
    allEntitiesDrag = [];
  }
  function popDragPoints() {
    //const viewer = mapRef.current.cesiumElement;
    viewer.entities.remove(allEntitiesDrag[allEntitiesDrag.length - 1]);
    allEntitiesDrag.pop();
  }
  function createCesiumPoint(coordinates, size, color) {
    let point = new PointGraphics({
      pixelSize: size,
      color: color,
    });
    let entity = new Entity({
      name: "pointHere",
      position: coordinates,
      point: point,
    });

    return entity;
  }

  const sidePanelInfoUpdate = (getData, k) => {
    if (!openAccordians1.includes(getData.selectedProjId)) {
      openAccordians1.push(getData.selectedProjId);
    }
    if (!openAccordians2.includes(getData.selectedFoldId)) {
      openAccordians2.push(getData.selectedFoldId);
    }

    selectedItem = {
      projectId: getData.selectedProjId,
      folderId: getData.selectedFoldId,
      fileId: getData.selectedImageId,
    };

    setLayerPanelSelected(getData.selectedImageId);
    setInfoLayerSelected("Image");
    setInfoLayerSelectedId(getData.selectedImageId);
    setInfoLayerSelectedFeatureId(null);
    getFeatureMediaFunction(getData.selectedImageId, null);
    setInfoLayerSelectedName(k.name);
    var json_data = k.exifData.data[0];
    var result = [];
    for (var m in json_data) result.push([m, json_data[m]]);
    var tableData =
      '<table style="display: block;width: initial;overflow: auto;"><tr><td>Name</td><td>Value</td></tr>';
    for (var n = 0; n < result.length; n++) {
      tableData += "<tr>";
      tableData += "<td>" + result[n][0] + "</td>";
      tableData += "<td>" + result[n][1] + "</td></tr>";
    }
    tableData += "</table>";
    setInfoLayerProperties(tableData);
    console.log("ExifData", k.exifData.data[0]);
  };

  const imgPushingFunction = (index, imgArray, getData) => {
    for (let i = 0; i < index; i++) {
      console.log("ForLoop", imgArray[i]);
      const k = imgArray[i];
      const img = new Image();
      const imgThumbnail = new Image();
      if (k.folderUrl) {
        img.src = k.folderUrl;
      } else {
        img.src = k.data.replace(/['"]+/g, "");
        imgThumbnail.src = (k?.thumbnail || "").replace(/['"]+/g, "");
      }
      if (!k.exifData.data[0]?.is360Image) {
        temImgArray.push({
          k: k,
          getData: getData,
          name: k.name,
          img: img,
          src: img.src,
          id: k.id,
          regions: k.regions.status === 403 ? [] : k.regions.regions.tagData,
          type: "image2D",
          show: false,
        });
      }

      if (k.id === getData.selectedImageId) {
        if (k.exifData.data[0]?.is360Image) {
          console.log("selectedimage360", k);
          temImgArray.push({
            k: k,
            getData: getData,
            name: k.name,
            img: img,
            src:
              (k.status.type === "multires" &&
                k.status.status === "Complete") ||
              k.status.type != "multires"
                ? img.src
                : k.data,
            id: k.id,
            regions: k?.regions?.status === 403 ? null : k?.regions?.regions,
            imgType:
              k.status.type === "multires" && k.status.status === "Complete"
                ? "multiRes"
                : "raw",
            cubeMap:
              k?.cubeMap != null || k?.cubeMap?.length > 0 ? k?.cubeMap : [],
            type: "image3D",
            show: false,
          });
          console.log("360ProcessingCheck");
        }
        //Side Panel Info Update
        sidePanelInfoUpdate(getData, k);

        if (!k.exifData.data[0]?.is360Image) {
          const handleImageLoadSuccess = (image, updateViewer = true) => {
            const locTemImgArray = [...temImgArray];
            const lastImgIndex = temImgArray.length - 1;
            if (locTemImgArray[lastImgIndex]) {
              locTemImgArray[lastImgIndex].show = true;
              locTemImgArray[lastImgIndex].img = image;
              locTemImgArray[lastImgIndex].src = image.src;
              handleImageLoad(locTemImgArray);
              lastUpdatedImageId = locTemImgArray[lastImgIndex].id;
              setImgLoader(false);
              if (updateViewer) setImageViewer(true);
            } else {
              setImgLoader(false);
            }
            console.log("imgLoad");
          };

          const handleOrgImageLoadSuccess = (image) => {
            console.log("lastUpdatedImageId", lastUpdatedImageId);
            let locTemImgArray = [...temImgArray];

            const removedElement = locTemImgArray.find(
              (item) => item.id === lastUpdatedImageId
            );

            locTemImgArray = locTemImgArray.filter(
              (item) => item.id !== lastUpdatedImageId
            );

            handleImageLoad(locTemImgArray);

            removedElement["show"] = true;
            removedElement["img"] = image;
            removedElement["src"] = image.src;
            locTemImgArray.push(removedElement);
            handleImageLoad(locTemImgArray);

            console.log("imgLoad");
          };

          const handleImageLoadError = () => {
            console.log("imgLoadFailed");
            setImageViewer(false);
            setImgLoader(false);

            dispatch({
              type: SET_MESSAGE,
              payload: {
                message: "Image Load Failed",
                severity: "error",
                isOpen: true,
              },
            });
          };

          imgThumbnail.onload = () => {
            setImgLoader(false);
            handleImageLoadSuccess(imgThumbnail);

            img.onload = () => {
              setImgLoader(true);
              handleOrgImageLoadSuccess(img);
            };

            img.onerror = handleImageLoadError;
          };

          imgThumbnail.onerror = () => {
            console.log("imgLoadFailed");
            setImageViewer(false);
            setImgLoader(false);

            img.onload = () => {
              setImgLoader(true);
              handleOrgImageLoadSuccess(img);
            };

            img.onerror = handleImageLoadError;
          };

          img.onerror = handleImageLoadError;
        } else {
          const locTemImgArray = temImgArray;
          locTemImgArray[temImgArray.length - 1].show = true;
          handleImageLoad(locTemImgArray);
          setImageViewer360(true);

          if (!k.status.type === "multires") {
            setImgLoader(false);

            img.onerror = () => {
              const locTemImgArray = temImgArray;
              locTemImgArray[temImgArray.length - 1].show = false;
              handleImageLoad(locTemImgArray);
              setImageViewer360(false);

              setImgLoader(false);
              dispatch({
                type: SET_MESSAGE,
                payload: {
                  message: "Image Load Failed",
                  severity: "error",
                  isOpen: true,
                },
              });
            };
          }
        }
      }
    }
    return temImgArray;
  };

  function disableCameraMotion(state, viewer) {
    viewer.scene.screenSpaceCameraController.enableRotate = state;
    viewer.scene.screenSpaceCameraController.enableZoom = state;
    viewer.scene.screenSpaceCameraController.enableLook = state;
    viewer.scene.screenSpaceCameraController.enableTilt = state;
    viewer.scene.screenSpaceCameraController.enableTranslate = state;
  }

  function createDraggableWaypoint(waypointPosition, viewer) {
    let picked = false;

    //setInterval(() => {
    //    console.log(waypointPosition);
    //}, 1000);

    let positionCallback = () => {
      return waypointPosition;
    };

    // TODO: Update Cesium typings so that we don't have to set positionCBP type to 'any'
    let positionCBP = new CallbackProperty(positionCallback, false);
    let myPoint = createCesiumPoint(positionCBP, 10, Color.GREENYELLOW);

    viewer.entities.add(myPoint);

    let handler = new ScreenSpaceEventHandler(viewer.canvas);

    /**
     * Listens for a left-mouse-button-down event on the Cesium
     * canvas, picks the entity at the mouse position (if any),
     * and disables the default camera behaviour if an entity is
     * picked
     */
    handler.setInputAction((click) => {
      let pickedObject = viewer.scene.pick(click.position);
      if (defined(pickedObject) && pickedObject.id === myPoint) {
        picked = true;
        disableCameraMotion(false, viewer);
        pickedEntity = pickedObject.id;
      }
    }, ScreenSpaceEventType.LEFT_DOWN);

    /**
     * Listens for a drag event on the Cesium canvas; if an entity has
     * been picked it updates the position variable with the mouse end
     * position
     */
    handler.setInputAction((movement) => {
      // move the myEllipse around
      if (!picked) {
        return;
      }
      waypointPosition = viewer.scene.pickPosition(movement.endPosition);
      // viewer.camera.pickEllipsoid(
      //   movement.endPosition,
      //   viewer.scene.globe.ellipsoid
      // );
      // cartesian = scene.pickPosition(new Cartesian2(x, y));
    }, ScreenSpaceEventType.MOUSE_MOVE);

    /**
     * Listens for a left-mouse-button-up event on the Cesium canvas,
     * unpicks the picked entity and enables the default camera
     * behaviour
     */
    handler.setInputAction((movement) => {
      if (picked) {
        const ellipsoid = viewer.scene.globe.ellipsoid;
        initialPositions = [];
        let tempPoints = initialPoints;
        initialPoints = [];
        initialPositionsNew = [];
        allEntitiesDrag.map((item, index) => {
          let cartesian = item._position._callback();
          const cartographic = ellipsoid.cartesianToCartographic(cartesian);
          let longitudeString = CesMath.toDegrees(
            cartographic.longitude
          ).toFixed(15);
          longitudeString = Number(longitudeString);
          let latitudeString = CesMath.toDegrees(cartographic.latitude).toFixed(
            15
          );
          latitudeString = Number(latitudeString);
          let lineCords = Cartographic.fromCartesian(cartesian);
          let lineCords2 = new Cartesian3.fromRadians(
            lineCords.longitude,
            lineCords.latitude,
            lineCords.height
          );
          initialPositionsNew.push(lineCords2);
          initialPositions.push(radiansToDegrees(lineCords.longitude));
          initialPositions.push(radiansToDegrees(lineCords.latitude));
          initialPoints.push({
            x: radiansToDegrees(lineCords.longitude),
            y: radiansToDegrees(lineCords.latitude),
            z: lineCords.height,
          });
        });
        setPoints(initialPoints);
        const tempinitialPositionsNew = [...initialPositionsNew];
        setPositions(tempinitialPositionsNew);
      }
      // if picked
      picked = false;
      disableCameraMotion(true, viewer);
    }, ScreenSpaceEventType.LEFT_UP);

    return myPoint;
  }

  function getPointCoordinates(event, viewer) {
    var cartesian = null;
    if (event.position) {
      cartesian = viewer.scene.pickPosition(event.position);
    } else {
      cartesian = viewer.scene.pickPosition(event.endPosition);
    }
    // let clickPosition = viewer.camera.pickEllipsoid(event.position);
    return cartesian;
  }

  const getImageryLayerExtentsAndCheck = (
    clickedLongitude,
    clickedLatitude
  ) => {
    const imageryLayers = viewer.imageryLayers._layers;

    // Loop through each imagery layer
    for (let i = imageryLayers.length - 1; i >= 0; i--) {
      const layer = imageryLayers[i];

      // Check if the layer's imagery provider is a TileMapServiceImageryProvider
      if (layer?._imageryProvider instanceof TileMapServiceImageryProvider) {
        const rectangle = layer?._imageryProvider?._rectangle; // Use standard way to access the rectangle

        // Check if the clicked point is inside the layer's extent
        const isInside = Rectangle.contains(
          rectangle,
          Cartographic.fromDegrees(clickedLongitude, clickedLatitude)
        );

        if (isInside && layer.show) {
          // console.log(
          //   `The clicked point is inside the extent of layer ${i}:`,
          //   rectangle
          // );
          return layer; // Return the matching layer
        }
      }
    }

    console.log("No layer found containing the clicked point.");
    return null; // Return null if no layer contains the point
  };

  const mapEventsHandler = () => {
    const scene = viewer.scene;
    if (eventHandler) {
      eventHandler.destroy();
    }
    let handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
    handler.setInputAction((movement) => {
      const cords = movement;

      if (scene.pickPositionSupported) {
        let picked = viewer.scene.pick(cords.position);
        const obj = picked;
        console.log("picked", picked, viewer.imageryLayers);
        let _layer = null;

        const cartesianConst = viewer.camera.pickEllipsoid(
          cords.position,
          viewer.scene.globe.ellipsoid
        );

        if (picked == undefined && cartesianConst) {
          // Convert the cartesian position to geographic coordinates (longitude, latitude)
          const cartographic = Cartographic.fromCartesian(cartesianConst);
          const longitude = CesMath.toDegrees(cartographic.longitude);
          const latitude = CesMath.toDegrees(cartographic.latitude);

          // Check if the clicked point is inside the extent of any TMS imagery layer
          _layer = getImageryLayerExtentsAndCheck(longitude, latitude);
          console.log("_layer", _layer);
          if (_layer) {
            //geoTiff Pick
            if (
              _layer?.item?.folderId &&
              _layer?.item?.id &&
              _layer?.item?.projectId
            ) {
              console.log("_layer", _layer);
              if (!openAccordians1.includes(_layer?.item?.projectId)) {
                openAccordians1.push(_layer?.item?.projectId);
              }
              if (!openAccordians2.includes(_layer?.item?.folderId)) {
                openAccordians2.push(_layer?.item?.folderId);
              }
              selectedItem = {
                projectId: _layer?.item?.projectId,
                folderId: _layer?.item?.folderId,
                fileId: _layer?.item?.id,
              };
              const featureId = _layer?.item?.id;
              if (featureId) {
                setLayerPanelSelected(featureId);
                setInfoLayerSelectedId(featureId);
                setInfoLayerSelectedFeatureId(null);
                getFeatureMediaFunction(featureId, null);
              }

              let selected = allLayers.filter((layer) => layer.id == featureId);
              console.log("selChk123", selected, featureId, appState);
              setInfoLayerSelectedName(selected[0].name);
              setDateInfoPanel({
                dateCreated: selected[0]?.createdAt,
                dateUpdated: selected[0]?.updatedAt,
                dateCollected:
                  selected[0]?.dateCollected != null
                    ? selected[0]?.dateCollected
                    : "",
              });

              setDateInfoPanel({
                dateCreated: selected[0]?.createdAt,
                dateUpdated: selected[0]?.updatedAt,
                dateCollected:
                  selected[0]?.dateCollected != null
                    ? selected[0]?.dateCollected
                    : "",
              });
              setRightDrawer(350);
              document.getElementsByClassName(
                "cesium-viewer-toolbar"
              )[0].style.right = "263px";
              handleInfoDrawerOpen();
              setMediaAccordian(false);
            }
          }
        }

        if (
          obj?.id?._name == "MYLOCATION" ||
          obj?.id?._name == "MYLOCATION1" ||
          obj?.id?._name == "geocodePin"
        ) {
          viewer.selectedEntity = undefined;
          return;
        }
        if (
          picked?.primitive?._text &&
          (picked?.primitive?._text.includes("^2") ||
            picked?.primitive?._text.includes(" m"))
        ) {
          viewer.selectedEntity = undefined;
          return;
        }
        if (!editSelected && !measureSelected) {
          console.log("CesiumClick", obj);
          selectedPrimitive = null;
          if (
            obj?.id?.description?.exifData ||
            obj?.id?.description?._value?.exifData
          ) {
            //2D Image Pick
            setImgLoader(true);
            let selectedProjId;
            let selectedFoldId;
            let selectedImageId;
            if (obj?.id?.ellipsoid) {
              selectedProjId = obj?.id.description._value?.projectid;
              selectedFoldId = obj?.id.description._value?.folderid;
              selectedImageId = obj?.id.description._value?.layerid;
            } else {
              selectedProjId = obj?.id.description.projectid;
              selectedFoldId = obj?.id.description.folderid;
              selectedImageId = obj?.id.description.layerid;
            }

            const foundObject = temImgArray.find(
              (obj) => obj.id === selectedImageId
            );
            if (foundObject) {
              setImgLoader(false);
            } else {
              getSingleLayer(selectedImageId)
                .then((res) => {
                  console.log("getSingleLayer", res);
                  if (res.status === 200) {
                    imageIndex = 0;
                    setImageIndexState(0);
                    imgArray = [];
                    if (res?.data?.layer?.status?.status === "Complete") {
                      res.data.layer["regions"] = res?.data?.regions;
                      imgArray.push(res.data.layer);
                    }

                    const getData = {
                      selectedProjId: selectedProjId,
                      selectedFoldId: selectedFoldId,
                      selectedImageId: selectedImageId,
                    };
                    console.log("getSingleLayer", res, imgArray);

                    if (imgArray?.length && imgArray[0] !== undefined) {
                      const temImgArray = imgPushingFunction(
                        1,
                        imgArray,
                        getData
                      );

                      handleImageLoad(temImgArray);
                      setImgLoader(true);
                    } else {
                      setImgLoader(false);
                      dispatch({
                        type: SET_MESSAGE,
                        payload: {
                          message: "Image Load Failed",
                          severity: "error",
                          isOpen: true,
                        },
                      });
                    }
                  }
                })
                .catch((error) => {
                  console.log("errorImage", error);
                  //dispatch({ type: LOADING_MESSAGE, isLoading: false });
                  setImgLoader(false);
                });
              // getImagesFolder(selectedProjId, selectedFoldId)
              //   .then((res) => {
              //     if (res.status === 200) {
              //       imageIndex = 0;
              //       setImageIndexState(0);

              //       imgArray = res.data.result.filter(
              //         (obj) => obj?.status?.status === "Complete"
              //       );
              //       console.log("Resp_getImagesFolder", imgArray, res);

              //       //Code to put clicked image at start of array
              //       const findIndex = imgArray.findIndex(
              //         (obj) => obj.id === selectedImageId
              //       );
              //       if (findIndex != 0) {
              //         const obj = imgArray.splice(findIndex, 1)[0]; // Remove the object from its current position
              //         imgArray.unshift(obj);
              //       }
              //       let temImgArray;
              //       const getData = {
              //         selectedProjId: selectedProjId,
              //         selectedFoldId: selectedFoldId,
              //         selectedImageId: selectedImageId,
              //       };
              //       if (imgArray?.length && imgArray[0] !== undefined) {
              //         const temImgArray = imgPushingFunction(
              //           1,
              //           imgArray,
              //           getData
              //         );

              //         handleImageLoad(temImgArray);
              //         setImgLoader(true);
              //       } else {
              //         setImgLoader(false);
              //         dispatch({
              //           type: SET_MESSAGE,
              //           payload: {
              //             message: "Image Load Failed",
              //             severity: "error",
              //             isOpen: true,
              //           },
              //         });
              //       }
              //     }
              //   })
              //   .catch((error) => {
              //     console.log("errorImage", error);
              //     //dispatch({ type: LOADING_MESSAGE, isLoading: false });
              //     setImgLoader(false);
              //   });
            }

            return;
          }
          if (
            obj?.primitive?.allTilesLoaded ||
            obj?.id?.hasOwnProperty("_customPropBillboard")
          ) {
            //3dtileset Billboard Pick
            let featureId;
            let layerId = obj?.primitive?.allTilesLoaded
              ? obj.primitive?._asset.layerid
              : obj?.id?._customPropBillboard?.layer?.entity?._asset.layerid;
            let folderid = obj?.primitive?.allTilesLoaded
              ? obj.primitive?._asset.folderid
              : obj?.id?._customPropBillboard?.layer?.entity?._asset.folderid;
            let projectid = obj?.primitive?.allTilesLoaded
              ? obj.primitive?._asset.projectid
              : obj?.id?._customPropBillboard?.layer?.entity?._asset.projectid;
            if (folderid && layerId && projectid) {
              if (!openAccordians1.includes(projectid)) {
                openAccordians1.push(projectid);
              }
              if (!openAccordians2.includes(folderid)) {
                openAccordians2.push(folderid);
              }
              selectedItem = {
                projectId: projectid,
                folderId: folderid,
                fileId: layerId,
              };
              setLayerPanelSelected(layerId);
            }
            selectedPrimitive = obj?.primitive?.allTilesLoaded
              ? obj.primitive
              : obj?.id?._customPropBillboard?.layer?.entity;
            setInfoLayerSelected("Point Cloud");

            const tempdefaultStyleArray = [...defaultStyleArray];
            const assetClasses = obj?.primitive?.allTilesLoaded
              ? obj.primitive?._asset.classes
              : obj?.id?._customPropBillboard?.layer?.entity?._asset.classes;
            const tilesetClassesArray = assetClasses ? assetClasses : [];

            const globe = viewer.scene.globe;
            if (globe.translucency.enabled) {
              setTransparencyEnabled(false);
            }
            pointCloudSetStyle(tempdefaultStyleArray, tilesetClassesArray);
            const entityUrl = obj?.primitive?.allTilesLoaded
              ? obj.primitive._url
              : obj?.id?._customPropBillboard?.layer?.entity._url;
            const parts = entityUrl.split("/");

            const filePart = parts[parts.length - 2];

            const fileName = filePart.split(".")[0];

            if (fileName) {
              setInfoLayerSelectedName(fileName);
            }

            featureId = layerId;
            const directLayer = featureId;

            if (featureId) {
              setInfoLayerSelectedId(featureId);
              setInfoLayerSelectedFeatureId(null);
              getFeatureMediaFunction(directLayer, null);
            } else {
              setInfoLayerSelectedId(null);
              setInfoLayerSelectedFeatureId(null);
              setInfoLayerSelectedMedia([]);
            }
            setInfoLayerSelectedWMSName("");
            setInfoLayerProperties("");
            setEditableVector(false);
            setRightDrawer(350);
            document.getElementsByClassName(
              "cesium-viewer-toolbar"
            )[0].style.right = "263px";
            handleInfoDrawerOpen();
            setMediaAccordian(false);
            //FlyTo On 3dTileset When Point Icon of 3dTileset is clicked
            obj?.id?.hasOwnProperty("_customPropBillboard") &&
              flyToEntity(selectedPrimitive);
            flyTo3dTilesetFunctions(
              obj.id?._customPropBillboard?.layer?.entity?._asset.layerid
            );
            return;
          }

          if (picked?.id?.kmlItem) {
            //Kml Pick Selection
            const kmlItem = picked?.id?.kmlItem;
            if (!openAccordians1.includes(kmlItem.projectId)) {
              openAccordians1.push(kmlItem.projectId);
            }
            if (!openAccordians2.includes(kmlItem.folderId)) {
              openAccordians2.push(kmlItem.folderId);
            }
            vectorLayerId = kmlItem.id;

            selectedItem = {
              projectId: kmlItem.projectId,
              folderId: kmlItem.folderId,
              fileId: kmlItem.id,
            };

            setLayerPanelSelected(kmlItem.id);
          }
          if (
            defined(picked) &&
            !picked?.id?.hasOwnProperty("_customPropBillboard")
          ) {
            //Kml Pick
            if (picked.id._name == "camera") {
              setRightDrawer(0);
              document.getElementsByClassName(
                "cesium-viewer-toolbar"
              )[0].style.right = "5px";
              handleInfoDrawerClose();
              return;
            }

            let description = picked?.id?._description?._value;
            let featureId;
            console.log("pickedddd", picked);
            if (picked?.id?._label) {
              //Kml Point Pick
              setInfoLayerSelected("Point");
              featureId = picked?.id?._position._value;
              if (description?.includes("</tbody>")) {
                description =
                  description.split("</tbody>")[0] +
                  "<tr><th>Total Points</th><td>" +
                  picked?.id.entityCollection._entities._array.length +
                  "</td></tr>" +
                  description.split("</tbody>")[1];
              }
            } else if (picked?.id?._polyline) {
              //Kml Line Pick
              console.log("Clicked LineString", picked?.id?._polyline);
              setInfoLayerSelected("LineString");
              if (description?.includes("</tbody>")) {
                description =
                  description.split("</tbody>")[0] +
                  "<tr><th>Total Vertices</th><td>" +
                  picked?.id._polyline._positions._value.length +
                  "</td></tr>" +
                  description.split("</tbody>")[1];
              }
              featureId = picked?.id?._polyline._positions._value[0];
            } else if (picked?.id?._polygon) {
              //Kml Polygon Pick
              setInfoLayerSelected("Polygon");
              featureId = picked?.id?._polygon._hierarchy._value.positions[0];
              if (description?.includes("</tbody>")) {
                description =
                  description.split("</tbody>")[0] +
                  "<tr><th>Total Vertices</th><td>" +
                  (picked?.id?._polygon._hierarchy._value.positions.length -
                    1) +
                  "</td></tr>" +
                  description.split("</tbody>")[1];
              }
            } else if (picked?.id?._position) {
              //Kml Others Pick
              setInfoLayerSelected("Others");
              featureId = picked?.id?._position._value;
            }
            if (
              picked?.id?.entityCollection?._owner?._name?.includes(
                "userGenerated"
              )
            ) {
              //Usergenerated Kml Pick
              setEditableVector(
                loadedEntitties.filter((ent) => ent.id == vectorLayerId)[0]
              );
              setAltMode(
                picked.id.kmlItem.altitudeMode
                  ? picked.id.kmlItem.altitudeMode
                  : "absolute"
              );
              picked.id.kmlItem.altitudeMode;
            } else {
              setEditableVector(false);
            }

            if (featureId) {
              setInfoLayerSelectedId(vectorLayerId);
              setInfoLayerSelectedFeatureId(featureId);

              getFeatureMediaFunction(vectorLayerId, featureId);
            } else {
              setInfoLayerSelectedId(null);
              setInfoLayerSelectedFeatureId(null);
              setInfoLayerSelectedMedia([]);
            }

            setInfoLayerProperties(description);

            let kmlName = "";
            document
              .querySelectorAll(".infoPanelDescription td")
              ?.forEach((item) => {
                if (item.parentNode.innerHTML.includes("<th>name")) {
                  kmlName = item.textContent;
                }
              });
            setInfoLayerSelectedName(
              picked.id._name ? picked.id._name : description ? kmlName : ""
            );

            if (picked.id?._kml || picked.id._name) {
              if (device === "Mobile" && picked.primitive._imageId) {
                const kmlItem = picked?.id?.kmlItem;
                let vectorLayerId = kmlItem.id;
                let selected = allLayers.filter(
                  (layer) => layer.id == vectorLayerId
                );
                let isVector = picked?.id?._position._value;
                isVector = "" + isVector;
                isVector = isVector.replaceAll(",", "");
                let index = 0;
                if (selected[0].feature_attributes.length) {
                  if (isVector) {
                    infoLayerId = null;
                    index = selected[0].feature_attributes.findIndex(
                      (x) => x.featureId?.replaceAll(",", "") === isVector
                    );
                    if (
                      index < 0 &&
                      selected[0].feature_attributes.length &&
                      selected[0].feature_attributes[
                        getNullFeature(selected[0].feature_attributes)
                      ]?.links.length
                    ) {
                      index = getNullFeature(selected[0].feature_attributes);
                    }
                  }
                  if (index < 0) {
                    setRightDrawer(350);
                    document.getElementsByClassName(
                      "cesium-viewer-toolbar"
                    )[0].style.right = "263px";
                    handleInfoDrawerOpen();
                    setMediaAccordian(false);
                  } else {
                    if (
                      selected[0].feature_attributes[index].links.length &&
                      selected[0].feature_attributes[
                        index
                      ].links.filter((item) => item.includes("matterport"))
                        .length
                    ) {
                      setLinkIframe(
                        selected[0].feature_attributes[
                          index
                        ].links.filter((item) => item.includes("matterport"))[0]
                      );
                    } else if (
                      selected[0].feature_attributes[index].links.length &&
                      selected[0].feature_attributes[index].links.filter(
                        (item) =>
                          item.includes("youtube") || item.includes("youtu.be")
                      ).length
                    ) {
                      setLinkIframe(
                        selected[0].feature_attributes[index].links.filter(
                          (item) =>
                            item.includes("youtube") ||
                            item.includes("youtu.be")
                        )[0]
                      );
                    } else if (
                      selected[0].feature_attributes[index].links.length
                    ) {
                      setLinkIframe(
                        selected[0].feature_attributes[index].links[0]
                      );
                    } else {
                      setRightDrawer(350);
                      document.getElementsByClassName(
                        "cesium-viewer-toolbar"
                      )[0].style.right = "263px";
                      handleInfoDrawerOpen();
                      setMediaAccordian(false);
                    }
                  }
                }
              } else {
                setRightDrawer(350);
                document.getElementsByClassName(
                  "cesium-viewer-toolbar"
                )[0].style.right = "263px";
                handleInfoDrawerOpen();
                setMediaAccordian(false);
              }
            } else {
              setRightDrawer(0);
              document.getElementsByClassName(
                "cesium-viewer-toolbar"
              )[0].style.right = "5px";
              handleInfoDrawerClose();
            }
          }
        }

        let objDefined = defined(obj) ? obj.primitive : false;
        let cart3 = getLocationFromScreenXY(
          cords.position.x,
          cords.position.y,
          objDefined
        );
        if (cart3) {
          if (editSelected && editSelectedDouble) {
            if (obj?._name == "pointHere" || obj?.id?._name == "pointHere") {
              viewer.selectedEntity = undefined;
              initialPositions.push(initialPoints[0].x);
              initialPositions.push(initialPoints[0].y);
              initialPoints.push({
                x: initialPoints[0].x,
                y: initialPoints[0].y,
                z: initialPoints[0].z,
              });
              initialPositionsNew.push(initialPositionsNew[0]);
              setPoints(initialPoints);
              const tempinitialPositionsNew = [...initialPositionsNew];
              setPositions(tempinitialPositionsNew);
              setEditSelectedDouble(false);
              // waypointPosition = getPointCoordinates(movement, viewer);
              waypointPosition = initialPositionsNew[0];
              let tempPos = obj.id._position._callback();
              routeEntity = createWaypoint(tempPos, viewer);
              allEntitiesDrag.push(routeEntity);
              return;
            } else if (
              obj?._name == "lineHere" ||
              obj?.id?._name == "lineHere"
            ) {
              viewer.selectedEntity = undefined;
              var closest = allEntitiesDrag.reduce(function(prev, curr) {
                return Math.abs(
                  curr._position._callback().x -
                    obj.id._polyline._positions._value[0].x
                ) <
                  Math.abs(
                    prev._position._callback().x -
                      obj.id._polyline._positions._value[0].x
                  ) &&
                  Math.abs(
                    curr._position._callback().y -
                      obj.id._polyline._positions._value[0].y
                  ) <
                    Math.abs(
                      prev._position._callback().y -
                        obj.id._polyline._positions._value[0].y
                    ) &&
                  Math.abs(
                    curr._position._callback().z -
                      obj.id._polyline._positions._value[0].z
                  ) <
                    Math.abs(
                      prev._position._callback().z -
                        obj.id._polyline._positions._value[0].z
                    )
                  ? curr
                  : prev;
              });
              let thisIndex = allEntitiesDrag.indexOf(closest);
              waypointPosition = getPointCoordinates(movement, viewer);
              routeEntity = createWaypoint(waypointPosition, viewer);
              allEntitiesDrag.splice(thisIndex + 1, 0, routeEntity);
              const ellipsoid = viewer.scene.globe.ellipsoid;
              initialPositions = [];
              let tempPoints = initialPoints;
              initialPoints = [];
              initialPositionsNew = [];
              allEntitiesDrag.map((item, index) => {
                let cartesian = item._position._callback();
                const cartographic = ellipsoid.cartesianToCartographic(
                  cartesian
                );
                let longitudeString = CesMath.toDegrees(
                  cartographic.longitude
                ).toFixed(15);
                longitudeString = Number(longitudeString);
                let latitudeString = CesMath.toDegrees(
                  cartographic.latitude
                ).toFixed(15);
                latitudeString = Number(latitudeString);
                let lineCords = Cartographic.fromCartesian(cartesian);
                let lineCords2 = new Cartesian3.fromRadians(
                  lineCords.longitude,
                  lineCords.latitude,
                  lineCords.height
                );
                initialPositionsNew.push(lineCords2);
                initialPositions.push(radiansToDegrees(lineCords.longitude));
                initialPositions.push(radiansToDegrees(lineCords.latitude));
                initialPoints.push({
                  x: radiansToDegrees(lineCords.longitude),
                  y: radiansToDegrees(lineCords.latitude),
                  z: lineCords.height,
                });
              });
              setPoints(initialPoints);
              const tempinitialPositionsNew = [...initialPositionsNew];
              setPositions(
                tempinitialPositionsNew.length ? tempinitialPositionsNew : []
              );
              return;
            } else if (
              obj?._name == "linePointHere" ||
              obj?.id?._name == "linePointHere"
            ) {
              viewer.selectedEntity = undefined;
              var closest = allEntitiesDrag.reduce(function(prev, curr) {
                return Math.abs(
                  curr._position._callback().x - obj.id._position._value.x
                ) <
                  Math.abs(
                    prev._position._callback().x - obj.id._position._value.x
                  ) &&
                  Math.abs(
                    curr._position._callback().y - obj.id._position._value.y
                  ) <
                    Math.abs(
                      prev._position._callback().y - obj.id._position._value.y
                    ) &&
                  Math.abs(
                    curr._position._callback().z - obj.id._position._value.z
                  ) <
                    Math.abs(
                      prev._position._callback().z - obj.id._position._value.z
                    )
                  ? curr
                  : prev;
              });
              tempPointForLine.point.show = false;
              let thisIndex = allEntitiesDrag.indexOf(closest);
              waypointPosition = getPointCoordinates(movement, viewer);
              routeEntity = createWaypoint(waypointPosition, viewer);
              allEntitiesDrag.splice(thisIndex + 1, 0, routeEntity);
              const ellipsoid = viewer.scene.globe.ellipsoid;
              initialPositions = [];
              let tempPoints = initialPoints;
              initialPoints = [];
              initialPositionsNew = [];
              allEntitiesDrag.map((item, index) => {
                let cartesian = item._position._callback();
                const cartographic = ellipsoid.cartesianToCartographic(
                  cartesian
                );
                let longitudeString = CesMath.toDegrees(
                  cartographic.longitude
                ).toFixed(15);
                longitudeString = Number(longitudeString);
                let latitudeString = CesMath.toDegrees(
                  cartographic.latitude
                ).toFixed(15);
                latitudeString = Number(latitudeString);

                let lineCords = Cartographic.fromCartesian(cartesian);
                let lineCords2 = new Cartesian3.fromRadians(
                  lineCords.longitude,
                  lineCords.latitude,
                  lineCords.height
                );
                initialPositionsNew.push(lineCords2);
                initialPositions.push(radiansToDegrees(lineCords.longitude));
                initialPositions.push(radiansToDegrees(lineCords.latitude));
                initialPoints.push({
                  x: radiansToDegrees(lineCords.longitude),
                  y: radiansToDegrees(lineCords.latitude),
                  z: lineCords.height,
                });
              });
              setPoints(initialPoints);
              const tempinitialPositionsNew = [...initialPositionsNew];
              setPositions(
                tempinitialPositionsNew.length ? tempinitialPositionsNew : []
              );
              return;
            }
            waypointPosition = getPointCoordinates(movement, viewer);
            routeEntity = createWaypoint(waypointPosition, viewer);
            allEntitiesDrag.push(routeEntity);
            viewer.selectedEntity = undefined;
            let lineCords = Cartographic.fromCartesian(waypointPosition);
            let lineCords2 = new Cartesian3.fromRadians(
              lineCords.longitude,
              lineCords.latitude,
              lineCords.height
            );
            initialPositionsNew.push(lineCords2);
            initialPositions.push(radiansToDegrees(lineCords.longitude));
            initialPositions.push(radiansToDegrees(lineCords.latitude));
            initialPoints.push({
              x: radiansToDegrees(lineCords.longitude),
              y: radiansToDegrees(lineCords.latitude),
              z: lineCords.height,
            });
            setPoints(initialPoints);
            const tempinitialPositionsNew = [...initialPositionsNew];
            setPositions(tempinitialPositionsNew);
          }
          if (measureSelected) {
            if (scene.pickPositionSupported) {
              var cartesian = viewer.scene.pickPosition(movement.position);
              // console.log(cartesian);
              if (defined(cartesian)) {
                //add first point
                if (newMeasurePoints.length === 0) {
                  point1 = newMeasurePoints.add({
                    position: new Cartesian3(
                      cartesian.x,
                      cartesian.y,
                      cartesian.z
                    ),
                    color: LINEPOINTCOLOR,
                  });
                } //add second point and lines
                else if (newMeasurePoints.length > 0) {
                  point1 =
                    newMeasurePoints._pointPrimitives[
                      newMeasurePoints._pointPrimitives.length - 1
                    ];
                  point2 = newMeasurePoints.add({
                    position: new Cartesian3(
                      cartesian.x,
                      cartesian.y,
                      cartesian.z
                    ),
                    color: LINEPOINTCOLOR,
                  });
                  point1GeoPosition = Cartographic.fromCartesian(
                    point1.position
                  );
                  point2GeoPosition = Cartographic.fromCartesian(
                    point2.position
                  );
                  point3GeoPosition = Cartographic.fromCartesian(
                    new Cartesian3(
                      point2.position.x,
                      point2.position.y,
                      point1.position.z
                    )
                  );

                  var pl1Positions = [
                    new Cartesian3.fromRadians(
                      point1GeoPosition.longitude,
                      point1GeoPosition.latitude,
                      point1GeoPosition.height
                    ),
                    new Cartesian3.fromRadians(
                      point2GeoPosition.longitude,
                      point2GeoPosition.latitude,
                      point2GeoPosition.height
                    ),
                  ];
                  var pl2Positions = [
                    new Cartesian3.fromRadians(
                      point2GeoPosition.longitude,
                      point2GeoPosition.latitude,
                      point2GeoPosition.height
                    ),
                    new Cartesian3.fromRadians(
                      point2GeoPosition.longitude,
                      point2GeoPosition.latitude,
                      point1GeoPosition.height
                    ),
                  ];
                  var pl3Positions = [
                    new Cartesian3.fromRadians(
                      point1GeoPosition.longitude,
                      point1GeoPosition.latitude,
                      point1GeoPosition.height
                    ),
                    new Cartesian3.fromRadians(
                      point2GeoPosition.longitude,
                      point2GeoPosition.latitude,
                      point1GeoPosition.height
                    ),
                  ];

                  polyline1 = polylines.add({
                    show: true,
                    positions: pl1Positions,
                    width: 1,
                    material: new Material({
                      fabric: {
                        type: "Color",
                        uniforms: {
                          color: LINEPOINTCOLOR,
                        },
                      },
                    }),
                  });
                  polyline2 = polylines.add({
                    show: true,
                    positions: pl2Positions,
                    width: 1,
                    material: new Material({
                      fabric: {
                        type: "PolylineDash",
                        uniforms: {
                          color: Color.BLUE,
                        },
                      },
                    }),
                  });
                  polyline3 = polylines.add({
                    show: true,
                    positions: pl3Positions,
                    width: 1,
                    material: new Material({
                      fabric: {
                        type: "PolylineDash",
                        uniforms: {
                          color: Color.ORANGE,
                        },
                      },
                    }),
                  });
                  var labelZ;
                  if (point2GeoPosition.height >= point1GeoPosition.height) {
                    labelZ =
                      point1GeoPosition.height +
                      (point2GeoPosition.height - point1GeoPosition.height) /
                        2.0;
                  } else {
                    labelZ =
                      point2GeoPosition.height +
                      (point1GeoPosition.height - point2GeoPosition.height) /
                        2.0;
                  }

                  addDistanceLabel(point1, point2, labelZ);
                }
              }
            }
          }
          if (!defined(picked) && !_layer) {
            setRightDrawer(0);
            document.getElementsByClassName(
              "cesium-viewer-toolbar"
            )[0].style.right = "5px";
            handleInfoDrawerClose();
          }
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    //Code for Panning using right click
    var screenSpaceEventHandler = viewer.screenSpaceEventHandler;

    const memo = viewer.scene.screenSpaceCameraController.tiltEventTypes;
    screenSpaceEventHandler.setInputAction(function() {
      viewer.scene.screenSpaceCameraController.enableZoom = false;
      viewer.scene.screenSpaceCameraController.tiltEventTypes = [
        CameraEventType.RIGHT_DRAG,
      ];
    }, ScreenSpaceEventType.RIGHT_DOWN);

    screenSpaceEventHandler.setInputAction(function() {
      viewer.scene.screenSpaceCameraController.enableZoom = true;
      viewer.scene.screenSpaceCameraController.tiltEventTypes = memo;
    }, ScreenSpaceEventType.RIGHT_UP);
    //viewer.scene.screenSpaceCameraController.enableZoom = false;
    //viewer.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];

    seteventHandler(handler);
  };

  const lastNodeRemoveMeasureToolHandler = () => {
    newMeasurePoints.remove(
      newMeasurePoints._pointPrimitives[
        newMeasurePoints._pointPrimitives.length - 1
      ]
    );
    newMeasurePoints._pointPrimitives.pop();
    if (polylines._polylines.length > 2) {
      polylines.remove(polylines._polylines[polylines._polylines.length - 1]);
      viewer.entities.remove(labels[labels.length - 1]);
      polylines.remove(polylines._polylines[polylines._polylines.length - 2]);
      viewer.entities.remove(labels[labels.length - 2]);
      polylines.remove(polylines._polylines[polylines._polylines.length - 3]);
      viewer.entities.remove(labels[labels.length - 3]);
      labels.pop();
      labels.pop();
      labels.pop();
      polylines._polylines.pop();
      polylines._polylines.pop();
      polylines._polylines.pop();
    }
    totalDist = 0;
    labels.forEach((element, index) => {
      if ((index + 1) % 2 == 0) {
        let temp = element._label._text._value.includes("km")
          ? parseFloat(element._label._text._value) * 1000
          : element._label._text._value.includes("miles")
          ? parseFloat(element._label._text._value) * 1609.34
          : element._label._text._value.includes("ft")
          ? (parseFloat(element._label._text._value) / 3.2808).toFixed(1)
          : parseFloat(element._label._text._value);
        totalDist = totalDist + temp;
      }
    });
    setDistance(totalDist);
    // const tempMeasurePositions = [...measurePositions];
    // const tempMeasurePoints = [...measurePoints];

    // tempMeasurePoints.pop();
    // tempMeasurePositions.pop();
    // initialMeasurePoints.pop();
    // initialMeasurePositions.pop();
    // initialMeasurePositions.pop();

    // console.log("initialMeasurePoints", initialMeasurePoints);
    // console.log("initialMeasurePositions", initialMeasurePositions);

    // setMeasurePositions(tempMeasurePositions);
    // setMeasurePoints(tempMeasurePoints);
  };

  const terrainShowHandler = () => {
    // const viewer = mapRef.current.cesiumElement;
    const viewModel = viewer.baseLayerPicker.viewModel;
    let terrainProvider = null;
    if (!showTerrain) {
      // terrainProvider = new createWorldTerrain({
      //   requestWaterMask: true,
      //   requestVertexNormals: true,
      // });

      viewModel.selectedTerrain = viewModel.terrainProviderViewModels[1];
      //Code to change 3dtilesetBillboard occlusion settings
      console.log("TerrainGoingWild", showTerrain);
      mapFeatures.forEach((k) => {
        if (k.type === "6a") {
          k.entity.billboard.disableDepthTestDistance = 35000000;
        }
      });
    } else {
      // terrainProvider = new EllipsoidTerrainProvider({});
      console.log("TerrainGoingWild", showTerrain);
      viewModel.selectedTerrain = viewModel.terrainProviderViewModels[0];
      mapFeatures.forEach((k) => {
        if (k.type === "6a") {
          k.entity.billboard.disableDepthTestDistance = 1000;
        }
      });
    }
    //viewer.scene.terrainProvider = terrainProvider;
  };
  const loadFrustum = async (layer) => {
    console.log("loadFrustum", layer);
    if (layer.latLong.orientation && layer.latLong.coordinates) {
      //const viewer = mapRef.current.cesiumElement;
      var position = Cartesian3.fromDegrees(
        layer.latLong.coordinates[1],
        layer.latLong.coordinates[0],
        layer.latLong.coordinates[2]
      );
      var hpr = new HeadingPitchRoll(
        layer.latLong.orientation[0],
        layer.latLong.orientation[1],
        layer.latLong.orientation[2]
      );
      // var orientation = Cesium.Transforms.headingPitchRollQuaternion(
      //   position,
      //   hpr
      // );
      const fixedFrameTransform = Transforms.localFrameToFixedFrameGenerator(
        "north",
        "west"
      );
      let entity = null;
      // entity = viewer.entities.add({
      //   model: {
      //     uri:
      //       layer.latLong.orientation[0] == 0 &&
      //       layer.latLong.orientation[1] == 0 &&
      //       layer.latLong.orientation[2] == 0
      //         ? "https://metageo-assets.s3.us-west-1.amazonaws.com/camera_no_orientation.glb"
      //         : "https://metageo-assets.s3.us-west-1.amazonaws.com/frustum.glb",
      //     scale: 1.2,
      //     minimumPixelSize: 100,
      //     maximumScale: 200,
      //     color:
      //       layer?.image_taggings?.length > 0
      //         ? Cesium.Color.ORANGE
      //         : Cesium.Color.LIMEGREEN,
      //   },
      //   show: layer.displayStatus ? true : false,
      //   position: position,
      //   orientation: orientation,
      //   name: layer.name,
      //   description: {
      //     layerid: layer.id,
      //     folderid: layer.folder_id,
      //     projectid: layer.projectId,
      //     referenceid: layer.referenceID,
      //     exifData: true,
      //     imageTaggings: layer.image_taggings ? layer.image_taggings : [],
      //   },
      // });
      if (layer?.status?.type === "multires" && layer.thumbnail) {
        entity = viewer.entities.add({
          show: layer.displayStatus ? true : false,
          name: layer.name,
          position: position,
          description: {
            layerid: layer.id,
            folderid: layer.folder_id,
            projectid: layer.projectId,
            referenceid: layer.referenceID,
            exifData: true,
            imageTaggings: layer.image_taggings ? layer.image_taggings : [],
          },
          ellipsoid: {
            radii: new Cartesian3(2, 2, 2),
            material: new ImageMaterialProperty({
              image: layer.thumbnail,
            }),
          },
        });
      } else {
        try {
          entity = await Model.fromGltfAsync({
            url:
              // "https://metageo-assets.s3.us-west-1.amazonaws.com/camera_no_orientation.glb",
              layer.latLong.orientation[0] == 0 &&
              layer.latLong.orientation[1] == 0 &&
              layer.latLong.orientation[2] == 0
                ? "https://metageo-assets.s3.us-west-1.amazonaws.com/camera_no_orientation.glb"
                : "https://metageo-assets.s3.us-west-1.amazonaws.com/frustum.glb",
            modelMatrix: Transforms.headingPitchRollToFixedFrame(
              position,
              hpr,
              Ellipsoid.WGS84,
              fixedFrameTransform
            ),
            scale: 0.6,
            minimumPixelSize: 60,
            maximumScale: 200,
            show: layer.displayStatus ? true : false,
            name: layer.name,
            id: {
              description: {
                layerid: layer.id,
                folderid: layer.folder_id,
                projectid: layer.projectId,
                referenceid: layer.referenceID,
                exifData: true,
                imageTaggings: layer.image_taggings ? layer.image_taggings : [],
              },
            },
            color:
              layer?.image_taggings?.length > 0
                ? Color.ORANGE
                : Color.LIMEGREEN,
          });
        } catch (error) {
          console.log(`Failed to load model. ${error}`);
        }
      }

      if (entity) {
        console.log("EntityFrustum", entity);
        if (layer?.status?.type === "multires" && layer.thumbnail) {
        } else {
          viewer.scene.primitives.add(entity);
        }
        const tempTags = (layer?.image_taggings || []).map((tag) => tag.cls);
        // console.log("tempTags", tempTags);
        updateItemInProjectImages(
          appState.projects.filter(
            (filItem) => filItem.id == layer.projectId
          )[0].children,
          layer.folderId,
          layer.id,
          entity,
          layer.status,
          tempTags
        );
        loadedEntitties.push({
          id: layer.id,
          entity: entity,
          status: layer.status,
        });

        mapFeatures.push({
          id: layer.id,
          type: 5,
          entity: entity,
          show: layer.displayStatus ? true : false,
        });
      }
    }
  };

  const getBoundingBox = (latitude, longitude, radius) => {
    var options = {
      units: "meters",
      properties: { id: "circle" },
    };
    var center = point([longitude, latitude], options);
    var buffered = buffer(center, radius, options);
    var bbox = bbox(buffered);
    return bbox;
  };

  // Function to convert latitude and longitude to Cartesian position
  const latLonToCartesian = (lon, lat, height) => {
    var ellipsoid = Ellipsoid.WGS84;
    return Cartesian3.fromDegrees(lon, lat, height, ellipsoid);
  };

  const getIntersectionHeights = (latLongs, objParam) => {
    //const viewer = mapRef.current.cesiumElement;
    var arrayReturn = [];
    for (var i = 0; i < latLongs.length; i++) {
      var longitude = latLongs[i].geometry.coordinates[0];
      var latitude = latLongs[i].geometry.coordinates[1];
      var position = latLonToCartesian(
        longitude,
        latitude,
        objParam.height - 25
      );
      var position1 = latLonToCartesian(longitude, latitude, 0);

      var rayDirection = new Cartesian3(0, 0, 1);

      // Perform raycasting to find the intersection with the 3D Tileset
      var intersection = viewer.scene.pickFromRay(
        new Ray(position, rayDirection)
      );

      if (defined(intersection) && defined(intersection.position)) {
        //console.log("intersectionPosition", intersection.position);
        // Get the height (elevation) of the intersection point
        var ellipsoid = viewer.scene.globe.ellipsoid;
        var cartographic = ellipsoid.cartesianToCartographic(
          intersection.position
        );
        var longitudeString = CesMath.toDegrees(cartographic.longitude).toFixed(
          6
        );
        var latitudeString = CesMath.toDegrees(cartographic.latitude).toFixed(
          6
        );
        var heightString = cartographic.height;
        const long = parseFloat(longitudeString);
        const lat = parseFloat(latitudeString);
        const height = parseFloat(heightString);
        //console.log("intersectionPositionDegrees", long, lat, cartographic.height);
        arrayReturn.push({
          latitude: lat,
          longitude: long,
          height3DTileset: height,
        });
      } else {
        //heights.push(undefined);
      }
    }

    return arrayReturn;
  };
  const getTerrainHeightsForLatLongs = async (objList) => {
    // const viewer = mapRef.current.cesiumElement;
    var arrayReturn = []; // Array to store terrain heights

    // Loop through the lat-long coordinates
    for (var i = 0; i < objList.length; i++) {
      var longitude = objList[i].longitude;
      var latitude = objList[i].latitude;

      // Create a Cartographic position from the latitude and longitude
      var cartographic = Cartographic.fromDegrees(longitude, latitude);

      try {
        // Get the terrain height at the Cartographic position
        var updatedPositions = await sampleTerrainMostDetailed(
          viewer.terrainProvider,

          [cartographic]
        );

        var terrainHeight = updatedPositions[0].height;

        arrayReturn.push({
          latitude: objList[i].latitude,
          longitude: objList[i].longitude,
          height3DTileset: objList[i].height3DTileset,
          heightTerrain: terrainHeight,
          zOffset: terrainHeight - objList[i].height3DTileset,
        });
      } catch (error) {
        console.log("Error getting terrain height:", error);
      }
    }

    return arrayReturn;
  };

  const heightCaculateBtRayCastMethodHandler = (objParam) => {
    // const viewer = mapRef.current.cesiumElement;
    var extent = objParam.bbox;
    var cellSide = 10;
    var options = { units: "meters" };

    var grid = pointGrid(extent, cellSide, options);
    console.log("gridTurf", grid);
    const tempArray = [];

    const latLongsList = grid.features;

    //Intersection Height List
    let intersectionheightList = [];
    let terrainHeightList = [];
    intersectionheightList = getIntersectionHeights(latLongsList, objParam);
    console.log("intersectionheightList", intersectionheightList);

    // Terrain Height List
    //Without All Promise
    if (intersectionheightList.length > 0) {
      // terrainHeightList = getTerrainHeightsForLatLongs(intersectionheightList);
      // console.log("terrainHeightList", terrainHeightList);
      getTerrainHeightsForLatLongs(intersectionheightList)
        .then(function(terrainInfoArray) {
          var minimumHeightTerrain = terrainInfoArray.reduce(function(
            min,
            current
          ) {
            return Math.min(min, current.heightTerrain);
          },
          Infinity);

          tileLoadEventListnerForPointCloudArray.forEach((k) => {
            if (selectedPrimitive._asset.layerid === k.id) {
              viewer.scene.globe.tileLoadProgressEvent.removeEventListener(
                k.eventListner
              );

              // if (minimumHeightTerrain < -0.99) {
              if (minimumHeightTerrain) {
                console.log(
                  "TileSetHeightDefault",
                  k.height,
                  "TileSetHeight",
                  minimumHeightTerrain
                );
                //const heightF = -Math.abs(minimumHeightTerrain);
                const heightF = minimumHeightTerrain;
                setTileSetHeightDefault(k.height);
                setTileSetHeight(heightF);
                //Registering RayCast value to Backend

                if (infoLayerSelected === "Point Cloud") {
                  const attrPointCloud = {
                    pointcloudStyle: {
                      pointCloudSize: pointCloudSize,
                      styleMode: styleMode,
                      styleClass: styleClass,
                      selectedColorPallet: selectedColorPallet,
                      heightSliderVal: heightSliderVal,
                      altitudeMode: selectedAltitudeMode,
                      tileSetHeightDefault: k.height,
                      tileSetHeight: heightF,
                      rayCastCalculation: true,
                    },
                  };
                  updatePointCloudLayerAttributes(
                    attrPointCloud,
                    infoLayerSelectedId
                  ).then((res) => {
                    if (res) {
                      if ((res.data.status = 200)) {
                        errorDisplay("success", "Styles updated");

                        selectedPrimitive._asset.style =
                          attrPointCloud.pointcloudStyle;
                      }

                      console.log(
                        "sucessStyle",
                        attrPointCloud.pointcloudStyle
                      );
                      setRayCastLoader(false);
                    } else {
                      errorDisplay("error", "Request Unsuccessful");
                      setRayCastLoader(false);
                    }
                  });
                }
              } else {
                k.eventListner(true);
                setRayCastLoader(false);
                // const heightF1 = minimumHeightTerrain - 10;
                // setTileSetHeightDefault(k.height);
                // setTileSetHeight(heightF1);
              }

              console.log(
                "tileLoadProgressEvent2",
                viewer.scene.globe.tileLoadProgressEvent
              );
              //viewer.scene.globe.tileLoadProgressEvent.removeAll();
              //setTileSetHeight(0);
            }
          });
        })
        .catch(function(error) {
          console.error("Error:", error);
        });
    }
  };

  const rayCastCalculation = (tilesetObj) => {
    setRayCastLoader(true);
    let boundingSphere = tilesetObj.boundingSphere;

    let cartographic = Cartographic.fromCartesian(boundingSphere.center);
    const degreesLat = CesMath.toDegrees(cartographic.latitude);
    const degreesLong = CesMath.toDegrees(cartographic.longitude);
    const radius = boundingSphere.radius;
    const bbox = getBoundingBox(degreesLat, degreesLong, radius);

    const objParam = {
      centerLat: degreesLat,
      centerLong: degreesLong,
      height: cartographic.height,
      radius: boundingSphere.radius,
      bbox: bbox,
      tileset: tilesetObj,
    };
    console.log(
      "Tileset3DBBox",
      bbox,
      "TilesetobjParam",
      objParam,
      "boundingSphereCenter",
      cartographic
    );
    heightCaculateBtRayCastMethodHandler(objParam);
  };

  const closeImageViewerHandler = (paramID) => {
    const filterViewer = imageFolderArray.filter((obj) => obj.id != paramID);
    console.log("paramObj", paramID, filterViewer);
    handleImageLoad(filterViewer);
    temImgArray = filterViewer;
  };

  const initialPosition3dTileset = (selectedTileset) => {
    const asset = selectedTileset._asset;
    const tempdefaultStyleArray = [...defaultStyleArray];

    const tilesetClassesArray = asset?.classes ? asset.classes : [];

    const {
      heightSliderVal,
      styleClass,
      selectedColorPallet,
      pointCloudSize,
      styleMode,
      height3DTileset,
      minzMaxz,
      showPointCloudStyleChkBx,
    } = getPointCloudStyleValues(
      tempdefaultStyleArray,
      tilesetClassesArray,
      asset
    );

    const {
      selectedAltitudeMode,
      tileSetHeightDefault,
      tileSetHeight,
      transparencyEnabled,
    } = getHeight3DTilesetValues(height3DTileset, asset);
    // console.log(
    //   "tileSetHeight",
    //   tileSetHeight,
    //   "tileSetHeightDefault",
    //   tileSetHeightDefault,
    //   "height3DTileset",
    //   height3DTileset,
    //   "asset",
    //   asset
    // );
    // console.log("assetCheck", asset.layerid, height3DTileset, asset);
    const style = styleModeHandler(
      styleMode,
      styleClass,
      pointCloudSize,
      heightSliderVal,
      minzMaxz,
      selectedColorPallet
    );

    selectedTileset.style = new Cesium3DTileStyle(style);

    if (tileSetHeight != null) {
      tilesetModelmatrixCalculation(
        selectedTileset,
        tileSetHeightDefault,
        tileSetHeight
      );
    }
  };
  const pointCloudTileSetHandler = async (url) => {
    // console.log("urlPointCloud", url, tokkenResp);
    const tokkenResp = authHeader();
    let replaceUrl = null;
    // const replaceUrl = url.replace(
    //   "https://s3-us-west-1.amazonaws.com/pointscloud-output/",
    //   API_URL + "s3/pointcloud/"
    // );
    if (API_URL == "https://devapi.metageo.io/") {
      replaceUrl = url
        .replace(
          "https://s3-us-west-1.amazonaws.com/pointscloud-output/",
          "https://devtiles.metageo.io/s3/pointcloud/"
        )
        .replace(
          "https://dev.metageo.io/s3/pointcloud/",
          "https://devtiles.metageo.io/s3/pointcloud/"
        )
        .replace(
          "https://devapi.metageo.io/s3/pointcloud/",
          "https://devtiles.metageo.io/s3/pointcloud/"
        );
    } else {
      replaceUrl = url
        .replace(
          "https://s3-us-west-1.amazonaws.com/pointscloud-output/",
          "https://tiles.metageo.io/s3/pointcloud/"
        )
        .replace(
          "https://map.metageo.io/s3/pointcloud/",
          "https://tiles.metageo.io/s3/pointcloud/"
        )
        .replace(
          "https://api.metageo.io/s3/pointcloud/",
          "https://tiles.metageo.io/s3/pointcloud/"
        );
    }
    const securedUrl = new Resource({
      url: replaceUrl,
      queryParameters: { access_token: tokkenResp },
    });

    //console.log("securedUrl", securedUrl);
    // const viewer = mapRef.current.cesiumElement;
    let tilesetObj = null;
    try {
      tilesetObj = await Cesium3DTileset.fromUrl(securedUrl, {
        //url: securedUrl,

        //Optimization
        preloadWhenHidden: true,
        cullRequestsWhileMoving: false,
        skipLevels: 0,
        //set following properties if 3dtilesets have memory issues
        cacheBytes: device === "Desktop" ? 536870912 : 100000000,

        maximumCacheOverflowBytes:
          device === "Desktop" ? 2000000000 : 384000000,

        // maximumScreenSpaceError: 1,
        preferLeaves: true,
        shadows: "ShadowMode.DISABLED",
        foveatedScreenSpaceError: true,
        //new dynamic screen space error optimization
        // maximumScreenSpaceError: 16,
        // dynamicScreenSpaceError: true,
        // dynamicScreenSpaceErrorDensity: 2.0e-4,
        // dynamicScreenSpaceErrorFactor: 24.0,
        // dynamicScreenSpaceErrorHeightFalloff: 0.25,
        //new skip level of detial optimization
        // skipLevelOfDetail: true,
        // baseScreenSpaceError: 1024,
        // skipScreenSpaceErrorFactor: 16,
        // skipLevels: 1,
        // immediatelyLoadDesiredLevelOfDetail: false,
        // loadSiblings: false,
        // cullWithChildrenBounds: true,
        // pointCloudShading: {
        //   attenuation: true,
        //   maximumAttenuation: 2,
        //   backFaceCulling:true,
        // },
      });

      if (!tilesetObj?._asset.hasOwnProperty("minz")) {
        //3D Tileset
        tilesetObj.maximumScreenSpaceError = 6;
        tilesetObj.dynamicScreenSpaceError = true;
        tilesetObj.dynamicScreenSpaceErrorDensity = 2.0e-4;
        tilesetObj.dynamicScreenSpaceErrorFactor = 24.0;
        tilesetObj.dynamicScreenSpaceErrorHeightFalloff = 0.25;
      }

      let heightOffset = 0.0;
      let boundingSphere = tilesetObj.boundingSphere;

      let cartographic = Cartographic.fromCartesian(boundingSphere.center);
      let surface = Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        0.0
      );

      let positionsTileset = [
        {
          longitude: cartographic.longitude,
          latitude: cartographic.latitude,
          height: 0,
        },
      ];

      // console.log("positionsTerrainHeight", positionsTileset, cartographic);

      let promise = await sampleTerrainMostDetailed(
        viewer.terrainProvider,
        positionsTileset
      );

      let terrainHeight = null;
      terrainHeight = promise[0].height;
      let nativeHeight = cartographic.height;

      const tileLoadEventListnerForPointCloud = (tilesetPositioning) => {
        // console.log("queuedTileCount", queuedTileCount);
        // console.log(viewer.scene.globe.tilesLoaded);

        if (viewer.terrainProvider instanceof CesiumTerrainProvider) {
          // console.log("nativeHeight Console", nativeHeight);
          heightOffset = 5;
          if (nativeHeight > 0) {
            heightOffset = terrainHeight - nativeHeight + 5;
            // console.log("nativeHeightTest5", nativeHeight, heightOffset);
          } else if (nativeHeight < 0) {
            heightOffset = terrainHeight * -1;

            // console.log("nativeHeightTest10", nativeHeight, heightOffset);
          }
        } else {
          if (nativeHeight > 0) {
            heightOffset = -nativeHeight + 5;
          } else if (nativeHeight < 0) {
            heightOffset = nativeHeight * -1;
          }
        }

        let offset = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          heightOffset
        );
        let translation = Cartesian3.subtract(
          offset,
          surface,
          new Cartesian3()
        );

        if (tilesetPositioning) {
          tilesetObj.modelMatrix = Matrix4.fromTranslation(translation);
        } else {
          return heightOffset;
        }
      };

      tileLoadEventListnerForPointCloudArray.push({
        eventListner: tileLoadEventListnerForPointCloud,
        height: tileLoadEventListnerForPointCloud(false),
        nativeHeight: nativeHeight,
        id: tilesetObj._asset.layerid,
      });
      if (
        Number(secureLocalStorage.getItem("shareZoom")) ==
        tilesetObj?._asset.layerid
      ) {
        shareTileSetZoom = true;
      }
    } catch (error) {
      console.log(`Error loading tileset: ${error}`);
    }

    return tilesetObj;
  };

  const calculateTurfAreaHandler = () => {
    const tempArray = [];
    measurePoints.forEach((k, i) => {
      tempArray.push([k.x, k.y, k.z]);
    });

    tempArray.push(tempArray[0]);

    let polygon = polygon([tempArray]);

    let area = area(polygon);
    let convertedArea = area / measureUnit.areaCoe;
    setArea(convertedArea);
    // console.log("tempArray", tempArray);

    // console.log("convertedArea", convertedArea);
  };
  const styleModeHandler = (
    expression,
    styleClass,
    pointCloudSize,
    heightSliderVal,
    minzMaxz,
    selectedColorPallet
  ) => {
    let style = {};
    switch (expression) {
      case "classification":
        const tempArray = [];
        const conditionsArray = [];
        styleClass.forEach((k, i) => {
          if (k.active) {
            const strShow = "${CLASSIFICATION} === " + k.classId + " ||";
            tempArray.push(strShow);
          }

          conditionsArray.push([
            "${CLASSIFICATION} === " + styleClass[i].classId + " ",
            "color('" + styleClass[i].color + "')",
          ]);
        });
        let stringArray = "false";

        if (tempArray.length > 0) {
          const joinArray = tempArray.join(" ");

          stringArray = joinArray.slice(0, -2);
        }

        //console.log("stringArray", stringArray);
        // console.log("styleClass[0].color", styleClass[0].color);
        style = {
          color: {
            conditions: conditionsArray,
          },
          show: stringArray,
          pointSize: pointCloudSize,
        };
        return style;
        break;
      case "height":
        style = {
          defines: {
            distance:
              "clamp(distance(${POSITION_ABSOLUTE}, vec3(4401699.414399114, 225012.6097161405, 4595431.437015289)) / 25.0, 0.0, 1.0)",
          },
          color: {
            conditions: [
              [
                "${INTENSITY}/100 >= " +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "+(((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3)*2)",
                'mix(color("' +
                  selectedColorPallet.color2 +
                  '"), color("' +
                  selectedColorPallet.color1 +
                  '"), clamp(((${INTENSITY}/100.0)-(' +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "+(((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3)*2)))/((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3), 0.0, 1.0))",
              ],
              [
                "${INTENSITY}/100 >= " +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "+((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3) && ${INTENSITY}/100 < " +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "+(((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3)*2)",
                'mix(color("' +
                  selectedColorPallet.color3 +
                  '"), color("' +
                  selectedColorPallet.color2 +
                  '"), clamp(((${INTENSITY}/100.0)-(' +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "+((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3)))/((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3), 0.0, 1.0))",
              ],
              [
                "${INTENSITY}/100 >= " +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  " && ${INTENSITY}/100 < " +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "+((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3)",
                'mix(color("' +
                  selectedColorPallet.color4 +
                  '"), color("' +
                  selectedColorPallet.color3 +
                  '"), clamp((${INTENSITY}/100.0-' +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ") /(((" +
                  (heightSliderVal[1] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "-" +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  ")/3)), 0.0, 1.0))",
              ],
              [
                "${INTENSITY}/100 < " +
                  (heightSliderVal[0] - minzMaxz[0]) /
                    (minzMaxz[1] - minzMaxz[0]) +
                  "",
                'color("' + selectedColorPallet.color4 + '")',
              ],
            ],
          },
          pointSize: pointCloudSize,
        };
        return style;
        break;

      default:
        // rgb
        style = {
          pointSize: pointCloudSize,
        };
        return style;
    }
  };

  const groupImagesLoad = (index) => {
    let temImgArray = imageFolderArray;
    let upperLimit = index + 6;

    if (imgArray.length > upperLimit) {
      for (let i = index; i < upperLimit; i++) {
        console.log("ForLoopif", imgArray[i]);
        const k = imgArray[i];
        const img = new Image();
        if (k.folderUrl) {
          img.src = k.folderUrl;
        } else {
          img.src = k.data.replace(/['"]+/g, "");
        }

        const isObjectIdPresent = temImgArray.some((obj) => obj.id === k.id);

        if (!isObjectIdPresent) {
          temImgArray.push({
            name: k.name,
            img: img,
            src: img.src,
            id: k.id,
            regions: k.regions.regions,
          });
        }
        if (i === upperLimit - 1) {
          img.onload = () => {
            dispatch({
              type: LOADING_MESSAGE,
              isLoading: false,
            });
            setImageViewer(true);

            // selectedItem.fileId = k.id;
            const selectedImageId = k.id;

            setLayerPanelSelected(selectedImageId);
          };
          img.onerror = () => {
            dispatch({
              type: LOADING_MESSAGE,
              isLoading: false,
            });
            dispatch({
              type: SET_MESSAGE,
              payload: {
                message: "Image Load Failed",
                severity: "error",
                isOpen: true,
              },
            });
          };
        }
      }
    } else {
      upperLimit = imgArray.length;
      for (let i = index; i < upperLimit; i++) {
        // console.log("ForLoopelse", imgArray[i]);
        const k = imgArray[i];
        const img = new Image();
        if (k.folderUrl) {
          img.src = k.folderUrl;
        } else {
          img.src = k.data.replace(/['"]+/g, "");
        }

        const isObjectIdPresent = temImgArray.some((obj) => obj.id === k.id);

        if (!isObjectIdPresent) {
          temImgArray.push({
            name: k.name,
            img: img,
            src: img.src,
            id: k.id,
            regions: k.regions.regions,
          });
        }

        if (i === upperLimit - 1) {
          img.onload = () => {
            dispatch({
              type: LOADING_MESSAGE,
              isLoading: false,
            });
            setImageViewer(true);

            //selectedItem.fileId = k.id;
            const selectedImageId = k.id;

            setLayerPanelSelected(selectedImageId);
          };
          img.onerror = () => {
            dispatch({
              type: LOADING_MESSAGE,
              isLoading: false,
            });
            dispatch({
              type: SET_MESSAGE,
              payload: {
                message: "Image Load Failed",
                severity: "error",
                isOpen: true,
              },
            });
          };
        }
      }
    }

    handleImageLoad(temImgArray);
  };

  const handleNextClick = () => {
    imageIndex = imageIndex + 1;
    // console.log("imageIndex", imageIndex);
    if (imageIndex % 5 === 0) {
      handleMultipleOfFive(imageIndex);
    }
  };

  const handleBackClick = () => {
    imageIndex = imageIndex > 0 ? imageIndex - 1 : 0;
    // console.log("imageIndex", imageIndex);
  };

  const handleMultipleOfFive = (index) => {
    if (imgArray.length > index) {
      setImageViewer(false);
      setImageIndexState(index);

      // Function to be executed when the index is a multiple of 5
      console.log(`Index ${index} is a multiple of 5`);
      dispatch({
        type: LOADING_MESSAGE,
        isLoading: true,
      });
      groupImagesLoad(index);

      // Add your code logic for handling index as a multiple of 5
    }
  };

  const addKeyDownFunctions = (event) => {
    const elements = document.querySelectorAll([
      ".image-annotate-container .MuiButtonBase-root",
    ]);
    const length = elements.length;

    if (event.key === "ArrowLeft" && !leftKeyPressed) {
      console.log("keydownLeft");
      if (elements.length > 0) {
        elements[0].click();
      }

      // handleBackClick();
      leftKeyPressed = true;
    }

    if (event.key === "ArrowRight" && !rightKeyPressed) {
      // if (imgArray.length > imageIndex) {
      //   console.log("keydownRight");
      //   elements[0].click();
      //   // handleNextClick();
      // }
      if (elements.length > 0) {
        elements[1].click();
      }
      rightKeyPressed = true;
    }
    if (event.key === "Escape" && !escKeyPressed) {
      console.log("keydownEsc");
      setImageViewer(false);
      escKeyPressed = true;
    }
  };
  const addKeyUpFunctions = (event) => {
    if (event.key === "ArrowLeft") {
      leftKeyPressed = false;
    }

    if (event.key === "ArrowRight") {
      rightKeyPressed = false;
    }
    if (event.key === "Escape") {
      escKeyPressed = false;
    }
  };
  const initOsmBuildings = async () => {
    tileset = await createOsmBuildingsAsync();
    tileset._asset["name"] = "CesiumOsmBuildings";
  };
  const removeKeyListeners = () => {
    if (!leftKeyPressed || !rightKeyPressed) {
      document.removeEventListener("keyup", addKeyUpFunctions);
      document.removeEventListener("keydown", addKeyDownFunctions);
      leftKeyPressed = false;
      rightKeyPressed = false;
      escKeyPressed = false;
    }
  };

  useEffect(() => {
    if (showCompBtn) {
      // Time Slider Movement Functions
      const slider = document.getElementById("slider");
      // console.log("slider", slider);
      viewer.scene.splitPosition =
        slider.offsetLeft / slider.parentElement.offsetWidth;

      if (eventHandlerSlider) {
        eventHandlerSlider.destroy();
      }

      const handlerTemp = new ScreenSpaceEventHandler(slider);

      let moveActive = false;

      function move(movement) {
        if (!moveActive) {
          return;
        }

        const relativeOffset = movement.endPosition.x;
        const splitPosition =
          (slider.offsetLeft + relativeOffset) /
          slider.parentElement.offsetWidth;
        slider.style.left = `${100.0 * splitPosition}%`;

        // viewer.scene.splitPosition = splitPosition - 0.085;
        viewer.scene.splitPosition = splitPosition;
        // console.log("splitPosition", viewer.scene.splitPosition);
      }

      handlerTemp.setInputAction(function() {
        moveActive = true;
      }, ScreenSpaceEventType.LEFT_DOWN);
      handlerTemp.setInputAction(function() {
        moveActive = true;
      }, ScreenSpaceEventType.PINCH_START);

      handlerTemp.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
      handlerTemp.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);

      handlerTemp.setInputAction(function() {
        moveActive = false;
      }, ScreenSpaceEventType.LEFT_UP);
      handlerTemp.setInputAction(function() {
        moveActive = false;
      }, ScreenSpaceEventType.PINCH_END);

      seteventHandlerSlider(handlerTemp);
    }
  }, [showCompBtn]);

  useEffect(() => {
    if (infoLayerProperties) {
      setAttrAccordian(true);
    } else {
      setAttrAccordian(false);
    }
  }, [infoLayerProperties]);
  useEffect(() => {
    if (document.getElementById("launcher-frame")) {
      if (rightDrawer == 0) {
        document.getElementById("launcher-frame").style.right = "92px";
      } else {
        document.getElementById("launcher-frame").style.right = "355px";
      }
    }
  }, [rightDrawer]);

  const domButtonsHide = (elements) => {
    if (elements.length > 0) {
      for (let i = elements.length - 1; i >= 0; i--) {
        if (
          elements[i].innerText === "Settings" ||
          elements[i].innerText === "Clone" ||
          elements[i].innerText === "Prev" ||
          elements[i].innerText === "Next"
        ) {
          elements[i].style.display = "none";
        }
      }
    }
  };

  useEffect(() => {
    if (imageFolderArray.length > 0) {
      const elements = document.querySelectorAll([
        ".image-annotate-container .MuiButtonBase-root",
      ]);
      const length = elements.length;

      if (elements.length > 0) {
        domButtonsHide(elements);
      }
    }
  }, [imageFolderArray, imageViewer]);
  const hidePointCloudKmlFunction = () => {
    //const viewer = mapRef.current.cesiumElement;
    const primitivesArray = viewer.scene._primitives._primitives;
    const dataSourcesArray = viewer.dataSources._dataSources;

    primitivesArray.forEach((k, i) => {
      if (k instanceof Cesium3DTileset) {
        // console.log("TileSet",k)
        if (k.show) {
          // console.log("k._url", k._url);
          const ID = k._url.split(".")[3].split("/tileset")[0];

          // console.log("ID", ID);
          selectedTileSetsCamerChange.push({ id: ID });
          k.show = false;
        }
      }
    });

    dataSourcesArray.forEach((k, i) => {
      if (k._primitives.show) {
        const kmlID = k._primitives._guid;

        selectedKmlCamerChange.push({ ID: kmlID });
        k._primitives.show = false;
      }
    });

    viewer.scene.globe.tileLoadProgressEvent.removeEventListener(
      tileLoadedListner
    );
  };

  const tileLoadedListner = (queuedTileCount) => {
    //const viewer = mapRef.current.cesiumElement;
    // console.log(queuedTileCount);
    // console.log(viewer.scene.globe.tilesLoaded);
    if (viewer.scene.globe.tilesLoaded) {
      // console.log("TilesLoaded");
      hidePointCloudKmlFunction();
    }
  };

  useLayoutEffect(() => {
    return () => {
      //Enable Scroll when Map View unmounts
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const addGeoLocateEntity = () => {
    //const viewer = mapRef.current.cesiumElement;
    if (billBoardGraphics) {
      viewer.entities.remove(billBoardGraphics[0]);
      viewer.entities.remove(billBoardGraphics[1]);
      viewer.entities.remove(billBoardGraphics[2]);
    }
    const geocodePin = viewer.entities.add({
      name: "geocodePin",
      position: Cartesian3.fromDegrees(0, 0),
      show: false,
      billboard: {
        image: "/images/purple-circle.png",
        heightReference: HeightReference.CLAMP_TO_GROUND,
        disableDepthTestDistance: 35000000,
        scale: 0.5,
      },
    });
    const editBillBoard1 = viewer.entities.add({
      position: Cartesian3.fromDegrees(0, 0),
      name: "MYLOCATION1",
      description: "test!!",
      show: false,
      ellipse: {
        semiMinorAxis: 1.3,
        semiMajorAxis: 1.3,
        material: Color.fromCssColorString("#901DA1"),
        outline: true, // height must be set for outline to display
        zIndex: 1,
      },
      // point: {
      //   outlineColor: Cesium.Color.WHITE,
      //   outlineWidth: 3,
      //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //   color: Cesium.Color.fromCssColorString("#901DA1"),
      //   pixelSize: 10,
      // },
    });
    const editBillBoard = viewer.entities.add({
      position: Cartesian3.fromDegrees(0, 0),
      name: "MYLOCATION",
      description: "test!!",
      show: false,
      ellipse: {
        semiMinorAxis: 2,
        semiMajorAxis: 2,
        material: Color.fromCssColorString("#FFFFFF"),
        outline: true, // height must be set for outline to display
      },
      // point: {
      //   outlineColor: Cesium.Color.WHITE,
      //   outlineWidth: 3,
      //   heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
      //   color: Cesium.Color.fromCssColorString("#901DA1"),
      //   pixelSize: 10,
      // },
    });
    setBillBoardGraphics([editBillBoard, editBillBoard1, geocodePin]);
  };

  const updateMapFeatureShow = (id, show) => {
    mapFeatures.forEach((obj) => {
      if (obj.id === id) {
        obj["show"] = show;
        //Changing Show of 3dtileset Point Icon
        if (obj.type === "6a" && !obj?.intersection) {
          obj.entity.billboard.show = show;
        } else if (obj.type === 6 && obj.entity instanceof Cesium3DTileset) {
          if (obj.intersection) {
            obj.entity.show = show;
          } else {
            obj.entity.show = false;
          }
        }
      }
    });
    // console.log("updateMapFeatureShow", mapFeatures, id, show);
  };

  // const onImageryLayerReady = (imageryLayer) => {
  //   if (imageryLayer._readyEvent) {
  //     imageryLayer._readyEvent.addEventListener(() => {
  //       console.log("viewModel", imageryLayer.imageryProvider._imageryProvider);
  //       imageryLayer.imageryProvider._imageryProvider.minimumLevel = 0;
  //       imageryLayer.imageryProvider._imageryProvider.maximumLevel = 20;
  //       imageryLayer.imageryProvider._imageryProvider.tileWidth = 20;
  //       imageryLayer.imageryProvider._imageryProvider.tileHeight = 20;
  //     });
  //   }
  // };
  // const improveBaseMapLoadingAndViewHandler = (viewerParam) => {
  //   // Initial imagery layer ready event
  //   const initialImageryLayer = viewerParam.imageryLayers.get(0);
  //   onImageryLayerReady(initialImageryLayer);

  //   // Listen for changes in the base layer
  //   Cesium.knockout
  //     .getObservable(viewerParam.baseLayerPicker.viewModel, "selectedImagery")
  //     .subscribe(() => {
  //       const newImageryLayer = viewerParam.imageryLayers.get(0);

  //       onImageryLayerReady(newImageryLayer);
  //     });
  // };
  const previewImageFunc = (obj) => {
    setImgLoader(true);
    let selectedProjId = obj.projectId;
    let selectedFoldId = obj.folderId;
    let selectedImageId = obj.layerId;
    const foundObject = temImgArray.find((obj) => obj.id === selectedImageId);
    if (foundObject) {
      setImgLoader(false);
    } else {
      getSingleLayer(selectedImageId)
        .then((res) => {
          console.log("getSingleLayer", res);
          if (res.status === 200) {
            imageIndex = 0;
            setImageIndexState(0);
            imgArray = [];
            if (res?.data?.layer?.status?.status === "Complete") {
              res.data.layer["regions"] = res?.data?.regions;
              imgArray.push(res.data.layer);
            }

            const getData = {
              selectedProjId: selectedProjId,
              selectedFoldId: selectedFoldId,
              selectedImageId: selectedImageId,
            };
            console.log("getSingleLayer", res, imgArray);

            if (imgArray?.length && imgArray[0] !== undefined) {
              const temImgArray = imgPushingFunction(1, imgArray, getData);

              handleImageLoad(temImgArray);
              setImgLoader(true);
            } else {
              setImgLoader(false);
              dispatch({
                type: SET_MESSAGE,
                payload: {
                  message: "Image Load Failed",
                  severity: "error",
                  isOpen: true,
                },
              });
            }
          }
        })
        .catch((error) => {
          console.log("errorImage", error);
          //dispatch({ type: LOADING_MESSAGE, isLoading: false });
          setImgLoader(false);
        });
    }
  };
  const tilesetModelmatrixCalculation = (
    tilesetSelected,
    tileSetHeightDefault,
    tileSetHeight
  ) => {
    let heightOffset = 0.0;
    let boundingSphere = tilesetSelected.boundingSphere;

    let cartographic = Cartographic.fromCartesian(boundingSphere.center);
    let surface = Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      0
    );

    //let nativeHeight = cartographic.height;
    heightOffset = tileSetHeightDefault + tileSetHeight;

    let offset = Cartesian3.fromRadians(
      cartographic.longitude,
      cartographic.latitude,
      heightOffset
    );
    let translation = Cartesian3.subtract(offset, surface, new Cartesian3());
    tilesetSelected.modelMatrix = Matrix4.fromTranslation(translation);
  };

  useEffect(() => {
    if (viewer) {
      addGeoLocateEntity();
    }
  }, [viewer]);
  useEffect(() => {
    if (
      !secureLocalStorage.getItem("industry") &&
      secureLocalStorage.getItem("user") !== "share"
    ) {
      history.push("/update-user");
    }
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    const viewer = new Viewer(mapRef.current, {
      shouldAnimate: false,
    });
    //Terria Widget

    const navigationOptions = {
      defaultResetView: Rectangle.fromDegrees(71, 24, 74, 26), // Smaller extent
      enableCompass: true,
      enableZoomControls: true,
      enableDistanceLegend: true,
      containerWidth: 125,
      units: "kilometers",
      distanceLabelFormatter: (distance, units) =>
        `${distance.toFixed(2)} ${units}`,
    };

    // Extend the Viewer with the navigation mixin
    viewer.extend(viewerCesiumNavigationMixin, navigationOptions);

    const distanceLegendDiv = document.querySelector(".distance-legend");
    // Create a new .distance-legend-label child element
    const legendLabelDiv = document.createElement("div");
    legendLabelDiv.className = "distance-legend-label-xy";

    // Append the label div to the .distance-legend container
    distanceLegendDiv.appendChild(legendLabelDiv);

    let geocoderFunc = viewer.geocoder.viewModel.destinationFound;
    viewer.geocoder.viewModel.destinationFound = (e, bbox) => {
      geocoderFunc(e, bbox);
      let center = Rectangle.center(bbox);
      let geocoderEnt = viewer.entities._entities._array.filter(
        (locEnt) => locEnt._name == "geocodePin"
      )[0];
      geocoderEnt.show = true;
      geocoderEnt.position = new Cartesian3.fromRadians(
        center.longitude,
        center.latitude,
        center.height
      );
    };
    viewer.scene.globe.maximumScreenSpaceError = 2;
    // Define the options for the directional light.
    var lightOptions = {
      direction: Cartesian3.normalize(
        new Cartesian3(1.0, 1.0, 1.0),
        new Cartesian3()
      ), // Set the light direction.
      color: Color.WHITE, // Set the light color.
      intensity: 2.0, // Set the light intensity.
    };

    // Create a new DirectionalLight with the specified options.
    var directionalLight = new DirectionalLight(lightOptions);

    // Add the directional light to the viewer's scene.
    viewer.scene.light = directionalLight;
    //viewer.scene.globe.enableLighting = true;

    //Setting Noon For Light
    // Get the current simulation clock

    //var clock = viewer.clock;

    // Create a date object representing 20.00
    //var noonTime = new Date();
    //noonTime.setHours(10, 0, 0, 0);
    // Set the simulation clock to 12:00 PM
    //clock.currentTime = Cesium.JulianDate.fromDate(noonTime);
    // clock.multiplier = 10000; // Optional: Set the clock speed (1 is real-time)

    const onTileLoadProgress = () => {
      if (viewer.scene.globe.tilesLoaded) {
        setViewer(viewer);
        // Remove the event listener
        viewer.scene.globe.tileLoadProgressEvent.removeEventListener(
          onTileLoadProgress
        );
      }
    };

    // Add the event listener
    viewer.scene.globe.tileLoadProgressEvent.addEventListener(
      onTileLoadProgress
    );

    tempPointForLine = viewer.entities.add({
      name: "linePointHere",
      point: {
        show: false,
        pixelSize: 10,
        color: Color.RED,
      },
    });
    initOsmBuildings();
    //Disable Scroll In Map View
    document.body.style.overflowY = "hidden";
    const camera = viewer.camera;
    const primitivesArray = viewer.scene._primitives._primitives;
    const dataSourcesArray = viewer.dataSources._dataSources;
    if (window.matchMedia("(max-width: 767px)").matches) {
      setDevice("Mobile");
    } else {
      setDevice("Desktop");
      Sabar("layers");
    }

    const availStor = (
      (Number(secureLocalStorage.getItem("storageCapacity")) -
        Number(secureLocalStorage.getItem("availableStorage"))) /
      1024
    ).toFixed(2);
    const totalStor = (
      Number(secureLocalStorage.getItem("storageCapacity")) / 1024
    ).toFixed(2);
    const percStor = (availStor / totalStor) * 100;

    if (percStor >= 90) {
      setStorageDialog(true);
    }

    //Add Remove Layers on the basis of height filter
    camera.changed.addEventListener(
      function() {
        const tileset3dIds = [];
        const filterMapFeatureObjs3dTilesets = filterObjectsInIdList(
          mapFeatures,
          tilesetLayerId
        );
        const frustum = camera.frustum;
        const cullingVolume = frustum.computeCullingVolume(
          camera.position,
          camera.direction,
          camera.up
        );

        filterMapFeatureObjs3dTilesets
          .filter((k) => k.type === 6 && k.entity instanceof Cesium3DTileset)
          .forEach((k) => {
            const boundingSphere = k?.entity?.boundingSphere;
            const originalRadius = boundingSphere.radius;

            // Increase radius to account for the height shift
            boundingSphere.radius += 100;

            const intersection = cullingVolume.computeVisibility(
              boundingSphere
            );

            // Reset radius after intersection check
            boundingSphere.radius = originalRadius;

            if (intersection === 0) {
              k.intersection = true;
              tileset3dIds.push(k.id);

              const primitives = viewer.scene.primitives._primitives;

              if (!containsObject(primitives, k.entity)) {
                viewer.scene.primitives.add(k.entity);
              } else {
                k.entity.show = k.show;
              }
            } else {
              k.entity.show = false;
              k.intersection = false;
            }
          });

        //most optimized
        mapFeatures.forEach((k) => {
          if (tileset3dIds.length > 0) {
            const matchedId = tileset3dIds.some((i) => k.id === i);

            if (matchedId && k.type === "6a") {
              k.entity.billboard.show = false;
              k.intersection = true;
            } else if (!matchedId && k.type === "6a") {
              k.entity.billboard.show = k.show;
              k.intersection = false;
            }
            //  else if (matchedId && k.type === 6) {
            //   if (k?.entity?.show && k.show) {
            //     mapFeatures
            //       .filter((n) => n.id === k.id && n.type === "6a")
            //       .forEach((n) => {
            //         n.entity.billboard.show = false;
            //       });
            //   }
            //   // else {
            //   //   mapFeatures
            //   //     .filter((n) => n.id === k.id && n.type === "6a")
            //   //     .forEach((n) => {
            //   //       n.entity.billboard.show = true;
            //   //     });
            //   // }
            // }
          } else if (k.type === "6a") {
            k.entity.billboard.show = k.show;
            k.intersection = false;
          }
        });
      }.bind(camera)
    );

    const viewModel = viewer.baseLayerPicker.viewModel;

    viewModel.selectedImagery =
      viewModel.imageryProviderViewModels[BASEMAP_INDEX];
    viewModel.selectedTerrain = viewModel.terrainProviderViewModels[1];

    let testElement = document.createElement("div");
    testElement.style.position = "absolute";
    testElement.id = "hoverInfo";
    testElement.style.display = "none";
    testElement.style.marginLeft = "-26px";
    testElement.style.marginTop = "-82px";
    testElement.style.cursor = "move";
    let isDown = false;
    testElement.addEventListener("mousedown", function() {
      isDown = true;
    });
    window.addEventListener("mouseup", function() {
      isDown = false;
    });
    window.addEventListener("mousemove", function(e) {
      if (isDown) {
        e.preventDefault();
        testElement.style.top = event.clientY + 70 + "px";
        testElement.style.left = event.clientX + "px";
      }
    });
    testElement.innerHTML =
      '<div id="cardHover" class="card">' +
      '  <div class="container">' +
      "    <h4><b id='hoverInfoName'></b><span id='closePopOver' style='display:none;float: right;font-size: 20px;font-weight: 600;margin: -9px -5px;cursor:pointer'>❌</span></h4>" +
      "    <p id='hoverInfoDescription'></p>" +
      "    <div id='hoverInfoEmbedded'></div>" +
      "  </div>" +
      "</div>" +
      `<h3 id="editHover" Right click to remove<br/>or drag to move</h3>`;
    testElement.addEventListener("click", () => {
      if (document.getElementById("hoverImage")) {
        let obj = JSON.parse(
          document.getElementById("hoverImage").getAttribute("data-layer")
        );
        previewImageFunc(obj);
      }
      if (document.querySelector("#hoverInfoEmbedded span")) {
        setLinkIframe(
          document.querySelector("#hoverInfoEmbedded span").getAttribute("href")
        );
      }
    });
    viewer.container.appendChild(testElement);
    document.querySelector(".cesium-widget canvas").addEventListener(
      "webglcontextlost",
      function(event) {
        event.preventDefault();
        //alert("Webgl crashed on the device browser.");
        window.location.reload();
      },
      false
    );
    testElement.addEventListener(
      "mouseover",
      function(event) {
        isMouseHover = true;
      },
      false
    );
    document.querySelector("#closePopOver").addEventListener(
      "click",
      function(event) {
        isMouseHover = false;
        testElement.style.display = "none";
        clearTimeout(infoHoverTimeout);
        infoHoverTimeout = null;
      },
      false
    );
    testElement.addEventListener(
      "mouseleave",
      function(event) {
        if (!isDown) {
          isMouseHover = false;
          testElement.style.display = "none";
          clearTimeout(infoHoverTimeout);
          infoHoverTimeout = null;
        }
      },
      false
    );
    let scratch2dPosition = new Cartesian2();
    let handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

    handler.setInputAction(function(movement) {
      const legendDiv = document.querySelector(".distance-legend-label-xy");
      //X Y Position on mouse movement
      const cartesian = viewer.camera.pickEllipsoid(
        movement.endPosition,
        viewer.scene.globe.ellipsoid
      );

      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        const lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(4);
        const lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(4);

        // Update coordinatesRef and directly modify the .distance-legend div
        coordinatesRef.current = { lat, lng };
        if (legendDiv) {
          legendDiv.innerHTML = `Lat: ${lat}, Lng: ${lng}`;
        }
      }
      //Viewer Mouse Move Functions Starts
      //console.log("onMouseMove");
      var elements = document.getElementsByClassName(
        "cesium-baseLayerPicker-categoryTitle"
      );
      var elements1 = document.getElementsByClassName(
        "cesium-baseLayerPicker-itemLabel"
      );
      for (var i = 0, len = elements1.length; i < len; i++) {
        if (elements1[i].innerHTML.includes("Cesium")) {
          elements1[i].innerHTML = "World Terrain";
        }
      }
      for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].innerHTML = "";
      }
      //Viewer Mouse Move Functions Ends

      if (!editSelected2 && !measureSelected2) {
        document.getElementById("cardHover").style.display = "block";
        document.getElementById("editHover").style.display = "none";
        const pickedObject = viewer.scene.pick(movement.endPosition);
        if (
          defined(pickedObject) &&
          pickedObject.id?._name !== "MYLOCATION" &&
          pickedObject.id?._name !== "MYLOCATION1" &&
          pickedObject.id?._name !== "geocodePin"
        ) {
          let position2d;
          // const cartesian = viewer.camera.pickEllipsoid(
          //   movement.endPosition,
          //   viewer.scene.globe.ellipsoid
          // );
          let isVector = false;
          let selected = null;

          if (pickedObject?.id?.kmlItem) {
            const kmlItem = pickedObject?.id?.kmlItem;
            vectorLayerId = kmlItem.id;
          }

          if (pickedObject?.id?.description?.exifData) {
            selected = allLayers.filter(
              (layer) => layer.id == pickedObject?.id.description.layerid
            );
          } else if (pickedObject?.id?.description?._value?.exifData) {
            selected = allLayers.filter(
              (layer) =>
                layer.id == pickedObject?.id.description._value?.layerid
            );
          } else if (pickedObject?.primitive?.allTilesLoaded) {
            selected = allLayers.filter(
              (layer) => layer.id == pickedObject.primitive._asset.layerid
            );
            return;
          } else if (pickedObject?.id?.hasOwnProperty("_customPropBillboard")) {
            selected = allLayers.filter(
              (layer) =>
                layer.id ==
                pickedObject?.id?._customPropBillboard?.layer?.entity._asset
                  .layerid
            );
          } else if (vectorLayerId) {
            // console.log(
            //   "vectorLayerId",
            //   allLayers,
            //   appState.allLayers,
            //   appState.layerArray
            // );
            selected = allLayers.filter((layer) => layer.id == vectorLayerId);
            if (pickedObject?.id?._label) {
              isVector = pickedObject?.id?._position._value;
            } else if (pickedObject?.id?._polyline) {
              isVector = pickedObject?.id?._polyline._positions._value[0];
            } else if (pickedObject?.id?._polygon) {
              isVector =
                pickedObject?.id?._polygon._hierarchy._value.positions[0];
            } else if (pickedObject?.id?._position) {
              isVector = pickedObject?.id?._position._value;
            }
            isVector = "" + isVector;
            isVector = isVector.replaceAll(",", "");
          }
          // console.log("pickedObjectHover", selected);
          if (selected && selected.length) {
            if (
              (infoLayerId !== selected[0].id && !isVector) ||
              (infoLayerId !== selected[0].id &&
                isVector &&
                isVector !== infoLayerFeatureId)
            ) {
              testElement.style.display = "none";
              clearTimeout(popoverTimeout);
              popoverTimeout = null;
              infoLayerId = selected[0].id;
              infoLayerFeatureId = isVector;
              document.getElementById("hoverInfoName").innerHTML =
                selected[0].name;
              let index = 0;
              if (selected[0].thumbnail) {
                document.getElementById("hoverInfoDescription").innerHTML = "";
                document.getElementById("hoverInfoEmbedded").innerHTML =
                  "<img data-layer='" +
                  JSON.stringify({
                    projectId: selected[0].projectId,
                    folderId: selected[0].folderId,
                    layerId: selected[0].id,
                  }) +
                  "' id='hoverImage' style='pointer-events:none;' width='300' src='" +
                  selected[0].thumbnail +
                  "'/>";
              } else if (selected[0].feature_attributes.length) {
                if (isVector) {
                  infoLayerId = null;
                  index = selected[0].feature_attributes.findIndex(
                    (x) => x.featureId?.replaceAll(",", "") === isVector
                  );
                  if (
                    index < 0 &&
                    selected[0].feature_attributes.length &&
                    selected[0].feature_attributes[
                      getNullFeature(selected[0].feature_attributes)
                    ]?.links.length
                  ) {
                    index = getNullFeature(selected[0].feature_attributes);
                  }
                }
                if (index < 0) {
                  document.getElementById("hoverInfoDescription").innerHTML =
                    "";
                  document.getElementById("hoverInfoEmbedded").innerHTML = "";
                } else {
                  document.getElementById("hoverInfoDescription").innerHTML =
                    selected[0].feature_attributes[index].description;
                  if (
                    selected[0].feature_attributes[index].links.length &&
                    selected[0].feature_attributes[index].links.filter((item) =>
                      item.includes("matterport")
                    ).length
                  ) {
                    var data = {
                      key: "af70d99d7f40302f3acf97ec8e9b0d50",
                      q: selected[0].feature_attributes[
                        index
                      ].links.filter((item) => item.includes("matterport"))[0],
                    };
                    document.getElementById("hoverInfoEmbedded").innerHTML =
                      "Loading...";
                    fetchUrlMeta({
                      url: selected[0].feature_attributes[
                        index
                      ].links.filter((item) => item.includes("matterport"))[0],
                    })
                      // fetch("https://api.linkpreview.net", {
                      //   method: "POST",
                      //   mode: "cors",
                      //   body: JSON.stringify(data),
                      // })
                      //   .then((res) => res.json())
                      .then((response) => {
                        // console.log("hoverResp", response.title);
                        document.getElementById(
                          "hoverInfoEmbedded"
                        ).innerHTML = `<span target="_blank" href="${
                          selected[0].feature_attributes[
                            index
                          ].links.filter((item) =>
                            item.includes("matterport")
                          )[0]
                        }"><div class="box" style="width:300px;zoom: .7;margin: auto;">
                      <img id="myimage" src="${
                        response.data.image
                      }" style="width: 300px;pointer-events:none;">
                      <div class="is-clipped">
                        <div id="mytitle" class="has-text-weight-bold" style="font-weight: 600;
                        color:black;">${response.data.title}</div>
                        <div id="mydescription" class="mt-2" style="
                        max-width: 300px;
                        color:black;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    ">${response.data.description}</div>
                        <div id="myurl" class="mt-2 is-size-7" style="font-weight: 600;
                        max-width: 300px;
                        color:black;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;">${
                          JSON.parse(response.config.data).url
                        }</div>
                      </div>
                    </div></span>`;
                      });
                  } else if (
                    selected[0].feature_attributes[index].links.length &&
                    selected[0].feature_attributes[index].links.filter(
                      (item) =>
                        item.includes("youtube") || item.includes("youtu.be")
                    ).length
                  ) {
                    var data = {
                      key: "af70d99d7f40302f3acf97ec8e9b0d50",
                      q: selected[0].feature_attributes[index].links.filter(
                        (item) =>
                          item.includes("youtube") || item.includes("youtu.be")
                      )[0],
                    };
                    document.getElementById("hoverInfoEmbedded").innerHTML =
                      "Loading...";
                    fetchUrlMeta({
                      url: selected[0].feature_attributes[index].links.filter(
                        (item) =>
                          item.includes("youtube") || item.includes("youtu.be")
                      )[0],
                    })
                      // fetch("https://api.linkpreview.net", {
                      //   method: "POST",
                      //   mode: "cors",
                      //   body: JSON.stringify(data),
                      // })
                      //   .then((res) => res.json())
                      .then((response) => {
                        document.getElementById(
                          "hoverInfoEmbedded"
                        ).innerHTML = `<span target="_blank" href="${
                          selected[0].feature_attributes[index].links.filter(
                            (item) =>
                              item.includes("youtube") ||
                              item.includes("youtu.be")
                          )[0]
                        }"><div class="box" style="width:300px;zoom: .7;margin: auto;">
                      <img id="myimage" src="${
                        response.data.image
                      }" style="width: 300px;pointer-events:none;">
                      <div class="is-clipped">
                        <div id="mytitle" class="has-text-weight-bold" style="font-weight: 600;color:black;">${
                          response.data.title
                        }</div>
                        <div id="mydescription" class="mt-2" style="
                        max-width: 300px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color:black;
                    ">${response.data.description}</div>
                        <div id="myurl" class="mt-2 is-size-7" style="font-weight: 600;
                        max-width: 300px;
                        color:black;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        ">${JSON.parse(response.config.data).url}</div>
                      </div>
                    </div></span>`;
                      });
                  } else if (
                    selected[0].feature_attributes[index].links.length
                  ) {
                    var data = {
                      key: "af70d99d7f40302f3acf97ec8e9b0d50",
                      q: selected[0].feature_attributes[index].links[0],
                    };
                    document.getElementById("hoverInfoEmbedded").innerHTML =
                      "Loading...";
                    fetchUrlMeta({
                      url: selected[0].feature_attributes[
                        index
                      ].links[0].includes("http")
                        ? selected[0].feature_attributes[index].links[0]
                        : "http://" +
                          selected[0].feature_attributes[index].links[0],
                    })
                      // fetch("https://api.linkpreview.net", {
                      //   method: "POST",
                      //   mode: "cors",
                      //   body: JSON.stringify(data),
                      // })
                      //   .then((res) => res.json())
                      .then((response) => {
                        document.getElementById(
                          "hoverInfoEmbedded"
                        ).innerHTML = `<span target="_blank" href="${
                          selected[0].feature_attributes[index].links[0]
                        }"><div class="box" style="width:300px;zoom: .7;margin: auto;">
                      <img id="myimage" src="${
                        response.data.image
                      }" style="width: 300px;pointer-events:none;">
                      <div class="is-clipped">
                        <div id="mytitle" class="has-text-weight-bold" style="font-weight: 600;color:black;">${
                          response.data.title
                        }</div>
                        <div id="mydescription" class="mt-2" style="
                        max-width: 300px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color:black;
                    ">${response.data.description}</div>
                        <div id="myurl" class="mt-2 is-size-7" style="font-weight: 600;
                        max-width: 300px;
                        color:black;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        ">${JSON.parse(response.config.data).url}</div>
                      </div>
                    </div></span>`;
                      });
                  } else if (
                    isVector &&
                    selected[0].feature_media.filter(
                      (media) =>
                        media.featureId?.replaceAll(",", "") == isVector
                    ).length
                  ) {
                    document.getElementById("hoverInfoEmbedded").innerHTML = `
                  <img
                    src="${
                      selected[0].feature_media
                        .filter(
                          (media) =>
                            media.featureId?.replaceAll(",", "") == isVector
                        )
                        .sort(function(a, b) {
                          return new Date(b.createdAt) - new Date(a.createdAt);
                        })[0].mediaKey
                    }"
                    style="height: 155px;margin: auto;display: block;pointer-events:none;"
                  ></img>`;
                  } else if (!isVector && selected[0].feature_media.length) {
                    document.getElementById("hoverInfoEmbedded").innerHTML = `
                  <img
                    src="${
                      selected[0].feature_media.sort(function(a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                      })[0].mediaKey
                    }"
                    style="height: 155px;margin: auto;display: block;pointer-events:none;"
                  ></img>`;
                  } else {
                    document.getElementById("hoverInfoEmbedded").innerHTML = "";
                  }
                }
              } else {
                document.getElementById("hoverInfoDescription").innerHTML = "";
                document.getElementById("hoverInfoEmbedded").innerHTML = "";
              }
            }
          }
          if (cartesian) {
            position2d = SceneTransforms.wgs84ToWindowCoordinates(
              viewer.scene,
              cartesian,
              scratch2dPosition
            );
          }
          if (position2d) {
            // Set the HTML position to match the entity's position.
            testElement.style.left = `${position2d.x + infoOffset}px`;
            testElement.style.top = document.getElementById("hoverInfoEmbedded")
              .innerHTML.length
              ? position2d.y - 100 + "px"
              : position2d.y + 50 + "px";
            if (!popoverTimeout && !dragDetected)
              popoverTimeout = setTimeout(() => {
                const pickedObject1 = viewer.scene.pick(movement.endPosition);
                if (defined(pickedObject1)) testElement.style.display = "block";
                popoverTimeout = null;
              }, 600);
            clearTimeout(infoHoverTimeout);
            infoHoverTimeout = null;
          }
        } else {
          if (!infoHoverTimeout)
            infoHoverTimeout = setTimeout(() => {
              if (!isMouseHover) {
                testElement.style.display = "none";
                clearTimeout(infoHoverTimeout);
                infoHoverTimeout = null;
              }
            }, 3500);
        }
      } else if (editSelected2) {
        document.getElementById("cardHover").style.display = "none";
        document.getElementById("editHover").style.display = "block";
        const pickedObject = viewer.scene.pick(movement.endPosition);
        if (defined(pickedObject)) {
          let position2d;
          // const cartesian = viewer.camera.pickEllipsoid(
          //   movement.endPosition,
          //   viewer.scene.globe.ellipsoid
          // );
          if (cartesian) {
            position2d = SceneTransforms.wgs84ToWindowCoordinates(
              viewer.scene,
              cartesian,
              scratch2dPosition
            );
          }
          if (viewer.scene.screenSpaceCameraController.enableRotate) {
            if (pickedObject?.id?._name == "pointHere") {
              document.getElementById("editHover").innerHTML =
                "Right click to remove<br/>or drag to move";
              if (position2d) {
                // Set the HTML position to match the entity's position.
                testElement.style.left = `${position2d.x + infoOffset + 90}px`;
                testElement.style.top = position2d.y + 180 + "px";
                testElement.style.display = "block";
                clearTimeout(infoHoverTimeout);
                infoHoverTimeout = null;
                tempPointForLine.point.show = false;
              }
            } else if (pickedObject?.id?._name == "lineHere") {
              document.getElementById("editHover").innerHTML =
                "Click to add point";
              if (position2d) {
                tempPointForLine.position = viewer.scene.pickPosition(
                  movement.endPosition
                );
                tempPointForLine.point.show = true;
                // Set the HTML position to match the entity's position.
                testElement.style.left = `${position2d.x + infoOffset + 90}px`;
                testElement.style.top = position2d.y + 180 + "px";
                testElement.style.display = "block";
                clearTimeout(infoHoverTimeout);
                infoHoverTimeout = null;
              }
            }
          }
        } else {
          tempPointForLine.point.show = false;
          testElement.style.display = "none";
        }
      }
    }, ScreenSpaceEventType.MOUSE_MOVE);
    handler.setInputAction(function(movement) {
      isMouseHover = false;
      testElement.style.display = "none";
      clearTimeout(infoHoverTimeout);
      clearTimeout(popoverTimeout);
      popoverTimeout = null;
      infoHoverTimeout = null;
    }, CameraEventType.LEFT_DRAG);
    viewer.camera.moveStart.addEventListener(() => {
      isMouseHover = false;
      testElement.style.display = "none";
      clearTimeout(infoHoverTimeout);
      clearTimeout(popoverTimeout);
      popoverTimeout = null;
      infoHoverTimeout = null;
      dragDetected = true;
    });
    viewer.camera.moveEnd.addEventListener(() => {
      dragDetected = false;
    });
    handler.setInputAction(function(movement) {
      // const viewer = mapRef.current.cesiumElement;
      const cords = movement;
      const obj = viewer.scene.pick(cords.position);
      //console.log("RIGHT_CLICK");
      if (obj != undefined) {
        if (obj._name == "pointHere" || obj.id._name == "pointHere") {
          //const viewer = mapRef.current.cesiumElement;
          let delEnt = null;
          allEntitiesDrag = allEntitiesDrag.filter((itemEnt) => {
            if (itemEnt == obj.id) {
              delEnt = itemEnt;
            }
            return itemEnt !== obj.id;
          });
          viewer.entities.remove(delEnt);
          const ellipsoid = viewer.scene.globe.ellipsoid;
          initialPositions = [];
          let tempPoints = initialPoints;
          initialPoints = [];
          initialPositionsNew = [];
          allEntitiesDrag.map((item, index) => {
            let cartesian = item._position._callback();
            const cartographic = ellipsoid.cartesianToCartographic(cartesian);
            let longitudeString = CesMath.toDegrees(
              cartographic.longitude
            ).toFixed(15);
            longitudeString = Number(longitudeString);
            let latitudeString = CesMath.toDegrees(
              cartographic.latitude
            ).toFixed(15);
            latitudeString = Number(latitudeString);

            let lineCords = Cartographic.fromCartesian(cartesian);
            let lineCords2 = new Cartesian3.fromRadians(
              lineCords.longitude,
              lineCords.latitude,
              lineCords.height
            );
            initialPositionsNew.push(lineCords2);
            initialPositions.push(radiansToDegrees(lineCords.longitude));
            initialPositions.push(radiansToDegrees(lineCords.latitude));
            initialPoints.push({
              x: radiansToDegrees(lineCords.longitude),
              y: radiansToDegrees(lineCords.latitude),
              z: lineCords.height,
            });
          });
          setPoints(initialPoints);
          const tempinitialPositionsNew = [...initialPositionsNew];
          setPositions(
            tempinitialPositionsNew.length ? tempinitialPositionsNew : []
          );
        }
      }
    }, ScreenSpaceEventType.RIGHT_CLICK);
    handler.setInputAction(function(movement) {
      //const viewer = mapRef.current.cesiumElement;
      const cords = movement;
      const obj = viewer.scene.pick(cords.position);
      let objDefined = defined(obj) ? obj.primitive : false;
      let cart3 = getLocationFromScreenXY(
        cords.position.x,
        cords.position.y,
        objDefined
      );
      // console.log("LEFT_DOUBLE_CLICK");
      if (obj != undefined) {
        if (obj?._name == "pointHere") {
          if (
            editSelected &&
            (layerTypeSelected == "polygon" || layerTypeSelected == "line") &&
            editSelectedDouble
          ) {
            initialPositions.push(initialPoints[0].x);
            initialPositions.push(initialPoints[0].y);
            initialPoints.push({
              x: initialPoints[0].x,
              y: initialPoints[0].y,
              z: initialPoints[0].z,
            });
            setPoints(initialPoints);
            initialPositionsNew.push(initialPositionsNew[0]);
            const tempinitialPositionsNew = [...initialPositionsNew];
            setPositions(tempinitialPositionsNew);
            setEditSelectedDouble(false);
            waypointPosition = initialPositionsNew[0];
            routeEntity = createWaypoint(waypointPosition, viewer);
            allEntitiesDrag.push(routeEntity);
          }
        } else if (obj?.id?._name == "pointHere") {
          if (
            editSelected &&
            (layerTypeSelected == "polygon" || layerTypeSelected == "line") &&
            editSelectedDouble
          ) {
            initialPositions.push(initialPoints[0].x);
            initialPositions.push(initialPoints[0].y);
            initialPoints.push({
              x: initialPoints[0].x,
              y: initialPoints[0].y,
              z: initialPoints[0].z,
            });
            setPoints(initialPoints);
            initialPositionsNew.push(initialPositionsNew[0]);
            const tempinitialPositionsNew = [...initialPositionsNew];
            setPositions(tempinitialPositionsNew);
            setEditSelectedDouble(false);
            waypointPosition = initialPositionsNew[0];
            routeEntity = createWaypoint(waypointPosition, viewer);
            allEntitiesDrag.push(routeEntity);
          }
        } else {
          cart3 &&
            handleSelectedEntityChanged({
              x: radiansToDegrees(cart3.longitude),
              y: radiansToDegrees(cart3.latitude),
              z: cart3.height,
            });
        }
      }
      handleInfoDrawerClose();
    }, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  }, []);

  useEffect(() => {
    //const viewer = mapRef.current.cesiumElement;
    //Polygon Graphics Code
    if (polygonGraphics) {
      viewer.entities.remove(polygonGraphics);
    }
    if (positions.length > 0 && layerTypeSelected == "polygon") {
      const editPolygon = viewer.entities.add({
        name: "Polygon",
        description: "PolygonGraphics!!",
        polygon: {
          hierarchy: positions,
          material: Color.fromCssColorString(polygonBackground.setColor),
        },
      });
      viewer.entities.remove(editPolygon);
      viewer.entities.add(editPolygon);
      setPolygonGraphics(editPolygon);
    }
    //Polygon Measure Graphics Code
    if (polygonGraphicsMeasure) {
      viewer.entities.remove(polygonGraphicsMeasure);
    }
    if (measurePositions.length > 0) {
      const editPolygonMeasure = viewer.entities.add({
        name: "PolygonMeasure",

        polygon: {
          hierarchy: measurePositions,
          material: Color.fromRgba(0x6757d45f),
        },
      });
      viewer.entities.remove(editPolygonMeasure);
      viewer.entities.add(editPolygonMeasure);
      setPolygonGraphicsMeasure(editPolygonMeasure);
    }

    //Polyline Graphics Code
    if (polyLineGraphics.length > 0) {
      polyLineGraphics.forEach((k) => {
        viewer.entities.remove(k);
      });
    }

    if (layerTypeSelected == "line" || layerTypeSelected == "polygon") {
      let tempPolyLine = [];
      positions.forEach((itemLine, lineIndex) => {
        if (lineIndex !== positions.length - 1) {
          const editPolyline = viewer.entities.add({
            name: "lineHere",
            description: "PolygonGraphics!!",
            polyline: {
              positions: [itemLine, positions[lineIndex + 1]],
              width: typeof lineWidth === "number" ? lineWidth : 3,
              material: Color.fromCssColorString(lineColor.setColor),
              clampToGround: altMode == "absolute" ? false : true,
            },
          });
          viewer.entities.remove(editPolyline);
          viewer.entities.add(editPolyline);
          tempPolyLine.push(editPolyline);
        }
      });
      setPolyLineGraphics(tempPolyLine);
    }

    //Polyline Measure Graphics Code
    if (polyLineGraphicsMeasure) {
      viewer.entities.remove(polyLineGraphicsMeasure);
    }
    if (measurePositions.length > 0) {
      const editPolyLineMeasure = viewer.entities.add({
        name: "PolyLineMeasure",
        polyline: {
          positions: measurePositions,
          width: 2,
          material: Color.BLACK,
          clampToGround: true,
        },
      });
      viewer.entities.remove(editPolyLineMeasure);
      viewer.entities.add(editPolyLineMeasure);
      setPolyLineGraphicsMeasure(editPolyLineMeasure);
    }

    //Point Graphics Code

    if (pointGraphics.length > 0) {
      pointGraphics.forEach((k) => {
        viewer.entities.remove(k);
      });
    }
    if (measurePoints.length > 0) {
      let arrayPoint = [];
      measurePoints.forEach((item) => {
        const editPoints = viewer.entities.add({
          // key: `MeasurePoints#${i}`,
          position: Cartesian3.fromDegrees(item.x, item.y),
          point: {
            pixelSize: 6,
            color: Color.WHITE,
            heightReference: HeightReference.CLAMP_TO_GROUND,
          },
        });
        viewer.entities.remove(editPoints);
        viewer.entities.add(editPoints);
        arrayPoint.push(editPoints);
      });
      setPointGraphics(arrayPoint);
    }
  }, [
    positions,
    altMode,
    polygonBackground.setColor,
    lineWidth,
    lineColor.setColor,
    measurePoints,
  ]);

  useEffect(() => {
    // console.log("infoLayerSelected", infoLayerSelected);
    infoLayerSelectedVar = infoLayerSelected;
    if (infoLayerSelected === "Point Cloud") {
      // console.log("selectedPrimitive", selectedPrimitive);
      // const viewer = mapRef.current.cesiumElement;
      // console.log("primitives", viewer.scene._primitives._primitives);
      // const primitivesArray = viewer.scene._primitives._primitives;
      let tilesetSelected = null;
      // primitivesArray.forEach((k, i) => {
      //   if (k instanceof Cesium3DTileset) {
      //     console.log("k._url", k._url);
      //     const ID =
      //       k._url
      //         .split(".")
      //         [k._url.split(".").length - 2].split("/tileset")[0] + getUserId();

      //     console.log("ID", ID);
      //     if (ID === infoLayerSelectedId) {
      //       console.log("matched id");
      //       tilesetSelected = k;
      //     }
      //   }
      // });
      tilesetSelected = selectedPrimitive;
      // console.log("tilesetSelecteduseEffect", tilesetSelected);
      if (tilesetSelected) {
        // if (tilesetSelected._tilesLoaded) {
        // console.log(
        //   "tilesetSelected",
        //   tilesetSelected,
        //   "styleMode",
        //   styleMode
        // );
        if (tilesetSelected._asset.hasOwnProperty("classes")) {
          if (tilesetSelected._asset.classes.length > 2) {
            setChkPointCloudClassStyle(false);
          } else {
            setChkPointCloudClassStyle(true);
          }
        } else {
          setChkPointCloudClassStyle(true);
        }

        const style = styleModeHandler(
          styleMode,
          styleClass,
          pointCloudSize,
          heightSliderVal,
          minzMaxz,
          selectedColorPallet
        );

        tilesetSelected.style = new Cesium3DTileStyle(style);

        //Add updated tileset heights value from height slider
        if (tileSetHeight != null) {
          tilesetModelmatrixCalculation(
            tilesetSelected,
            tileSetHeightDefault,
            tileSetHeight
          );
        }
      }
    }
  }, [
    pointCloudSize,
    styleMode,
    styleClass,
    infoLayerSelectedId,
    selectedColorPallet,
    heightSliderVal,
    tileSetHeight,
    tileSetHeightDefault,
    infoLayerSelected,
  ]);

  useEffect(() => {
    if (viewer) {
      mapEventsHandler();
    }

    measureSelected2 = measureSelected;
    editSelected2 = editSelected;
    if (!editCheck) {
      setInfoLayerLinks([]);
    } else {
      editCheck = false;
    }
  }, [editSelected, measureSelected, viewer]);
  useEffect(() => {
    if (layerPanelSelected) {
      loadedEntitties.forEach((ent) => {
        if (ent.entity instanceof Model) {
          if (ent.id == layerPanelSelected) {
            ent.entity.color = Color.ORANGERED;
          } else {
            //console.log("entityChk", ent);
            ent.entity.color =
              ent.entity.id.description.imageTaggings.length > 0
                ? Color.ORANGE
                : Color.LIMEGREEN;
          }
        }
      });
      let selected = appState.layerArray.filter(
        (layer) => layer.id == layerPanelSelected
      );

      setDateInfoPanel({
        dateCreated: selected[0]?.createdAt,
        dateUpdated: selected[0]?.updatedAt,
        dateCollected:
          selected[0]?.dateCollected != null ? selected[0]?.dateCollected : "",
      });
    }
  }, [layerPanelSelected, appState.layerArray]);

  useEffect(() => {
    if (viewer) {
      // console.log("appState.layerArray", appState.layerArray);
      let interval,
        intervalVector,
        sessionInterval,
        awsCredsInterval,
        pendingShareInterval;
      if (
        JSON.parse(secureLocalStorage.getItem("showTips")) &&
        secureLocalStorage.getItem("user") !== "share"
      ) {
        let abc = JSON.parse(secureLocalStorage.getItem("userTips"));
        abc.length && setTipsDialog(true);
      }
      if (secureLocalStorage.getItem("user") == "share") {
        enableEula().then(() => {
          setEulaBanner(true);
          secureLocalStorage.setItem("eula", JSON.stringify(true));
          setTimeout(() => {
            setEulaBanner(false);
          }, 40000);
        });
      }
      if (
        !JSON.parse(secureLocalStorage.getItem("eula")) &&
        secureLocalStorage.getItem("user") !== "share"
      ) {
        setEulaDialog(true);
      }
      if (appState.layerArray.length > 0) {
        tilesetLayerId = [];
        const tilesetLayerObjs = [];
        const frustumLayerObjs = [];
        const frustumLayerId = [];
        // *** here you get all layer of projects
        appState.layerArray.map(async (layer, layerIndex) => {
          // if (layer.layerType == 6) {
          //   tilesetLayerId.push(layer.id);
          // }
          // if (layer.layerType == 5) {
          //   frustumLayerId.push(layer.id)
          // }
          layer.layerType == 6 && tilesetLayerId.push(layer.id);
          layer.layerType == 5 && frustumLayerId.push(layer.id);
          if (!loadedFiles.includes(layer.id)) {
            if (layer.layerType == 2) {
              if (layer.folderUrl) {
                let oldImagery = uploadFilesImagery;
                oldImagery.push({
                  type: 2,
                  id: layer.id,
                  url: layer.folderUrl,
                  projectId: layer.projectId,
                  folderId: layer.folderId,
                  show: layer.displayStatus ? true : false,
                });
                setUploadFilesImagery(oldImagery);
                loadedFiles.push(layer.id);
                pendingImagery = pendingImagery.filter(
                  (item) => item != layer.id
                );
              } else {
                if (!pendingImagery.includes(layer.id)) {
                  pendingImagery.push(layer.id);
                }
              }
            } else if (layer.layerType == 3) {
              if (layer.folderUrl) {
                let oldImagery = uploadFilesImagery;
                oldImagery.push({
                  type: 3,
                  id: layer.id,
                  url: layer.folderUrl.split(":")[
                    layer.folderUrl.split(":").length - 1
                  ],
                  show: layer.displayStatus ? true : false,
                });
                loadedFiles.push(layer.id);
                pendingImagery = pendingImagery.filter(
                  (item) => item != layer.id
                );
                setUploadFilesImagery(oldImagery);
              } else {
                if (!pendingImagery.includes(layer.id)) {
                  pendingImagery.push(layer.id);
                }
              }
            } else if (layer.layerType == 7) {
              let oldImagery = uploadFilesImagery;
              oldImagery.push({
                type: 7,
                id: layer?.id,
                projectId: layer?.projectId,
                folderId: layer?.folderId,
                url: layer?.data,
                show: layer.displayStatus ? true : false,
                name: layer?.name,
              });
              loadedFiles.push(layer.id);
              pendingImagery = pendingImagery.filter(
                (item) => item != layer.id
              );
              setUploadFilesImagery(oldImagery);
            } else if (layer.layerType == 4) {
              // if (layer.folderUrl && layer.status?.status !== "Processing") {
              //   let oldImagery = uploadFilesImagery;
              //   oldImagery.push({
              //     type: 6,
              //     id: layer.id,
              //     projectId: layer.projectId,
              //     folderId: layer.folderId,
              //     url: layer.folderUrl,
              //     show: layer.displayStatus ? true : false,
              //   });
              //   loadedFiles.push(layer.id);
              //   pendingImagery = pendingImagery.filter(
              //     (item) => item != layer.id
              //   );
              //   setUploadFilesImagery(oldImagery);
              // } else
              if (layer.signedUrl && layer.status?.status !== "Processing") {
                // console.log("altitudeModeTestKml", layer);
                let oldImagery = uploadFilesImagery;
                oldImagery.push({
                  type: 6,
                  id: layer.id,
                  size: layer.size,
                  projectId: layer.projectId,
                  folderId: layer.folderId,
                  url: !layer.folderUrl ? layer.signedUrl : layer.folderUrl,
                  show: layer.displayStatus ? true : false,
                  altitudeMode:
                    layer.feature_attributes[0]?.altitudeMode != null
                      ? layer.feature_attributes[0]?.altitudeMode
                      : "absolute",
                });
                setUploadFilesImagery(oldImagery);
                // if (
                //   !pendingImagery.includes(layer.id) &&
                //   layer.data.includes("userGenerated")
                // ) {
                //   pendingImagery.push(layer.id);
                // } else {
                loadedFiles.push(layer.id);
                pendingImagery = pendingImagery.filter(
                  (item) => item != layer.id
                );
                // }
              } else {
                if (!pendingImagery.includes(layer.id)) {
                  pendingImagery.push(layer.id);
                }
              }
            } else if (layer.layerType == 1) {
              if (layer.folderUrl) {
                let oldImagery = uploadFilesImagery;
                oldImagery.push({
                  type: 3,
                  id: layer.id,
                  url: layer.folderUrl.split(":")[
                    layer.folderUrl.split(":").length - 1
                  ],
                  show: layer.displayStatus ? true : false,
                });
                loadedFiles.push(layer.id);
                pendingImagery = pendingImagery.filter(
                  (item) => item != layer.id
                );
                setUploadFilesImagery(oldImagery);
              } else {
                if (!pendingVectors.includes(layer.id)) {
                  pendingVectors.push(layer.id);
                }
              }
            } else if (layer.layerType == 5) {
              if (layer.exifData) {
                // console.log("layerPanos1", layer);
                loadedFiles.push(layer.id);
                pendingImagery = pendingImagery.filter(
                  (item) => item != layer.id
                );
                loadFrustum(layer);
              } else {
                if (!pendingImagery.includes(layer.id)) {
                  pendingImagery.push(layer.id);
                }
              }
            } else if (layer.layerType == 6 && layer.displayStatus) {
              tilesetAddFns(layer);
            }
          }
          if (layerIndex + 1 == appState.layerArray.length) {
            //const viewer = mapRef.current.cesiumElement;
            // viewer.scene.globe.tileCacheSize = 1000; // default 100
            // viewer.scene.globe.maximumScreenSpaceError = 5;

            viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
            viewer.scene.globe.translucency.frontFaceAlphaByDistance = new NearFarScalar(
              400.0,
              0.0,
              800.0,
              1.0
            );
            let alpha = CesMath.clamp(0, 0.0, 1.0);

            viewer.scene.globe.translucency.frontFaceAlphaByDistance.nearValue = alpha;
            viewer.scene.globe.translucency.frontFaceAlphaByDistance.farValue = true
              ? 1.0
              : alpha;
            clearInterval(interval);
            clearInterval(sessionInterval);
            if (Number(secureLocalStorage.getItem("isAdmin")) !== 1) {
              clearInterval(awsCredsInterval);
            }
            clearInterval(intervalVector);
            clearInterval(pendingShareInterval);
            //Interval Functions Here
            if (secureLocalStorage.getItem("user") == "share") {
              pendingShareInterval = setInterval(async () => {
                if (pendingShareDownloads.length) {
                  pendingShareDownloads.forEach((itemShare) => {
                    downloadFolder(itemShare).then((res) => {
                      if (res.data.signedUrl) {
                        pendingShareDownloads = pendingShareDownloads.filter(
                          (b) => b !== itemShare
                        );
                        setPendingDownCount(pendingShareDownloads.length);
                        let a = document.createElement("a");
                        a.href = res.data.signedUrl;
                        a.click();
                      }
                    });
                  });
                }
              }, 10000);
            }
            if (secureLocalStorage.getItem("user") !== "share") {
              interval = setInterval(async () => {
                // console.log("activeInterval");
                if (
                  pendingImagery.length &&
                  !secureLocalStorage.getItem("uploading")
                ) {
                  getBulkLayers({ layerIDs: pendingImagery })
                    .then((res) => {
                      console.log("IntervalFunctionsHere", res);
                      res.data.layerList.forEach((layer1, index) => {
                        let layer = layer1;
                        if (!loadedFiles.includes(layer.id)) {
                          if (layer.folderUrl) {
                            let currentFolderId = layer.folderId;
                            let currentProjectrId = layer.projectId;

                            if (layer.layerType == 2) {
                              if (layer.folderUrl) {
                                let oldImagery = uploadFilesImagery;
                                oldImagery.push({
                                  type: 2,
                                  id: layer.id,
                                  projectId: layer.projectId,
                                  folderId: layer.folderId,
                                  url: layer.folderUrl,
                                  show: layer.displayStatus ? true : false,
                                });
                                setUploadFilesImagery(oldImagery);
                                reloadCheck = true;
                                // setAppState({
                                //   ...appState,
                                //   projectId: layer.projectId,
                                //   folderId: layer.folderId,
                                //   reloadProjects: true,
                                // });
                                loadedFiles.push(layer.id);
                                pendingImagery = pendingImagery.filter(
                                  (item) => item != layer.id
                                );
                              }
                            } else if (
                              layer.layerType == 3 ||
                              layer.layerType == 1
                            ) {
                              if (layer.folderUrl) {
                                let oldImagery = uploadFilesImagery;
                                oldImagery.push({
                                  type: 3,
                                  id: layer.id,
                                  url: layer.folderUrl.split(":")[
                                    layer.folderUrl.split(":").length - 1
                                  ],
                                  show: layer.displayStatus ? true : false,
                                });
                                setUploadFilesImagery(oldImagery);
                                reloadCheck = true;
                                loadedFiles.push(layer.id);
                                // setAppState({
                                //   ...appState,
                                //   projectId: layer.projectId,
                                //   folderId: layer.folderId,
                                //   reloadProjects: true,
                                // });
                              }
                            }
                            pendingImagery = pendingImagery.filter(
                              (item) => item != layer.id
                            );
                          }
                          if (layer.signedUrl) {
                            let currentFolderId = layer.folderId;
                            let currentProjectrId = layer.projectId;
                            if (layer.layerType == 4) {
                              if (
                                layer.folderUrl &&
                                layer.status?.status !== "Processing"
                              ) {
                                let oldImagery = uploadFilesImagery;
                                if (layer.data.includes("userGenerated")) {
                                  oldImagery = oldImagery.filter(
                                    (itemVec) => itemVec.id != layer.id
                                  );
                                }
                                oldImagery.push({
                                  type: 6,
                                  id: layer.id,
                                  url: layer.folderUrl,
                                  projectId: layer.projectId,
                                  folderId: layer.folderId,
                                  show: layer.displayStatus ? true : false,
                                });
                                loadedFiles.push(layer.id);
                                setUploadFilesImagery(oldImagery);
                                reloadCheck = true;
                                // setAppState({
                                //   ...appState,
                                //   projectId: layer.projectId,
                                //   folderId: layer.folderId,
                                //   reloadProjects: true,
                                // });
                                pendingImagery = pendingImagery.filter(
                                  (item) => item != layer.id
                                );
                              }
                            }
                          }
                          if (layer.layerType == 6 && layer.displayStatus) {
                            if (layer.folderUrl) {
                              pointCloudTileSetHandler(
                                layer.folderUrl + "tileset.json"
                              )
                                .then((tileset) => {
                                  if (tileset) {
                                    tileset.show = layer.displayStatus
                                      ? true
                                      : false;
                                    //Define AltitudeMode and Tileset Height inside _asset
                                    //Add Point Icon of 3dTileset Here 2
                                    let cartographic = Cartographic.fromCartesian(
                                      tileset.boundingSphere.center
                                    );
                                    var longitudeString = CesMath.toDegrees(
                                      cartographic.longitude
                                    ).toFixed(6);
                                    var latitudeString = CesMath.toDegrees(
                                      cartographic.latitude
                                    ).toFixed(6);
                                    var heightString = cartographic.height;
                                    const long = parseFloat(longitudeString);
                                    const lat = parseFloat(latitudeString);
                                    const height = parseFloat(heightString);
                                    console.log(
                                      "TilesetPoints",
                                      long,
                                      lat,
                                      height
                                    );

                                    const billBoardPoint3dTileSet = viewer.entities.add(
                                      {
                                        position: Cartesian3.fromDegrees(
                                          long,
                                          lat
                                        ),
                                        customPropBillboard: { layer: layer },
                                        billboard: {
                                          image: pinkMarker,
                                          show: layer.displayStatus
                                            ? true
                                            : false,
                                          heightReference:
                                            HeightReference.CLAMP_TO_GROUND,
                                          disableDepthTestDistance: 35000000,
                                          scale: 0.5,
                                          // customProp: { layer: layer },
                                        },
                                      }
                                    );
                                    //billBoardPoint3dTileSet.billboard.show = false;
                                    mapFeatures.push({
                                      id: layer.id,
                                      type: "6a",
                                      entity: billBoardPoint3dTileSet,
                                      intersection: false,
                                      show: layer.displayStatus ? true : false,
                                    });
                                    //viewer.scene.primitives.add(tileset);

                                    const tempProjects = appState;
                                    console.log(
                                      "tempProjects",
                                      tempProjects.projects,
                                      "layerInInterval",
                                      layer
                                    );
                                    tempProjects.projects.forEach((project) => {
                                      if (project.id === layer.projectId) {
                                        updateItemInProjectTileSet(
                                          project.children,
                                          layer.folderId,
                                          layer.id,
                                          tileset
                                        );
                                        loadedEntitties.push({
                                          id: layer.id,
                                          entity: tileset,
                                        });
                                        mapFeatures.push({
                                          id: layer.id,
                                          type: 6,
                                          entity: tileset,
                                          intersection: false,
                                          show: layer.displayStatus
                                            ? true
                                            : false,
                                        });
                                      }
                                    });
                                    console.log(
                                      "tempProjects1",
                                      tempProjects.projects
                                    );
                                    reloadCheck = true;
                                    setAppState({
                                      ...appState,
                                      projects: tempProjects.projects,
                                    });
                                    loadedFiles.push(layer.id);
                                    pendingImagery = pendingImagery.filter(
                                      (item) => item != layer.id
                                    );
                                    tileset._asset["layer"] = layer;
                                    initialPosition3dTileset(tileset);
                                  }
                                })
                                .catch((error) => {
                                  console.error("An error occurred:", error);
                                });
                            }
                          }
                          if (
                            layer.exifData &&
                            layer.status.status === "Complete"
                          ) {
                            console.log("layerPanos", layer);
                            // loadFrustum(layer);
                            // reloadCheck = true;

                            // loadedFiles.push(layer.id);
                            // pendingImagery = pendingImagery.filter(
                            //   (item) => item != layer.id
                            // );
                            loadFrustum(layer);
                            reloadCheck = true;
                            // setAppState({ ...appState, reloadProjects: true });
                            loadedFiles.push(layer.id);
                            pendingImagery = pendingImagery.filter(
                              (item) => item != layer.id
                            );
                          }
                        }
                        if (
                          layer?.status?.status.toLowerCase().includes("failed")
                        ) {
                          reloadCheck = true;
                          pendingImagery = pendingImagery.filter(
                            (item) => item != layer.id
                          );
                        }
                      });
                      if (reloadCheck) {
                        setReloadTrigger(!reloadTrigger);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }, 20000);
            }
            sessionInterval = setInterval(() => {
              getUserDetail()
                .then((res) => {
                  // console.log("getUserDetail", res);
                  // if (res.status == 401 && res.message === "Token expired") {
                  //   localStorage.clear();
                  //   window.location.reload();
                  // }
                })
                .catch((error) => {
                  const message =
                    error?.response?.data?.message ||
                    error?.message ||
                    error?.toString();

                  dispatch({
                    type: SET_MESSAGE,
                    payload: {
                      message: message,
                      pass: true,
                      severity: "error",
                      isOpen: true,
                    },
                  });
                  if (
                    message === "Token expired" ||
                    message?.toLowerCase().includes("valid session") ||
                    message?.toLowerCase().includes("expired") ||
                    message
                      ?.toLowerCase()
                      .includes("could not decode access token")
                  ) {
                    localStorage.clear();
                    window.location.reload();
                  }
                });
            }, 15000);
            intervalVector = setInterval(async () => {
              if (
                pendingVectors.length &&
                !secureLocalStorage.getItem("uploading")
              ) {
                pendingVectors.forEach(async (id) => {
                  await getSingleLayer(id)
                    .then((layer1) => {
                      let layer = layer1.data.layer;
                      if (layer.folderUrl) {
                        let currentFolderId = layer.folderId;
                        let currentProjectrId = layer.projectId;

                        if (layer.layerType == 2) {
                          if (layer.folderUrl) {
                            let oldImagery = uploadFilesImagery;
                            oldImagery.push({
                              type: 2,
                              id: layer.id,
                              projectId: layer.projectId,
                              folderId: layer.folderId,
                              url: layer.folderUrl,
                              show: layer.displayStatus ? true : false,
                            });
                            setUploadFilesImagery(oldImagery);
                            setAppState({ ...appState, reloadProjects: true });
                          }
                        } else if (
                          layer.layerType == 3 ||
                          layer.layerType == 1
                        ) {
                          if (layer.folderUrl) {
                            let oldImagery = uploadFilesImagery;
                            oldImagery.push({
                              type: 3,
                              id: layer.id,
                              url: layer.folderUrl.split(":")[
                                layer.folderUrl.split(":").length - 1
                              ],
                              show: layer.displayStatus ? true : false,
                            });
                            setUploadFilesImagery(oldImagery);
                            setAppState({ ...appState, reloadProjects: true });
                          }
                        }
                        pendingVectors = pendingVectors.filter(
                          (item) => item != id
                        );
                      }
                      if (layer.layerType == 4) {
                        if (
                          layer.signedUrl &&
                          layer.status?.status !== "Processing"
                        ) {
                          let oldImagery = uploadFilesImagery;
                          oldImagery.push({
                            type: 6,
                            id: layer.id,
                            url: layer.signedUrl,
                            projectId: layer.projectId,
                            folderId: layer.folderId,
                            show: layer.displayStatus ? true : false,
                          });
                          setUploadFilesImagery(oldImagery);
                          setAppState({ ...appState, reloadProjects: true });
                          pendingVectors = pendingVectors.filter(
                            (item) => item != id
                          );
                        } else if (
                          layer.folderUrl &&
                          layer.status?.status !== "Processing"
                        ) {
                          let oldImagery = uploadFilesImagery;
                          oldImagery.push({
                            type: 6,
                            id: layer.id,
                            url: layer.folderUrl,
                            projectId: layer.projectId,
                            folderId: layer.folderId,
                            show: layer.displayStatus ? true : false,
                          });
                          setUploadFilesImagery(oldImagery);
                          setAppState({ ...appState, reloadProjects: true });
                          pendingVectors = pendingVectors.filter(
                            (item) => item != id
                          );
                        }
                      }
                      if (layer.layerType == 6 && layer.displayStatus) {
                        console.log("3dtilesetLayer3", layer);
                        if (layer.folderUrl) {
                          //const viewer = mapRef.current.cesiumElement;
                          pointCloudTileSetHandler(
                            layer.folderUrl + "tileset.json"
                          )
                            .then((tileset) => {
                              // Now you can use the tilesetObj for further operations
                              // console.log("tilesetTest", tileset);
                              if (tileset) {
                                tileset.show = layer.displayStatus
                                  ? true
                                  : false;
                                //Add Point Icon of 3dTileset Here 3
                                let cartographic = Cartographic.fromCartesian(
                                  tileset.boundingSphere.center
                                );
                                var longitudeString = CesMath.toDegrees(
                                  cartographic.longitude
                                ).toFixed(6);
                                var latitudeString = CesMath.toDegrees(
                                  cartographic.latitude
                                ).toFixed(6);
                                var heightString = cartographic.height;
                                const long = parseFloat(longitudeString);
                                const lat = parseFloat(latitudeString);
                                const height = parseFloat(heightString);
                                console.log("TilesetPoints", long, lat, height);

                                const billBoardPoint3dTileSet = viewer.entities.add(
                                  {
                                    position: Cartesian3.fromDegrees(long, lat),
                                    customPropBillboard: { layer: layer },
                                    billboard: {
                                      image: pinkMarker,
                                      show: layer.displayStatus ? true : false,
                                      heightReference:
                                        HeightReference.CLAMP_TO_GROUND,
                                      disableDepthTestDistance: 35000000,
                                      scale: 0.5,
                                      // customProp: { layer: layer },
                                    },
                                  }
                                );
                                //billBoardPoint3dTileSet.billboard.show = false;
                                mapFeatures.push({
                                  id: layer.id,
                                  type: "6a",
                                  entity: billBoardPoint3dTileSet,
                                  intersection: false,
                                  show: layer.displayStatus ? true : false,
                                });
                                //viewer.scene.primitives.add(tileset);

                                appState.projects.forEach((project) => {
                                  if (project.id === layer.projectId) {
                                    updateItemInProject(
                                      project.children,
                                      layer.folderId,
                                      layer.id,
                                      tileset
                                    );
                                    loadedEntitties.push({
                                      id: layer.id,
                                      entity: tileset,
                                    });
                                    mapFeatures.push({
                                      id: layer.id,
                                      type: 6,
                                      entity: tileset,
                                      intersection: false,
                                      show: layer.displayStatus ? true : false,
                                    });
                                  }
                                });
                                setAppState({
                                  ...appState,
                                  reloadProjects: true,
                                });
                                pendingImagery = pendingImagery.filter(
                                  (item) => item != id
                                );
                                tileset._asset["layer"] = layer;
                                initialPosition3dTileset(tileset);
                              }
                            })
                            .catch((error) => {
                              console.error("An error occurred:", error);
                            });
                        }
                      }
                      if (layer.exifData) {
                        loadFrustum(layer);
                        setAppState({ ...appState, reloadProjects: true });
                        pendingVectors = pendingVectors.filter(
                          (item) => item != id
                        );
                      }
                      if (
                        layer?.status?.status.toLowerCase().includes("failed")
                      ) {
                        pendingVectors = pendingVectors.filter(
                          (item) => item != id
                        );
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                });
              }
            }, 1000);
            if (Number(secureLocalStorage.getItem("isAdmin")) !== 1) {
              awsCredsInterval = setInterval(() => {
                if (!secureLocalStorage.getItem("uploading"))
                  axios
                    .get(API_URL + "users/aws-credentials", {
                      headers: { Authorization: `Bearer ${authHeader()}` },
                    })
                    .then((response1) => {
                      secureLocalStorage.setItem(
                        "SessionToken",
                        response1.data.SessionToken
                      );
                      secureLocalStorage.setItem(
                        "ACCESS_ID",
                        response1.data.AccessKeyId
                      );
                      secureLocalStorage.setItem(
                        "SECRET_KEY",
                        response1.data.SecretAccessKey
                      );
                    });
              }, 7200000);
            }
            if (secureLocalStorage.getItem("shareZoom")) {
              let zoomInterval = setInterval(() => {
                if (viewer.scene.globe.tilesLoaded) {
                  appState.layerArray.forEach((n, m) => {
                    if (
                      Number(secureLocalStorage.getItem("shareZoom")) === n.id
                    ) {
                      const show = n.displayStatus ? true : false;
                      if (n.entity) {
                        if (n.entity instanceof Cesium3DTileset) {
                          dispatch({ type: LOADING_MESSAGE, isLoading: true });
                        }

                        if (
                          n.entity instanceof Cesium3DTileset &&
                          n.entity?._asset &&
                          shareTileSetZoom
                        ) {
                          fileAccordianOnDoubleClick(null, n, false);
                          dispatch({
                            type: LOADING_MESSAGE,
                            isLoading: false,
                          });
                          // }, 3000);
                          clearInterval(zoomInterval);
                        } else if (n.entity instanceof Model) {
                          let cartographic = Cartographic.fromCartesian(
                            n.entity.boundingSphere.center
                          );
                          var longitudeString = CesMath.toDegrees(
                            cartographic.longitude
                          ).toFixed(6);
                          var latitudeString = CeMath.toDegrees(
                            cartographic.latitude
                          ).toFixed(6);
                          var heightString = cartographic.height;
                          const long = parseFloat(longitudeString);
                          const lat = parseFloat(latitudeString);
                          const height = parseFloat(heightString);
                          viewer.camera.flyToBoundingSphere(
                            new BoundingSphere(
                              Cartesian3.fromDegrees(long, lat, height),
                              200
                            ),
                            { duration: 1 }
                          );
                          clearInterval(zoomInterval);
                          //viewer.flyTo(item.entity.model.boundingSphere());
                        } else {
                          if (n.entity instanceof Cesium3DTileset) return;
                          flyToCordVectors(n);
                          clearInterval(zoomInterval);
                        }
                      } else if (
                        !n.hasOwnProperty("entity") &&
                        n.layerType == 6
                      ) {
                        addOrVisibility(n.id, show, n);
                      } else if (n.layerType == 2) {
                        addOrVisibility(n.id, show, n, true);
                        clearInterval(zoomInterval);
                      } else {
                        const cords = n.latLong ? n.latLong?.coordinates : null;
                        if (cords) {
                          cords && cords.minx && flyToRectangle(cords);
                          cords && flyToCords(cords[1], cords[0]);
                          clearInterval(zoomInterval);
                        } else {
                        }
                      }
                    }
                  });
                }
              }, 2000);
            }
            dispatch({ type: LOADING_MESSAGE, isLoading: false });
          }
        });

        const primitives = viewer.scene.primitives._primitives;
        //Code to Delete 3Dtileset layers from map
        if (
          appState.layerArray.length > 0 &&
          viewer.scene.primitives._primitives.length > 0
        ) {
          //Code to push 3dtileset Entities
          primitives.forEach((entity) => {
            if (
              entity instanceof Cesium3DTileset &&
              entity.hasOwnProperty("_asset")
            ) {
              tilesetLayerObjs.push(entity);
            }
          });
          const filteredList = filterTilesetObjectsNotInIdList(
            tilesetLayerObjs,
            tilesetLayerId
          );

          // console.log(
          //   "tilesetLayerId",
          //   tilesetLayerObjs,
          //   appState.layerArray,
          //   tilesetLayerId,
          //   viewer.scene.primitives,
          //   filteredList
          // );

          filteredList.forEach((k) => {
            if (k?._asset?.name != "CesiumOsmBuildings") {
              viewer.scene.primitives.remove(k);
            }

            // mapFeatures = mapFeatures.filter(function(obj) {
            //   return obj.id !== k?._asset.layerid;
            // });
            // mapFeatures.forEach((m) => {
            //   if (m.id === k?._asset.layerid && m.type === "6a") {
            //     viewer.entities.remove(k.entity);
            //   }
            // });
            updateMapFeatures(k?._asset.layerid);
          });
        }
        //Code to Delete Frustum layers from map
        console.log("mHere");
        console.log(" viewer.entities", viewer.entities);

        if (
          appState.layerArray.length > 0 &&
          viewer.scene.primitives._primitives.length > 0
        ) {
          //Remove Entities
          primitives.forEach((entity) => {
            if (
              entity instanceof Model &&
              entity?.id?.description.hasOwnProperty("exifData")
            ) {
              frustumLayerObjs.push(entity);
            }
          });

          const filteredFrustumList = filterFrustumObjectsNotInIdList(
            frustumLayerObjs,
            frustumLayerId
          );

          filteredFrustumList.forEach((k) => {
            viewer.scene.primitives.remove(k);
            // mapFeatures = mapFeatures.filter(function(obj) {
            //   return obj.id !== k?.id?.description.layerid;
            // });
            updateMapFeatures(k?.id?.description.layerid);
          });

          console.log(
            "frustumLayerId",
            frustumLayerId,
            appState.layerArray,
            viewer.entities,
            frustumLayerObjs,
            filteredFrustumList
          );
        }
      } else {
        // const viewer = mapRef.current.cesiumElement;

        // viewer.scene.globe.tileCacheSize = 1000; // default 100
        // viewer.scene.globe.maximumScreenSpaceError = 5;
        // var options = {};

        // options.defaultResetView = Rectangle.fromDegrees(71, 3, 90, 14);
        // Only the compass will show on the map
        // options.enableCompass = true;
        // options.enableZoomControls = true;
        // options.enableDistanceLegend = false;
        // options.containerWidth = 125;
        // options.units = "kilometers"; // default is kilometers;
        // turf helpers units https://github.com/Turfjs/turf/blob/v5.1.6/packages/turf-helpers/index.d.ts#L20
        // options.distanceLabelFormatter = (convertedDistance, units : Units): string => { ... } // custom label formatter
        //viewer.extend(viewerCesiumNavigationMixin, options);
        viewer.scene.screenSpaceCameraController.enableCollisionDetection = false;
        viewer.scene.globe.translucency.frontFaceAlphaByDistance = new NearFarScalar(
          400.0,
          0.0,
          800.0,
          1.0
        );
        let alpha = CesMath.clamp(0, 0.0, 1.0);

        viewer.scene.globe.translucency.frontFaceAlphaByDistance.nearValue = alpha;
        viewer.scene.globe.translucency.frontFaceAlphaByDistance.farValue = true
          ? 1.0
          : alpha;
      }
      allLayers = appState.layerArray;
      return () => {
        clearInterval(interval);
        clearInterval(intervalVector);
        clearInterval(pendingShareInterval);
        clearInterval(sessionInterval);
        if (Number(secureLocalStorage.getItem("isAdmin")) !== 1) {
          clearInterval(awsCredsInterval);
        }
      };
    }
  }, [appState.layerArray, viewer]);
  useEffect(() => {
    cloneprojid = appState.projectId;
    clonefolderid = appState.folderId;
  }, [appState.projectId, appState.folderId]);
  useEffect(() => {
    if (rightDrawer == 350 && window.matchMedia("(max-width: 767px)").matches) {
      setRightDrawer(300);
    }
  }, [rightDrawer]);
  useEffect(() => {
    if (appState.projects.length) {
      appState.layerArray.forEach((arrLayer) => {
        let checkEnt = loadedEntitties.filter((ent) => ent.id == arrLayer.id);
        if (
          checkEnt.length &&
          appState.projects.filter(
            (filItem) => filItem.id == arrLayer.projectId
          )[0]
        ) {
          updateItemInProject(
            appState.projects.filter(
              (filItem) => filItem.id == arrLayer.projectId
            )[0].children,
            arrLayer?.folderId,
            arrLayer.id,
            checkEnt[0].entity
          );
        }
      });
    }
  }, [appState.projects]);
  useEffect(() => {
    if (appState.reloadProjects) {
      getProjectsDetails()
        .then((res, error) => {
          console.log("appStatereloadProjects", setAppState);
          if (error) {
            errorDisplay(
              "error",
              error.response ? error.response.data : "Projects fetch error"
            );
            return;
          }
          const projects = res.data.projects;
          let layerArray = [];
          projects.forEach((project) => {
            getNestedLayer(project.children, layerArray, project.id);
          });
          if (
            nestedLayerArray.length === 0 ||
            nestedLayerArray.length !== layerArray.length
          ) {
            nestedLayerArray = [...layerArray];
            setAppState({
              ...appState,
              projects: projects,
              layerArray: nestedLayerArray,
              reloadProjects: false,
              projectsFilter: searchVal
                ? filter(
                    projects,
                    ({ name, feature_attributes, entity }) =>
                      name.toLowerCase().includes(searchVal.toLowerCase()) ||
                      searchVal.toLowerCase().includes(name.toLowerCase()) ||
                      (feature_attributes &&
                        feature_attributes.length &&
                        feature_attributes.filter(
                          (itemAttr) =>
                            itemAttr.tags &&
                            itemAttr.tags.filter(
                              (itemTag) =>
                                typeof itemTag === "string" &&
                                itemTag !== "" &&
                                (itemTag
                                  ?.toLowerCase()
                                  .includes(searchVal?.toLowerCase()) ||
                                  searchVal
                                    ?.toLowerCase()
                                    .includes(itemTag?.toLowerCase()))
                            ).length
                        ).length) ||
                      ((name.toLowerCase().includes(".kml") ||
                        name.toLowerCase().includes(".kmz")) &&
                        entity?._entityCollection?._entities?._array.filter(
                          (kmlItem) =>
                            kmlItem?._name
                              ?.toLowerCase()
                              .includes(searchVal?.toLowerCase()) ||
                            searchVal
                              ?.toLowerCase()
                              .includes(kmlItem?._name?.toLowerCase())
                        ).length)
                  )
                : null,
            });
          } else {
            setAppState({
              ...appState,
              projects: projects,
              reloadProjects: false,
              projectsFilter: searchVal
                ? filter(
                    projects,
                    ({ name, feature_attributes, entity }) =>
                      name.toLowerCase().includes(searchVal.toLowerCase()) ||
                      searchVal.toLowerCase().includes(name.toLowerCase()) ||
                      (feature_attributes &&
                        feature_attributes.length &&
                        feature_attributes.filter(
                          (itemAttr) =>
                            itemAttr.tags &&
                            itemAttr.tags.filter(
                              (itemTag) =>
                                typeof itemTag === "string" &&
                                itemTag !== "" &&
                                (itemTag
                                  ?.toLowerCase()
                                  .includes(searchVal?.toLowerCase()) ||
                                  searchVal
                                    ?.toLowerCase()
                                    .includes(itemTag?.toLowerCase()))
                            ).length
                        ).length) ||
                      ((name.toLowerCase().includes(".kml") ||
                        name.toLowerCase().includes(".kmz")) &&
                        entity?._entityCollection?._entities?._array.filter(
                          (kmlItem) =>
                            kmlItem?._name
                              ?.toLowerCase()
                              .includes(searchVal?.toLowerCase()) ||
                            searchVal
                              ?.toLowerCase()
                              .includes(kmlItem?._name?.toLowerCase())
                        ).length)
                  )
                : null,
            });
          }
          dispatch({ type: LOADING_MESSAGE, isLoading: false });
        })
        .catch((error) => {
          errorDisplay(
            "error",
            error.response
              ? error.response.data?.message
              : "Projects fetch error"
          );
        });
    }

    return () => {};
  }, [appState.reloadProjects]);

  const filterObjectsNotInIdList = (objectList, idList) => {
    return objectList.filter((obj) => !idList.includes(obj?.itemId));
  };

  const filterTilesetObjectsNotInIdList = (objectList, idList) => {
    return objectList.filter((obj) => !idList.includes(obj?._asset.layerid));
  };

  const filterFrustumObjectsNotInIdList = (objectList, idList) => {
    return objectList.filter(
      (obj) => !idList.includes(obj?.id?.description.layerid)
    );
  };

  const filterObjectsInIdList = (objectList, idList) => {
    return objectList.filter((obj) => idList.includes(obj?.id));
  };
  const filterObjectsNotInIdList1 = (objectList, idList) => {
    return objectList.filter((obj) => !idList.includes(obj?.id));
  };
  const filterKmlObjectsInIdList = (objectList, idList) => {
    return objectList.filter((obj) => idList.includes(obj?.itemId));
  };

  const containsObject = (arr, obj) => {
    return arr.includes(obj);
  };

  useEffect(() => {
    if (viewer) {
      //const viewer = mapRef.current.cesiumElement;

      // Load multiple KML sources from state
      console.log("ViewerLoaded", uploadFilesImagery);
      const wmsLayerId = [];
      const kmlLayerId = [];
      const tileImgLayerId = [];
      const tileMapLayerObjs = [];
      uploadFilesImagery.length > 0 &&
        uploadFilesImagery.map((item, index) => {
          if (item?.type == 2) {
            // console.log("TileMapServiceImageryProviderHandler", item);
            tileImgLayerId.push(item.id);

            TileMapServiceImageryProviderHandler(item);
          } else if (item?.type == 6) {
            if (
              item.size > 100 &&
              window.matchMedia("(max-width: 767px)").matches
            ) {
              return;
            }
            kmlLayerId.push(item.id);
            kmlDataSourceProviderHandler(item);
          } else if (item?.type == 7) {
            //Code to remove Imagery Layers
            wmsLayerId.push(item.id);
            WebMapServiceImageryProviderHandler(item);
          }
        });

      // console.log("viewer.datasources", viewer.dataSources);
      //Code to remove deleted wms layers from viewer
      if (
        uploadFilesImagery.length > 0 &&
        viewer.imageryLayers._layers.length > 0
      ) {
        const filteredList = filterObjectsNotInIdList(
          viewer.imageryLayers._layers,
          wmsLayerId
        );

        viewer.imageryLayers._layers.forEach((k) => {
          if (k?.layerType === 2) {
            tileMapLayerObjs.push(k);
          }
        });
        const filteredTileImgList = filterObjectsNotInIdList(
          tileMapLayerObjs,
          tileImgLayerId
        );
        // console.log(
        //   "filteredList",
        //   filteredList,
        //   "wmsLayerId",
        //   wmsLayerId,
        //   "viewer.imageryLayers._layers",
        //   viewer.imageryLayers._layers
        // );
        console.log(
          "filteredTileImgList",
          tileMapLayerObjs,
          filteredTileImgList,
          "tileImgLayerId",
          tileImgLayerId,
          "viewer.imageryLayers._layers",
          viewer.imageryLayers._layers
        );
        filteredList.forEach((k) => {
          if (k.hasOwnProperty("layerType")) {
            if (k.layerType === 7) {
              viewer.imageryLayers.remove(k);
              // mapFeatures = mapFeatures.filter(function(obj) {
              //   return obj.id !== k?.itemId;
              // });
              updateMapFeatures(k?.itemId);
            }
          }
        });

        //Code to remove Tile image Layers from Viewer
        filteredTileImgList.forEach((k) => {
          if (k.hasOwnProperty("layerType")) {
            if (k.layerType === 2) {
              viewer.imageryLayers.remove(k);
              // mapFeatures = mapFeatures.filter(function(obj) {
              //   return obj.id !== k?.itemId;
              // });
              updateMapFeatures(k?.itemId);
            }
          }
        });
      }
      //Code to remove deleted kml layers from viewer
      if (
        uploadFilesImagery.length > 0 &&
        viewer.dataSources._dataSources.length > 0
      ) {
        const filteredKmlList = filterObjectsNotInIdList(
          viewer.dataSources._dataSources,
          kmlLayerId
        );
        filteredKmlList.forEach((k) => {
          viewer.dataSources.remove(k);
        });
      } else if (uploadFilesImagery.length === 0 && layerPanelSelected) {
        const filteredKmlList = filterKmlObjectsInIdList(
          viewer.dataSources._dataSources,
          [layerPanelSelected]
        );
        filteredKmlList.forEach((k) => {
          viewer.dataSources.remove(k);
        });
      }

      console.log("viewer.imageryLayers", viewer.imageryLayers);
    }
  }, [uploadFilesImagery.length, uploadFilesImagery]);
  const calculateLineLength = () => {
    const tempArray = measurePoints.map((point) => [point.x, point.y, point.z]);
    const turfLine = lineString(tempArray);
    console.log("turfLine", turfLine);
    const totalDistanceInMeters = length(turfLine, { units: "meters" });
    const totalDistanceInKm = totalDistanceInMeters * measureUnit.lengthCoe;
    setDistance(totalDistanceInKm);
  };
  useEffect(() => {
    if (viewer) {
      const globe = viewer.scene.globe;

      if (transparencyEnabled) {
        globe.translucency.enabled = true;
      } else {
        globe.translucency.enabled = false;
      }
    }
  }, [transparencyEnabled]);
  useEffect(() => {
    if (selectedItem) {
      setAppState({
        ...appState,
        projectId: selectedItem.projectId,
        folderId: selectedItem.folderId,
        fileId: selectedItem.fileId,
      });
      selectedItem = null;
    }
  }, [layerPanelSelected]);

  useEffect(() => {
    if (reloadCheck) {
      setAppState({
        ...appState,
        reloadProjects: true,
        // projectId: selectedItem.projectId,
        // folderId: selectedItem.folderId,
        // fileId: selectedItem.fileId,
      });
      reloadCheck = null;
    }
  }, [reloadTrigger]);

  useEffect(() => {
    if (measurePositions.length >= 2) {
      calculateLineLength();
    } else {
      setDistance(0);
    }
    if (measurePositions.length >= 3) {
      //setArea(SphericalPolygonAreaMeters(measurePoints) / measureUnit.areaCoe);
      calculateTurfAreaHandler();
    } else {
      setArea(0);
    }
  }, [measurePositions, measureUnit]);
  useEffect(() => {
    if (positions.length == 0 && viewer) {
      deleteDragPoints();
      // const viewer = mapRef.current.cesiumElement;
      let handler = new ScreenSpaceEventHandler(viewer.canvas);
      handler.removeInputAction(ScreenSpaceEventType.LEFT_UP);
    }
  }, [positions]);
  const deleteProjectFunction = (projectId) => {
    // dispatch({ type: LOADING_MESSAGE, isLoading: true });
    errorDisplay("success", "Item is being deleted");
    deleteProject(projectId)
      .then((res) => {
        if (res?.status === 200) {
          // dispatch({ type: LOADING_MESSAGE, isLoading: false });
          // *** verify it as i removed code
          updateLocalStorage(
            res?.data?.storageDetail?.storageCapacity,
            res?.data?.storageDetail?.usedStorage
          );
          setAppState({ ...appState, reloadProjects: true });
          errorDisplay("success", "Project deleted successfully");
        } else {
          errorDisplay("error", "Request Unsuccessful");
        }
      })
      .catch((error) => {
        errorDisplay(
          "error",
          error.response ? error.response.data : "Connection error"
        );
      });
  };
  const createLayerHandler = (vectorName) => {
    console.log("vectorName", vectorName);
    if (vectorName) {
      let newLayerNameFromRef = vectorName;
      let datatosend;
      new Promise((resolve, reject) => {
        if (layerTypeSelected == "point") {
          let pointFeature = [];
          points.map((item, i) => {
            pointFeature.push({
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [item.x, item.y, item.z],
              },
              properties: {
                name: vectorName,
                altitudeMode: "relativeToGround",
              },
            });
          });
          datatosend = {
            crs: {
              type: "name",
              properties: {
                name: "urn:ogc:def:crs:OGC:1.3:CRS84",
              },
            },
            type: "FeatureCollection",
            features: pointFeature,
          };
        } else if (layerTypeSelected == "line") {
          let pointFeature = [];
          points.map((item, i) => {
            pointFeature.push([item.x, item.y, item.z]);
          });
          datatosend = {
            crs: {
              type: "name",
              properties: {
                name: "urn:ogc:def:crs:OGC:1.3:CRS84",
              },
            },
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "LineString",
                  coordinates: pointFeature,
                },
                properties: {
                  stroke: lineColor.hex,
                  "stroke-width": lineWidth,
                  name: vectorName,
                  visibility: "1",
                },
              },
            ],
          };
        } else if (layerTypeSelected == "polygon") {
          let pointFeature = [];
          points.map((item, i) => {
            pointFeature.push([item.x, item.y, item.z]);
          });
          pointFeature.push(pointFeature[0]);
          datatosend = {
            crs: {
              type: "name",
              properties: {
                name: "urn:ogc:def:crs:OGC:1.3:CRS84",
              },
            },
            type: "FeatureCollection",
            features: [
              // {
              //   type: "Feature",
              //   geometry: {
              //     type: "LineString",
              //     coordinates: pointFeature,
              //   },
              //   properties: {
              //     stroke: lineColor.hex,
              //     "stroke-width": lineWidth,
              //     name: vectorName,
              //   },
              // },
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [pointFeature],
                },
                properties: {
                  stroke: lineColor.hex,
                  "stroke-width": lineWidth,
                  fill: polygonBackground.hex,
                  "fill-opacity": fillTrans,
                  name: vectorName,
                },
              },
            ],
          };
        }
        let layerToBeAdded = [
          {
            attributes: {
              description: "",
              color: "",
              fillColor: "",
              strokeColor: "",
              transparency: "",
              strokeTransparency: "",
              fillTransparency: "",
              tags: [],
              links: [],
            },
            name: newLayerNameFromRef,
            data: datatosend,
          },
        ];
        let kml = tokml(datatosend, { simplestyle: true });
        if (kml.includes("</ExtendedData><Point>")) {
          //add base style info
          let markerURLtemp = markerURL;
          // if (editEntity) {
          //   markerURLtemp =
          //     editEntity._entityCollection._entities._array[0]._billboard._image
          //       ._value._url;
          // }
          kml = kml.replace(
            "<Document>",
            '<Document><Style id = "Icon"><IconStyle><color>ffffffff</color><scale>1.0</scale><heading>0.0</heading><Icon><href>' +
              markerURLtemp +
              '</href></Icon><hotSpot x="0.5" y="0" xunits="fraction" yunits="fraction"/></IconStyle><LabelStyle><color>ffffffff</color><scale>1.0</scale></LabelStyle></Style>'
          );
          kml = kml.replace(/relativeToGround/g, "clampToGround");
          kml = kml.replace(/<\/Point>/g, "</Point><styleUrl>#Icon</styleUrl>");
          //individual coordinates to attribute
          var kmlsplit = kml.split("</Placemark>");
          kmlsplit.forEach((kmlspliti) => {
            var coordkml = kmlspliti.match(
              /<coordinates>(.*?)<\/coordinates>/g
            );
            if (coordkml) {
              var tempcoords = coordkml
                .toString()
                .replace("<coordinates>", "")
                .replace("</coordinates>", "");
              console.log(coordkml.toString());
              kml = kml.replace(
                "</ExtendedData><Point>" + coordkml.toString(),
                '<Data name="Coordinates">' +
                  "<value>" +
                  tempcoords +
                  "</value></Data></ExtendedData><Point>" +
                  coordkml.toString()
              );
            }
          });

          //coordinate family list
          var coordString = "";
          for (let pointindex = 0; pointindex < points.length; pointindex++) {
            coordString =
              coordString +
              "[" +
              points[pointindex].x.toString() +
              "," +
              points[pointindex].y.toString() +
              "," +
              points[pointindex].z.toString() +
              "]";
            if (pointindex < points.length - 1) {
              coordString = coordString + ",";
            }
          }
          kml = kml.replace(
            /<\/ExtendedData><Point>/g,
            '<Data name="Family">' +
              "<value>" +
              coordString +
              "</value></Data>" +
              "</ExtendedData><Point>"
          );
        }
        if (kml.includes("</ExtendedData><Polygon>")) {
          kml = kml.replace(
            "<Polygon>",
            "<Polygon>" +
              "<altitudeMode>" +
              altMode +
              "</altitudeMode><tessellate>1</tessellate><extrude>0</extrude>"
          );
          let polyKml = kml.split("<PolyStyle>");
          kml = polyKml[0] + "<PolyStyle>" + "<outline>1</outline>";
          if (fillTrans == 0) {
            kml = kml + "<fill>0</fill>";
          }
          kml = kml + polyKml[1];
          if (fillTrans == 0) {
            polyKml = kml.split("<color>00");
            kml = polyKml[0] + "<color>ff" + polyKml[1];
          }
          polyKml = kml.split("</ExtendedData><Polygon>");
          const tempArray = [];
          let polyMid = { x: 0, y: 0, z: 0 };
          points.forEach((k, i) => {
            tempArray.push([k.x, k.y, k.z]);
            polyMid = {
              x: polyMid.x + k.x,
              y: polyMid.y + k.y,
              z: polyMid.z + k.z,
            };
          });
          polyMid = {
            x: polyMid.x / points.length,
            y: polyMid.y / points.length,
            z: polyMid.z / points.length,
          };

          tempArray.push(tempArray[0]);
          //use earcut because turfjs uses it and assumes 2 dimensional
          let turfPoly = polygon([tempArray]);
          var flattened = earcut.flatten(turfPoly.geometry.coordinates);
          console.log(flattened);
          let trianglized = earcut(
            flattened.vertices,
            flattened.holes,
            flattened.dimensions
          );
          let earDeviation = earcut.deviation(
            flattened.vertices,
            flattened.holes,
            flattened.dimension,
            trianglized
          );

          var triangleList = [];
          var verticeList = [];
          //Used from TurfJS, get triangle vertices
          trianglized.forEach(function(vert, i) {
            var triangleVindex = trianglized[i];
            verticeList.push([
              flattened.vertices[triangleVindex * 3],
              flattened.vertices[triangleVindex * 3 + 1],
              flattened.vertices[triangleVindex * 3 + 2],
            ]);
          });
          //get each triangle
          for (var i = 0; i < verticeList.length; i += 3) {
            var coords = verticeList.slice(i, i + 3);
            coords.push(verticeList[i]);
            triangleList.push(coords);
          }
          let totalArea = 0;
          for (
            let triangleIndex = 0;
            triangleIndex < triangleList.length;
            triangleIndex++
          ) {
            let trianglePoints = triangleList[triangleIndex];
            let point0 = Cartographic.toCartesian(
              Cartographic.fromDegrees(
                trianglePoints[0][0],
                trianglePoints[0][1],
                trianglePoints[0][2]
              )
            );
            let point1 = Cartographic.toCartesian(
              Cartographic.fromDegrees(
                trianglePoints[1][0],
                trianglePoints[1][1],
                trianglePoints[1][2]
              )
            );
            let point2 = Cartographic.toCartesian(
              Cartographic.fromDegrees(
                trianglePoints[2][0],
                trianglePoints[2][1],
                trianglePoints[2][2]
              )
            );
            let distance01 = Cartesian3.distance(point0, point1);
            let distance12 = Cartesian3.distance(point1, point2);
            let distance20 = Cartesian3.distance(point2, point0);
            console.log("Distances:");
            console.log(distance01);
            console.log(distance12);
            console.log(distance20);
            let heronS = (distance01 + distance12 + distance20) / 2;
            totalArea =
              totalArea +
              Math.sqrt(
                heronS *
                  (heronS - distance01) *
                  (heronS - distance12) *
                  (heronS - distance20)
              );
            //use Heron's formula
          }

          let area3D = totalArea;
          let area2D = area(turfPoly);

          let areaText;
          if (measureUnit.length === "m") {
            if (area3D < 1) {
              areaText = "" + (area3D * 100).toFixed(2) + " cm^2";
            } else {
              areaText = "" + area3D.toFixed(2) + " m^2";
            }
          } else if (measureUnit.length === "km") {
            if (area3D < 1) {
              areaText = "" + (area3D * 100).toFixed(2) + " cm^2";
            } else {
              areaText = "" + (area3D / 1000).toFixed(2) + " km^2";
            }
          } else if (measureUnit.length === "ft") {
            let areaToUSSurveyFeetConversion = 3.2808333333;
            let areaToFeet = area3D * areaToUSSurveyFeetConversion;
            if (areaToFeet < 1) {
              areaText = "" + (areaToFeet * 12).toFixed(2) + " in^2";
            } else {
              //survey feet in miles
              areaText = "" + areaToFeet.toFixed(2) + " ft^2";
            }
          } else if (measureUnit.length === "miles") {
            let areaToUSSurveyFeetConversion = 3.2808333333;
            let areaToFeet = area3D * areaToUSSurveyFeetConversion;
            if (areaToFeet < 1) {
              areaText = "" + (areaToFeet * 12).toFixed(2) + " in^2";
            } else {
              areaText =
                "" + (areaToFeet / 5279.98944002112).toFixed(2) + " mi^2";
            }
          } else if (measureUnit.length === "acres") {
            areaText = "" + (area3D / 4046.8564224).toFixed(3) + " acres";
          } else if (measureUnit.length === "hectares") {
            areaText = "" + (area3D / 10000).toFixed(3) + " hectares";
          }
          kml =
            polyKml[0] +
            // `<Data name="2D Area in m^2"><value>${area2D.toFixed(
            //   3
            // )}</value></Data>` +
            `<Data name="2D Area"><value>${areaText}</value></Data>` +
            "</ExtendedData><Polygon>" +
            polyKml[1];
          //create center area label
          kml = kml.replace(
            "</Style>",
            '</Style><Style id= "noicon"><IconStyle><scale>0</scale>' +
              "</IconStyle><LabelStyle><scale>1</scale><color>" +
              "ffffffff" +
              "</color></LabelStyle></Style>"
          );
          kml = kml.replace(
            "</Placemark>",
            "</Placemark><Placemark><name>" +
              areaText +
              "</name><Point><altitudeMode>" +
              altMode +
              "</altitudeMode><coordinates>" +
              polyMid.x +
              "," +
              polyMid.y +
              "," +
              (polyMid.z + 4) +
              "</coordinates></Point><styleUrl>#noicon</styleUrl></Placemark>"
          );
        }

        if (kml.includes("</ExtendedData><LineString>")) {
          //console.log("Adding data to LineString.");

          var totalDistanceInMeters = 0;
          var midpointsAndLengths = [];

          for (let pointindex = 1; pointindex < points.length; pointindex++) {
            var leftPoint = Cartographic.toCartesian(
              Cartographic.fromDegrees(
                points[pointindex - 1].x,
                points[pointindex - 1].y,
                points[pointindex - 1].z
              )
            );

            var rightPoint = Cartographic.toCartesian(
              Cartographic.fromDegrees(
                points[pointindex].x,
                points[pointindex].y,
                points[pointindex].z
              )
            );

            var midPoint = new Cartesian3();
            Cartesian3.midpoint(leftPoint, rightPoint, midPoint);
            //add distance length conversion
            //this might need to be changed to cartographic
            var distanceCalcCartesian = new Cartesian3();
            Cartesian3.subtract(leftPoint, rightPoint, distanceCalcCartesian);
            distanceCalcCartesian.x = distanceCalcCartesian.x;
            distanceCalcCartesian.y = distanceCalcCartesian.y;

            var currentLength = Math.sqrt(
              distanceCalcCartesian.x * distanceCalcCartesian.x +
                distanceCalcCartesian.y * distanceCalcCartesian.y +
                distanceCalcCartesian.z * distanceCalcCartesian.z
            ); //in meters

            //Cesium.Cartesian3.distance() is not to be trusted
            //because cartesian3.magnitude() is bugged
            //var currentLength = Cesium.Cartesian3.distance(
            //  leftPoint,
            //  rightPoint
            //);
            totalDistanceInMeters = totalDistanceInMeters + currentLength;
            midpointsAndLengths.push([
              Cartographic.fromCartesian(midPoint),
              currentLength,
            ]);
          }
          //add totalLength to first vertice
          if (midpointsAndLengths.length > 1) {
            midpointsAndLengths.push([
              Cartographic.fromDegrees(
                points[points.length - 1].x,
                points[points.length - 1].y,
                points[points.length - 1].z
              ),
              totalDistanceInMeters,
            ]);
          }
          if (measureUnit.length === "m") {
            if (totalDistanceInMeters < 1) {
              totalDistanceInMeters =
                "" + (totalDistanceInMeters * 100).toFixed(2) + " cm";
            } else {
              totalDistanceInMeters =
                "" + totalDistanceInMeters.toFixed(2) + " m";
            }
          } else if (measureUnit.length === "km") {
            if (totalDistanceInMeters < 1) {
              totalDistanceInMeters =
                "" + (totalDistanceInMeters * 100).toFixed(2) + " cm";
            } else {
              totalDistanceInMeters =
                "" + (totalDistanceInMeters / 1000).toFixed(2) + " km";
            }
          } else if (measureUnit.length === "ft") {
            let metersToFeet =
              totalDistanceInMeters * metersToUSSurveyFeetConversion;
            if (metersToFeet < 1) {
              totalDistanceInMeters =
                "" + (metersToFeet * 12).toFixed(2) + " in";
            } else {
              //survey feet in miles
              totalDistanceInMeters = "" + metersToFeet.toFixed(2) + " ft";
            }
          } else if (measureUnit.length === "miles") {
            let metersToFeet =
              totalDistanceInMeters * metersToUSSurveyFeetConversion;
            if (metersToFeet < 1) {
              totalDistanceInMeters =
                "" + (metersToFeet * 12).toFixed(2) + " in";
            } else {
              totalDistanceInMeters =
                "" + (metersToFeet / 5279.98944002112).toFixed(2) + " mi";
            }
          } else if (
            measureUnit.length === "acres" ||
            measureUnit.length === "hectares"
          ) {
            let metersToFeet =
              totalDistanceInMeters * metersToUSSurveyFeetConversion;
            if (metersToFeet < 1) {
              totalDistanceInMeters =
                "" + (metersToFeet * 12).toFixed(2) + " in";
            } else if (metersToFeet < 5279.98944002112) {
              //survey feet in miles
              totalDistanceInMeters = "" + metersToFeet.toFixed(2) + " ft";
            } else {
              totalDistanceInMeters =
                "" + (metersToFeet / 5279.98944002112).toFixed(2) + " mi";
            }
          }
          //add in total length to ExtendedData
          kml = kml.replace(
            "</ExtendedData>",
            '<Data name="Total length"><value>' +
              totalDistanceInMeters +
              "</value></Data></ExtendedData>"
          );

          kml = kml.replace(
            "<LineString>",
            "<LineString>" +
              "<altitudeMode>" +
              altMode +
              "</altitudeMode><tessellate>1</tessellate><extrude>0</extrude>"
          );

          let labelcolor = kml.split("<color>")[1].split("</color>")[0];
          kml = kml.replace(
            "</Style>",
            '</Style><Style id= "noicon"><IconStyle><scale>0</scale>' +
              "</IconStyle><LabelStyle><scale>1</scale><color>" +
              "ffffffff" +
              "</color></LabelStyle></Style>"
          );
          //add label placemarks to kml
          //label[0] = Cartesian3 midpoint/label point location
          //label[1] = length/distance
          let kmlLengthName = "";
          midpointsAndLengths.forEach((label) => {
            //could be refactored to helper method
            console.log("Line length: " + label[1]);
            if (measureUnit.length === "m") {
              if (label[1] < 1) {
                kmlLengthName = "" + (label[1] * 100).toFixed(2) + " cm";
              } else {
                kmlLengthName = "" + label[1].toFixed(2) + " m";
              }
            } else if (measureUnit.length === "km") {
              if (label[1] < 1) {
                kmlLengthName = "" + (label[1] * 100).toFixed(2) + " cm";
              } else {
                kmlLengthName = "" + (label[1] / 1000).toFixed(2) + " km";
              }
            } else if (measureUnit.length === "ft") {
              let metersToFeet = label[1] * metersToUSSurveyFeetConversion;
              if (metersToFeet < 1) {
                kmlLengthName = "" + (metersToFeet * 12).toFixed(2) + " in";
              } else {
                //survey feet in miles
                kmlLengthName = "" + metersToFeet.toFixed(2) + " ft";
              }
            } else if (measureUnit.length === "miles") {
              let metersToFeet = label[1] * metersToUSSurveyFeetConversion;
              if (metersToFeet < 1) {
                kmlLengthName = "" + (metersToFeet * 12).toFixed(2) + " in";
              } else {
                kmlLengthName =
                  "" + (metersToFeet / 5279.98944002112).toFixed(2) + " mi";
              }
            } else if (
              measureUnit.length === "acres" ||
              measureUnit.length === "hectares"
            ) {
              let metersToFeet = label[1] * metersToUSSurveyFeetConversion;
              if (metersToFeet < 1) {
                kmlLengthName = "" + (metersToFeet * 12).toFixed(2) + " in";
              } else if (metersToFeet < 5279.98944002112) {
                //survey feet in miles
                kmlLengthName = "" + metersToFeet.toFixed(2) + " ft";
              } else {
                kmlLengthName =
                  "" + (metersToFeet / 5279.98944002112).toFixed(2) + " mi";
              }
            }
            //^^could be refactored to helper method
            kml = kml.replace(
              "</Placemark>",
              "</Placemark><Placemark><name>" +
                kmlLengthName +
                "</name><Point><altitudeMode>" +
                altMode +
                "</altitudeMode><coordinates>" +
                CesMath.toDegrees(label[0].longitude) +
                "," +
                CesMath.toDegrees(label[0].latitude) +
                "," +
                (label[0].height + 4) +
                "</coordinates></Point><styleUrl>#noicon</styleUrl></Placemark>"
            );
          });
        } else {
          console.log("KML detect <LineString> bypassed.");
        }
        var parts = [new Blob([kml], { type: "text/plain" })];

        // Construct a file
        var kml_file = new File(parts, newLayerNameFromRef + ".kml", {
          lastModified: new Date(0), // optional - default = now
          type: "text/xml", // optional - default = ''
        });
        if (kml_file) {
          console.log("kml_file", kml);
          uploadVector(kml_file);
          initialPositions = [];
          initialPositionsNew = [];
          setPositions([]);
          setPoints([]);
          setEditSelected(false);
          document.getElementById("vectorNameVal").value = "";
          initialPoints = [];
          initialPositions = [];
          initialPositionsNew = [];
          setPositions([]);
          setPoints([]);
          // vectorName = "";
          return Promise.resolve("resolve");
        } else {
          errorDisplay("error", "Request Unsuccessful");
          return Promise.resolve("resolve");
        }
        return true;
      });
    } else {
      errorDisplay("error", "Please add layer name before saving!");
      return Promise.reject("resolve");
    }
  };

  const defaultKmlSetState = () => {
    setSelectedAltitudeModeKml("absolute");
    setFillColor("");
    setFillTrans(1);
    setStrokeColor("");
    setStrokeTrans(1);
    setInfoLayerTags([]);
    setInfoLayerLinks([]);
    setInfoLayerDes(" ");
    setInfoLayerDes("");
  };
  const getFeatureMediaFunction = (layerId, fid) => {
    if (typeof layerId === "string" && layerId.includes("folder_")) {
      getFeatureAttrFolder(layerId.split("folder_")[1]).then((attr) => {
        console.log("newLayerAtrFolder", attr);
        console.log("infoLayerSelected", infoLayerSelected);
        if (attr.data.featureAttribute) {
          if (
            infoLayerSelectedVar == "Polygon" ||
            infoLayerSelectedVar == "LineString" ||
            infoLayerSelectedVar == "MultiLineString" ||
            infoLayerSelectedVar == "Point"
          ) {
            attr.data.featureAttribute.hasOwnProperty("altitudeMode")
              ? attr.data.featureAttribute.altitudeMode != null
                ? setSelectedAltitudeModeKml(
                    attr.data.featureAttribute.altitudeMode
                  )
                : setSelectedAltitudeModeKml("absolute")
              : setSelectedAltitudeModeKml("absolute");
            attr.data.featureAttribute.hasOwnProperty("fillColor")
              ? setFillColor(attr.data.featureAttribute.fillColor)
              : setFillColor("");
            attr.data.featureAttribute.hasOwnProperty("fillTransparency")
              ? setFillTrans(
                  parseFloat(attr.data.featureAttribute.fillTransparency)
                )
              : setFillTrans(1);
            attr.data.featureAttribute.hasOwnProperty("strokeColor")
              ? setStrokeColor(attr.data.featureAttribute.strokeColor)
              : setStrokeColor("");
            attr.data.featureAttribute.hasOwnProperty("strokeTransparency")
              ? setStrokeTrans(
                  parseFloat(attr.data.featureAttribute.strokeTransparency)
                )
              : setStrokeTrans(1);
          }

          setInfoLayerTags(attr.data.featureAttribute.tags);
          if (
            attr.data.featureAttribute.links &&
            attr.data.featureAttribute.links.length > 0
          ) {
            setLinkAccordian(true);
          } else {
            setLinkAccordian(false);
          }
          setInfoLayerLinks(
            attr.data.featureAttribute.links
              ? attr.data.featureAttribute.links
              : []
          );
          setInfoLayerDes(attr.data.featureAttribute.description);
          // setDateCreated(
          //   attr.data.featureAttribute.createdAt.split("T")[0] +
          //     " " +
          //     attr.data.featureAttribute.createdAt.split("T")[1].substring(0, 8)
          // );
        } else {
          console.log("From Attribute DATA  False");
          defaultKmlSetState();

          // setDateCreated("");
        }
        getFeatureMedia(layerId, fid)
          .then(async (res) => {
            try {
              let mediaList = res.data.media;
              // for (let i = 0; i < mediaList.length; i++) {
              //   let media = mediaList[i];
              //   media.mediaKey = await getSignUrl(media.mediaKey);
              // }
              if (mediaList.length > 0) {
                setMediaAccordian(true);
              } else {
                setMediaAccordian(false);
              }
              allLayers = allLayers.map((thisInfo) => {
                if (thisInfo.id == layerId) {
                  thisInfo.feature_media = unionBy(
                    mediaList,
                    thisInfo.feature_media,
                    "id"
                  );
                }
                return thisInfo;
              });
              infoLayerId = null;
              infoLayerFeatureId = null;
              setInfoLayerSelectedMedia(mediaList);
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else {
      getFeatureAttr(layerId, fid).then((attr) => {
        console.log("newLayerAtr", attr);
        console.log("infoLayerSelected12", infoLayerSelected);
        if (attr.data.featureAttribute) {
          if (
            infoLayerSelectedVar == "Polygon" ||
            infoLayerSelectedVar == "LineString" ||
            infoLayerSelectedVar == "MultiLineString" ||
            infoLayerSelectedVar == "Point"
          ) {
            attr.data.featureAttribute.hasOwnProperty("altitudeMode")
              ? attr.data.featureAttribute.altitudeMode != null
                ? setSelectedAltitudeModeKml(
                    attr.data.featureAttribute.altitudeMode
                  )
                : setSelectedAltitudeModeKml("absolute")
              : setSelectedAltitudeModeKml("absolute");
            attr.data.featureAttribute.hasOwnProperty("fillColor")
              ? setFillColor(attr.data.featureAttribute.fillColor)
              : setFillColor("");
            attr.data.featureAttribute.hasOwnProperty("fillTransparency")
              ? setFillTrans(
                  parseFloat(attr.data.featureAttribute.fillTransparency)
                )
              : setFillTrans(1);
            attr.data.featureAttribute.hasOwnProperty("strokeColor")
              ? setStrokeColor(attr.data.featureAttribute.strokeColor)
              : setStrokeColor("");
            attr.data.featureAttribute.hasOwnProperty("strokeTransparency")
              ? setStrokeTrans(
                  parseFloat(attr.data.featureAttribute.strokeTransparency)
                )
              : setStrokeTrans(1);
          }

          setInfoLayerTags(attr.data.featureAttribute.tags);
          if (
            attr.data.featureAttribute.links &&
            attr.data.featureAttribute.links.length > 0
          ) {
            setLinkAccordian(true);
          } else {
            setLinkAccordian(false);
          }
          setInfoLayerLinks(
            attr.data.featureAttribute.links
              ? attr.data.featureAttribute.links
              : []
          );
          setInfoLayerDes(attr.data.featureAttribute.description);
          // setDateCreated(
          //   attr.data.featureAttribute.createdAt.split("T")[0] +
          //     " " +
          //     attr.data.featureAttribute.createdAt.split("T")[1].substring(0, 8)
          // );
        } else {
          console.log("From Attribute DATA  False");
          defaultKmlSetState();
          let selected = allLayers.filter((layer) => layer.id == layerId);
          let templinks =
            getNullFeature(selected[0].feature_attributes) == -1
              ? []
              : selected[0].feature_attributes[
                  getNullFeature(selected[0].feature_attributes)
                ].links;
          setInfoLayerLinks(templinks);

          // setDateCreated("");
        }
        getFeatureMedia(layerId, fid)
          .then(async (res) => {
            try {
              let mediaList = res.data.media;
              // for (let i = 0; i < mediaList.length; i++) {
              //   let media = mediaList[i];
              //   media.mediaKey = await getSignUrl(media.mediaKey);
              // }
              if (mediaList.length > 0) {
                setMediaAccordian(true);
              } else {
                setMediaAccordian(false);
              }
              allLayers = allLayers.map((thisInfo) => {
                if (thisInfo.id == layerId) {
                  thisInfo.feature_media = unionBy(
                    mediaList,
                    thisInfo.feature_media,
                    "id"
                  );
                }
                return thisInfo;
              });
              infoLayerId = null;
              infoLayerFeatureId = null;
              setInfoLayerSelectedMedia(mediaList);
            } catch (e) {
              console.log(e);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const colorPalletChangeHandler = (a, b, c) => {
    setSelectedColorPallet(heightColorList[b.props.value]);
  };
  const styleClassEditHandler = (k) => {
    const tempStyleClassEdit = { ...styleClassEdit };
    tempStyleClassEdit["index"] = k.id;
    tempStyleClassEdit["color"] = k.color;
    tempStyleClassEdit["active"] = true;
    setStyleClassEdit(tempStyleClassEdit);
  };
  const onChangeClassEditColorHandler = (color) => {
    const tempStyleClass = [...styleClass];
    const tempStyleClassEdit = { ...styleClassEdit };
    //console.log("onChangeClassEditColorHandler",color)
    tempStyleClassEdit["color"] = color;

    //console.log("styleClassEdit",styleClassEdit)
    // tempStyleClass[tempStyleClassEdit["index"]]["color"] =
    //   tempStyleClassEdit["color"];
    let checkIndex = null;
    checkIndex = tempStyleClass.findIndex((object) => {
      console.log("objectId", object.id);
      console.log("tempStyleClassEdit.index", tempStyleClassEdit.index);
      return object.id === tempStyleClassEdit.index;
    });
    console.log("checkIndex", checkIndex);
    if (checkIndex || checkIndex === 0) {
      tempStyleClass[checkIndex]["color"] = tempStyleClassEdit["color"];
      console.log("checkIndex", checkIndex);
    }
    setStyleClassEdit(tempStyleClassEdit);
    setStyleClass(tempStyleClass);
  };
  const styleClassDeleteHandler = (k) => {
    const tempStyleClass = [...styleClass];
    const tempdefaultStyleArray = [...defaultStyleArray];

    const tempStyleClassEdit = { ...styleClassEdit };
    tempStyleClassEdit["index"] = k.id;

    //tempStyleClassEdit["active"] = true;

    // setStyleClassEdit(tempStyleClassEdit);
    let checkIndex = null;
    checkIndex = tempdefaultStyleArray.findIndex((object) => {
      return object.id === tempStyleClassEdit.index;
    });

    let checkIndexStyleClass = null;
    checkIndexStyleClass = tempStyleClass.findIndex((object) => {
      return object.id === tempStyleClassEdit.index;
    });

    if (checkIndex || checkIndex === 0) {
      if (checkIndexStyleClass || checkIndexStyleClass === 0) {
        tempStyleClass[checkIndexStyleClass]["color"] =
          tempdefaultStyleArray[checkIndex]["color"];
        tempStyleClassEdit["color"] =
          tempdefaultStyleArray[checkIndex]["color"];
      }
    }
    //setStyleClassEdit(tempStyleClassEdit);
    setStyleClass(tempStyleClass);
    setStyleClassEdit(tempStyleClassEdit);
    //onChangeClassEditColorHandler(k.color);
  };
  const closeStyleClassHandler = () => {
    const tempStyleClassEdit = { ...styleClassEdit };
    tempStyleClassEdit["active"] = false;
    setStyleClassEdit(tempStyleClassEdit);
  };
  const handleChangeSwitch = (chk, event, id) => {
    console.log("resp switch", chk, event, id);
    const tempStyleClass = [...styleClass];
    console.log("tempStyleClass", tempStyleClass);
    let checkIndex = null;
    checkIndex = tempStyleClass.findIndex((object) => {
      return object.id === id;
    });

    if (checkIndex || checkIndex === 0) {
      tempStyleClass[checkIndex]["active"] = chk;
    }

    setStyleClass(tempStyleClass);
  };
  // const isValidJSON = (str) => {
  //   try {
  //     JSON.parse(str);
  //     return true;
  //   } catch (e) {
  //     return false;
  //   }
  // };
  const WebMapServiceImageryProviderHandler = (item) => {
    //console.log("WebMapServiceImageryProviderHandler", item);
    //const viewer = mapRef.current.cesiumElement;
    // const checkUrl = isValidJSON(item.url);

    const dataObj = JSON.parse(item.url);
    //console.log("dataObjWMS", dataObj);
    if (dataObj.textValWMS) {
      // console.log("dataObj", dataObj);
      const updatedUrl = dataObj.textValWMS.replace("\t", "");
      //console.log("updatedUrl", updatedUrl);
      let imageryLayerProvider = null;
      if (dataObj?.wmsLayerType == "checkWmts") {
        const dataLayerObj = JSON.parse(dataObj.layerData);

        const identifier = dataObj.textValLayerName;
        const filteredObjects = dataLayerObj.filter(
          (obj) => obj.identifier === identifier
        );

        if (filteredObjects.length > 0) {
          const coords = filteredObjects[0].boundingBox
            .split(",")
            .map((num) => parseFloat(num.trim()));
          console.log(
            "checkWmts",

            filteredObjects,
            coords
          );
          imageryLayerProvider = new WebMapTileServiceImageryProvider({
            url: filteredObjects[0].resourceURL,
            layer: filteredObjects[0].identifier,
            style: "default",
            format: "image/png",
            tileMatrixSetID: filteredObjects[0].tileMatrixSetId,
            rectangle: Rectangle.fromDegrees(
              coords[0],
              coords[1],
              coords[2],
              coords[3]
            ),
            tilingScheme: new WebMercatorTilingScheme(),
          });
        }
      } else {
        imageryLayerProvider = new WebMapServiceImageryProvider({
          url: updatedUrl,
          layers: dataObj.textValLayerName,
          parameters: {
            transparent: true,
            tiled: dataObj?.wmsLayerType === "checkOgcWms" ? false : true,
            format: "image/png",
          },
        });
      }

      const imageryLayer = new ImageryLayerCesium(imageryLayerProvider);
      imageryLayer["itemId"] = item.id;
      imageryLayer["layerType"] = 7;

      let check = false;
      if (viewer.imageryLayers._layers.length > 0) {
        viewer.imageryLayers._layers.forEach((k, i) => {
          if (k.itemId === item.id) {
            check = true;
            k.show = item.show;
          }
        });
      } else {
        imageryLayer.show = item.show;
        viewer.imageryLayers.add(imageryLayer);
        mapFeatures.push({
          id: item.id,
          type: 7,
          entity: imageryLayer,
          show: item.show,
        });
      }

      if (check === false) {
        imageryLayer.show = item.show;
        viewer.imageryLayers.add(imageryLayer);
        mapFeatures.push({
          id: item.id,
          type: 7,
          entity: imageryLayer,
          show: item.show,
        });
      }
    }

    //console.log("item", item);
    //console.log("viewer.imageryLayers", viewer.imageryLayers);
  };

  const debouncedSetState = debounce(
    // function

    (value) => {
      setSearchVal(value);
      if (value.length) {
        setAppState({
          ...appState,
          projectsFilter: filter(
            appState.projects,
            ({ name, feature_attributes, entity }) =>
              name.toLowerCase().includes(value.toLowerCase()) ||
              value.toLowerCase().includes(name.toLowerCase()) ||
              (feature_attributes &&
                feature_attributes.length &&
                feature_attributes.filter(
                  (itemAttr) =>
                    itemAttr.tags &&
                    itemAttr.tags.filter(
                      (itemTag) =>
                        typeof itemTag === "string" &&
                        itemTag !== "" &&
                        (itemTag
                          ?.toLowerCase()
                          .includes(value?.toLowerCase()) ||
                          value?.toLowerCase().includes(itemTag?.toLowerCase()))
                    ).length
                ).length) ||
              ((name.toLowerCase().includes(".kml") ||
                name.toLowerCase().includes(".kmz")) &&
                entity?._entityCollection?._entities?._array.filter(
                  (kmlItem) =>
                    kmlItem?._name
                      ?.toLowerCase()
                      .includes(searchVal?.toLowerCase()) ||
                    searchVal
                      ?.toLowerCase()
                      .includes(kmlItem?._name?.toLowerCase())
                ).length)
          ),
        });
      } else {
        setAppState({
          ...appState,
          projectsFilter: null,
        });
      }
    },
    // delay in ms
    800
  );

  const viewerImagerAddFn = async (item) => {
    console.log("viewerImagerAddFn", item);
    const tokkenResp = authHeader();
    let urlToPass = null;
    let imageryLayer = null;
    let imageryLayerProvider = null;
    if (API_URL == "https://devapi.metageo.io/") {
      urlToPass = new Resource({
        url: item.url
          .replace(/['"]+/g, "")
          .replace(
            "https://s3-us-west-1.amazonaws.com/mg-tiff-output/",
            "https://devtiles.metageo.io/s3/map/"
          )
          .replace(
            "https://dev.metageo.io/s3/map/",
            "https://devtiles.metageo.io/s3/map/"
          )
          .replace(
            "https://devapi.metageo.io/s3/map",
            "https://devtiles.metageo.io/s3/map/"
          ),
        queryParameters: { access_token: tokkenResp },
      });
    } else {
      urlToPass = new Resource({
        url: item.url
          .replace(/['"]+/g, "")
          .replace(
            "https://s3-us-west-1.amazonaws.com/mg-tiff-output/",
            "https://tiles.metageo.io/s3/map/"
          )
          .replace(
            "https://map.metageo.io/s3/map/",
            "https://tiles.metageo.io/s3/map/"
          )
          .replace(
            "https://api.metageo.io/s3/map",
            "https://tiles.metageo.io/s3/map/"
          ),
        queryParameters: { access_token: tokkenResp },
      });
    }

    try {
      imageryLayerProvider = await TileMapServiceImageryProvider.fromUrl(
        urlToPass,
        {
          enablePickFeatures: true,
          getFeatureInfoAsXml: true,
          getFeatureInfoAsGeoJson: true,
          getFeatureInfoParameters: {
            info_format: "text/plain",
          },
        }
        // {
        //   url: urlToPass,
        //   tileWidth: 20,
        //   tileHeight: 200,
        //   maximumLevel: 20,
        //   minimumLevel: 0,

        // }
      );

      imageryLayer = new ImageryLayerCesium(imageryLayerProvider);
      imageryLayer["itemId"] = item.id;
      imageryLayer["layerType"] = 2;
      imageryLayer["item"] = item;
    } catch (error) {
      console.error("Error loading KML data:", error);
    }

    return imageryLayer;
  };

  const viewerKmlAddFn = async (item) => {
    //const viewer = mapRef.current.cesiumElement;
    let kmlLayer = null;
    // console.log("viewerKmlAddFn", item);
    try {
      const dataSource = await KmlDataSource.load(item.url, {
        camera: viewer.scene.camera,
        canvas: viewer.scene.canvas,
        clampToGround: item?.altitudeMode === "absolute" ? false : true,
        // screenOverlayContainer: viewer.container,
      });

      if (dataSource) {
        //console.log("dataSource", dataSource, item);

        dataSource._entityCollection._entities._array.forEach((k) => {
          k["kmlItem"] = item;
        });
        // Add the data source to the viewer

        kmlLayer = dataSource; // Assign the dataSource to kmlLayer
      }
    } catch (error) {
      console.error("Error loading KML data:", error);
      // You can add further error handling here if needed
      kmlLayer = null;
    }

    //console.log("kmlLayer", kmlLayer);
    return kmlLayer;
  };
  const addKmlToViewer = (kmlLayer, viewer, item) => {
    kmlLayer.show = item.show;
    kmlLayer["itemId"] = item.id;
    appState.projects.forEach((project) => {
      if (project.id === item.projectId) {
        loadedEntitties = loadedEntitties.filter(
          (itemloaded) => itemloaded.id !== item.id
        );
        updateItemInProject(project.children, item.folderId, item.id, kmlLayer);
        loadedEntitties.push({
          id: item.id,
          entity: kmlLayer,
        });
        if (editableVector && editableVector.id == item.id) {
          setEditableVector({
            id: item.id,
            entity: kmlLayer,
          });
        }
        mapFeatures.push({
          id: item.id,
          type: item.type,
          entity: kmlLayer,
          show: item.show,
        });
      }
    });
    //console.log("kmlLayer", kmlLayer);
    viewer.dataSources.add(kmlLayer);
  };
  const kmlDataSourceProviderHandler = async (item) => {
    //const viewer = mapRef.current.cesiumElement;
    let kmlLayer = null;

    let check = false;
    if (viewer.dataSources._dataSources.length > 0) {
      viewer.dataSources._dataSources.forEach((k, i) => {
        if (k.itemId === item.id) {
          check = true;
          if (k.show != item.show) {
            k.show = item.show;
          }
        }
      });
    } else {
      kmlLayer = await viewerKmlAddFn(item);
      if (kmlLayer) {
        addKmlToViewer(kmlLayer, viewer, item);
      }
    }

    if (!check) {
      if (item.change) {
        let temp = await getSingleLayer(item.id);
        item.url = temp.data.layer.signedUrl;
      }
      kmlLayer = await viewerKmlAddFn(item);
      if (kmlLayer) {
        addKmlToViewer(kmlLayer, viewer, item);
      }
    }
  };

  const TileMapServiceImageryProviderHandler = async (item) => {
    console.log("TileMapServiceImageryProviderHandler", item);

    // const viewer = mapRef.current.cesiumElement;
    let imageryLayer = null;
    if (item.hasOwnProperty("flyTo") && item?.flyTo) {
      const checkMapFeature = hasFeatureWithId(item.id);
      if (checkMapFeature) {
        fileAccordianOnDoubleClick(false, item);
      } else {
        imageryLayer = await viewerImagerAddFn(item);
        if (imageryLayer) {
          imageryLayer.show = item.show;
          viewer.imageryLayers.add(imageryLayer);
          viewer.flyTo(imageryLayer, { offset: FlyToHeadingPitchRange() });

          mapFeatures.push({
            id: item.id,
            type: 2,
            entity: imageryLayer,
            show: item.show,
          });
        }
      }
    } else {
      let check = false;
      if (viewer.imageryLayers._layers.length > 0) {
        viewer.imageryLayers._layers.forEach((k, i) => {
          if (k.itemId === item.id) {
            check = true;
            k.show = item.show;
          }
        });
      } else {
        imageryLayer = await viewerImagerAddFn(item);
        if (imageryLayer && item.show) {
          imageryLayer.show = item.show;
          viewer.imageryLayers.add(imageryLayer);
          mapFeatures.push({
            id: item.id,
            type: 2,
            entity: imageryLayer,
            show: item.show,
          });
        }
      }

      if (!check && item.show) {
        //console.log("In Check");
        imageryLayer = await viewerImagerAddFn(item);
        if (imageryLayer) {
          imageryLayer.show = item.show;
          viewer.imageryLayers.add(imageryLayer);
          mapFeatures.push({
            id: item.id,
            type: 2,
            entity: imageryLayer,
            show: item.show,
          });
        }
      }
    }

    //console.log("item",item)
    // console.log("ImageryLayers", viewer.imageryLayers.length);
  };

  const changeClassificationStyleMode = (event) => {
    //setSelectedValue(event.target.value);
    //console.log("styleMode",event.target.value)
    setStyleMode(event.target.value);
  };
  const selectIcon = (file) => {
    // **** add all condition of layer icon here

    if (
      file?.status?.status &&
      !file?.status?.status.toLowerCase().includes("failed")
    ) {
      return (file.folderUrl || file.exifData) && file.layerType != 6 ? (
        file.layerType == 3 ||
        (file.layerType == 4 &&
          !file?.status?.status.toLowerCase().includes("processing")) ||
        file.layerType == 1 ? (
          <FormatShapesIcon
            style={{
              color:
                Number(layerPanelSelected) == Number(file.id) ? "white" : "",
            }}
          />
        ) : file.layerType == 2 ? (
          <WallpaperIcon
            style={{
              color:
                Number(layerPanelSelected) == Number(file.id) ? "white" : "",
            }}
          />
        ) : file.layerType == 5 ? (
          <CameraIcon
            style={{
              color:
                Number(layerPanelSelected) == Number(file.id) ? "white" : "",
            }}
          />
        ) : (
          <SettingsSystemDaydream
            style={{
              color:
                Number(layerPanelSelected) == Number(file.id) ? "white" : "",
            }}
          />
        )
      ) : file.layerType == 4 &&
        file.signedUrl &&
        !file?.status?.status.toLowerCase().includes("processing") ? (
        <FormatShapesIcon
          style={{
            color: Number(layerPanelSelected) == Number(file.id) ? "white" : "",
          }}
        />
      ) : file.layerType == 6 &&
        (file?.status?.status === "intervalDone" ||
          file?.status?.status === "Done") ? (
        <SettingsSystemDaydream
          style={{
            color: Number(layerPanelSelected) == Number(file.id) ? "white" : "",
          }}
        />
      ) : (
        <SyncIcon
          style={{
            animation: `3s linear 0s infinite reverse none running spin`,
            color: Number(layerPanelSelected) == Number(file.id) ? "white" : "",
          }}
        />
      );
    } else if (file.layerType == 7) {
      //console.log("filetype7", file);
      return (
        <MapIcon
          style={{
            color: Number(layerPanelSelected) == Number(file.id) ? "white" : "",
          }}
        />
      );
    } else {
      // style={{ color: Number(layerPanelSelected) == Number(file.id) ? 'white' : '' }}

      return typeof file?.status == "object" &&
        "errors" in file?.status &&
        typeof file?.status.errors == "string" ? (
        <Tooltip
          classes={{ tooltip: styles().tooltip }}
          title={file?.status.errors}
          placement="right"
        >
          <ErrorIcon style={{ color: "#c73939" }} />
        </Tooltip>
      ) : (
        <ErrorIcon style={{ color: "#c73939" }} />
      );
    }
  };
  const selectName = (file) => {
    // **** add all condition of layer name here
    if (file.layerType == 7) {
      const dataObj = JSON.parse(file.data);
      //console.log("dataObjWMSInSelectName", dataObj);
      // return `${file.name} `;
      if (dataObj.layerLabel && dataObj.textValLayerName) {
        return dataObj.layerLabel + dataObj.textValLayerName;
      } else {
        return `${file.name} `;
      }
    } else if (file.layerType == 6 && file?.status?.status === "intervalDone") {
      return `${file.name} `;
    } else if (file.layerType == 5 && file?.status?.status === "Complete") {
      return `${file.name} `;
    } else {
      return `${file.name}${
        file.folderUrl ||
        file.exifData ||
        (file.layerType == 4 &&
          !file?.status?.status.toLowerCase().includes("processing"))
          ? ""
          : `(${file?.status?.status})`
      }`;
    }
  };

  const CapitaliseFirstWord = ({ name }) => {
    console.log("name", name);
    const capitalise = name.split(" ");

    for (let i = 0; i < capitalise.length; i++) {
      if (capitalise[i][0]) {
        capitalise[i] =
          capitalise[i][0].toUpperCase() + capitalise[i].substr(1);
      } else {
        capitalise[i] = capitalise[i].substr(1);
      }
    }
    capitalise.join(" ");
    return capitalise;
  };

  const handleClickCheck = (item, project, device, event) => {
    switch (event.detail) {
      case 1:
        console.log("click");
        if (device === "Desktop") {
          setAppState({
            ...appState,
            projectId: project.id,
            folderId: null,
            fileId: null,
          });
        } else {
          projectAccordianOnClick(item, project);
        }
        break;
      case 2:
        console.log("double click");
        projectAccordianOnClick(item, project);
        break;
      // case 3:
      //   console.log("triple click");
      //   break;
    }
  };
  const projectAccordianOnClick = (item, project) => {
    if (openAccordians1.includes(item.id)) {
      openAccordians1 = openAccordians1.filter((fil) => fil !== item.id);
    } else {
      openAccordians1.push(item.id);
    }
    //console.log("DataContext", props);
    setAppState({
      ...appState,
      projectId: project.id,
      folderId: null,
      fileId: null,
    });
  };

  const handleClickCheckFolder = (item, project, device, event) => {
    switch (event.detail) {
      case 1:
        console.log("click");
        if (device === "Desktop") {
          setAppState({
            ...appState,
            projectId: project.id,
            folderId: item.id,
            fileId: null,
          });
        } else {
          folderAccordianOnClick(item, project);
        }
        break;
      case 2:
        console.log("double click");
        folderAccordianOnClick(item, project);
        break;
      // case 3:
      //   console.log("triple click");
      //   break;
    }
  };
  const folderAccordianOnClick = (item, project) => {
    if (openAccordians2.includes(item.id)) {
      openAccordians2 = openAccordians2.filter((fil) => fil !== item.id);
    } else {
      openAccordians2.push(item.id);
    }
    setAppState({
      ...appState,
      projectId: project.id,
      folderId: item.id,
      fileId: null,
    });
  };

  const updatingImgStates = (item) => {
    getImagesFolder(item.projectId, item.folderId)
      .then((res) => {
        if (res.status === 200) {
          // removeKeyListeners();
          const tempImgArray = res.data.result;

          const findIndex = tempImgArray.findIndex((obj) => obj.id === item.id);

          console.log("Resp_getImagesFolder", tempImgArray[findIndex]);
          setInfoLayerSelected("Image");
          getFeatureMediaFunction(item.id, null);
          var json_data = tempImgArray[findIndex].exifData.data[0];
          var result = [];
          for (var m in json_data) result.push([m, json_data[m]]);
          var tableData =
            '<table style="display: block;width: initial;overflow: auto;"><tr><td>Name</td><td>Value</td></tr>';
          for (var n = 0; n < result.length; n++) {
            tableData += "<tr>";
            tableData += "<td>" + result[n][0] + "</td>";
            tableData += "<td>" + result[n][1] + "</td></tr>";
          }
          tableData += "</table>";
          setInfoLayerProperties(tableData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatingVectorStates = (entityObj, featureIdParam) => {
    console.log("entityObj", entityObj);
    if (!editSelected && !measureSelected) {
      let description = entityObj?._description?._value;
      let featureId = featureIdParam;
      if (entityObj?._label) {
        setInfoLayerSelected("Point");
        //featureId = entityObj?._position._value;
      } else if (entityObj?._polyline) {
        //picked.id._polyline._clampToGround = true;
        console.log("Clicked LineString", entityObj?._polyline);
        setInfoLayerSelected("LineString");
        //featureId = entityObj?._polyline._positions._value[0];
      } else if (entityObj?._polygon) {
        setInfoLayerSelected("Polygon");
        //featureId = entityObj?._polygon._hierarchy._value.positions[0];
      } else if (entityObj?._position) {
        setInfoLayerSelected("Others");
        //featureId = entityObj?._position._value;
      }
      if (featureId) {
        getFeatureMediaFunction(featureId, null);
      }
      console.log("description", description);

      setInfoLayerProperties(description);
    }
  };

  // const IsTilesetInView = (tileset) => {
  //   // tileset.tileVisible.addEventListener(function(tile) {
  //   //   if (tile.content instanceof Cesium.Model3DTileContent) {
  //   //     console.log("doubleClick3dTileset", tile._visible);
  //   //   }
  //   // });
  //   // tileset.allTilesLoaded.addEventListener(() => {
  //   //   console.log("doubleClick3dTileset", true);
  //   // });
  //   const camera = viewer.camera;
  //   const frustum = camera.frustum;
  //   const cullingVolume = frustum.computeCullingVolume(
  //     camera.position,
  //     camera.direction,
  //     camera.up
  //   );
  //   const intersection = cullingVolume.computeVisibility(
  //     tileset.boundingSphere
  //   );

  //   console.log(
  //     "doubleClick3dTileset",
  //     intersection,
  //     cullingVolume,
  //     tileset.boundingSphere
  //   );
  // };
  const fileAccordianOnDoubleClick = (event, item, doNotFlyChk) => {
    console.log("doubleClickItem", item);
    if (item.layerType == 7) {
      const identifier = item.id;
      const filteredObjects = mapFeatures.filter(
        (obj) => obj.id === identifier
      );
      viewer.flyTo(filteredObjects[0].entity, {
        offset: FlyToHeadingPitchRange(),
      });

      console.log(filteredObjects);
    } else {
      if (
        item?.status?.status.toLowerCase().includes("processing") ||
        item?.status?.status.toLowerCase().includes("failed")
      ) {
        return;
      }
      if (event) {
        event.stopPropagation();
      }

      const globe = viewer.scene.globe;
      if (globe.translucency.enabled) {
        setTransparencyEnabled(false);
      }

      if (item.entity) {
        if (item?.exifData) {
          previewImageFunc({
            projectId: item.projectId,
            folderId: item.folderId,
            layerId: item.id,
          });
        }
        console.log("doubleClick", item, item.entity);
        if (item.entity instanceof Cesium3DTileset && item.entity._asset) {
          //var IsTilesetVisible = IsTilesetInView(item.entity);

          const tempdefaultStyleArray = [...defaultStyleArray];

          const tilesetClassesArray = item.entity._asset.classes
            ? item.entity._asset.classes
            : [];
          selectedPrimitive = item.entity;
          pointCloudSetStyle(tempdefaultStyleArray, tilesetClassesArray);
          setInfoLayerSelected("Point Cloud");
          console.log("3dtilesetdouble", item);

          if (!doNotFlyChk) {
            flyToEntity(item.entity);
            flyTo3dTilesetFunctions(item.id);
          }
        } else if (item.entity instanceof Model) {
          FrustumFlyToFn(viewer, item);
        } else {
          if (!doNotFlyChk) {
            flyToCordVectors(item);
          }
          console.log("entityTest", item);
          if (item?.exifData) {
            updatingImgStates(item);
          } else {
            updatingVectorStates(
              item.entity._entityCollection._entities._array[0],
              item.id
            );
          }
        }
      } else {
        let tempLayer = mapFeatures.filter((item1) => item1.id == item.id);
        if (tempLayer.length) {
          viewer.flyTo(tempLayer[0].entity, {
            offset: FlyToHeadingPitchRange(),
          });
          return;
        }
        const cords = item.latLong ? item.latLong?.coordinates : null;
        if (cords) {
          cords && cords.minx && flyToRectangle(cords);
          cords && flyToCords(cords[1], cords[0]);
        } else {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              message:
                "There is a problem with the geolocation of this file, please check the file or contact support.",
              severity: "error",
              isOpen: true,
            },
          });
        }
      }
    }
  };

  const getNestedLayer = (children, layerArray, projectId) => {
    children?.forEach((item) => {
      if (item.isFolder) {
        getNestedLayer(item.children, layerArray, projectId);
      } else {
        if (!(item?.status?.status === "Failed")) {
          item.projectId = projectId;
          layerArray.push(item);
        }
      }
    });
  };
  const updateLocalStorage = (storageCapacity, usedStorage) => {
    if (storageCapacity && usedStorage) {
      secureLocalStorage.setItem(
        "availableStorage",
        storageCapacity - usedStorage
      );
    }
  };
  const errorDisplay = (type, message) => {
    dispatch({
      type: SET_MESSAGE,
      payload: { message: message, severity: type, isOpen: true },
    });
  };

  const hasFeatureWithId = (id) => {
    return mapFeatures.some((feature) => feature.id === id);
  };
  const tilesetAddFns = (layer, flyTo) => {
    if (layer.folderUrl) {
      console.log("3dtilesetLayer1", layer);
      loadedFiles.push(layer.id);
      pendingImagery = pendingImagery.filter((item) => item != layer.id);

      pointCloudTileSetHandler(layer.folderUrl + "tileset.json")
        .then((tileset) => {
          if (tileset) {
            tileset.show = layer.displayStatus ? true : false;

            //Add Point Icon of 3dTileset Here 1
            let cartographic = Cartographic.fromCartesian(
              tileset.boundingSphere.center
            );
            var longitudeString = CesMath.toDegrees(
              cartographic.longitude
            ).toFixed(6);
            var latitudeString = CesMath.toDegrees(
              cartographic.latitude
            ).toFixed(6);
            var heightString = cartographic.height;
            const long = parseFloat(longitudeString);
            const lat = parseFloat(latitudeString);
            const height = parseFloat(heightString);
            const billBoardPoint3dTileSet = viewer.entities.add({
              position: Cartesian3.fromDegrees(long, lat),
              customPropBillboard: { layer: layer },
              billboard: {
                image: pinkMarker,
                show: layer.displayStatus ? true : false,
                heightReference: HeightReference.CLAMP_TO_GROUND,
                disableDepthTestDistance: 35000000,
                scale: 0.5,
              },
            });

            mapFeatures.push({
              id: layer.id,
              type: "6a",
              entity: billBoardPoint3dTileSet,
              intersection: false,
              show: layer.displayStatus ? true : false,
            });
            const tempProjects = appState;
            tempProjects.projects.forEach((project) => {
              if (project.id === layer.projectId) {
                updateItemInProject(
                  project.children,
                  layer.folderId,
                  layer.id,
                  tileset
                );
                loadedEntitties.push({
                  id: layer.id,
                  entity: tileset,
                });
                mapFeatures.push({
                  id: layer.id,
                  type: 6,
                  entity: tileset,
                  intersection: false,
                  show: layer.displayStatus ? true : false,
                });
              }
            });

            setAppState({
              ...appState,
              projects: tempProjects.projects,
            });

            tileset._asset["layer"] = layer;
            initialPosition3dTileset(tileset);
            if (flyTo) {
              flyToEntity(tileset);
              flyTo3dTilesetFunctions(layer.id);
            }
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      if (!pendingImagery.includes(layer.id)) {
        pendingImagery.push(layer.id);
      }
    }
  };

  const addOrVisibility = (id, show, layer, flyTo) => {
    const check = hasFeatureWithId(id);
    console.log("addOrVisibility", id, show, layer, flyTo, check, mapFeatures);
    if (check || layer.layerType == 2) {
      let newfiles = uploadFilesImagery.map((delItem) => {
        if (delItem.id === id) {
          delItem.show = show;
          if (flyTo && layer.layerType == 2) {
            delItem["flyTo"] = flyTo;
          } else if (!flyTo && layer.layerType == 2) {
            delItem["flyTo"] = false;
          }
        }
        return delItem;
      });
      setUploadFilesImagery(newfiles);
      updateMapFeatureShow(id, show);
    } else {
      if (layer.layerType == 6) {
        tilesetAddFns(layer, flyTo);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Collapse in={eulaBanner}>
        <Alert
          style={{
            position: "absolute",
            zIndex: 1201,
            width: "100%",
            bottom: 0,
          }}
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setEulaBanner(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          By using this you agree to the{" "}
          <Link target={"_blank"} to="/terms-of-service">
            terms of service
          </Link>{" "}
          and{" "}
          <a target={"_blank"} href="https://www.metageo.io/privacy-policy">
            privacy policy
          </a>
          .
        </Alert>
      </Collapse>
      {(pendingDownCount || imgLoader) && (
        <Alert
          style={{
            position: "absolute",
            zIndex: 1201,
            bottom: 0,
            right: 0,
            padding: pendingDownCount ? "3px 15px" : "3px 0 3px 18px",
            background: "white",
          }}
          severity="info"
          icon={
            <SyncIcon
              style={{
                animation: `3s linear 0s infinite reverse none running spin`,
                color: "#901da1",
              }}
            />
          }
        >
          {pendingDownCount ? `Zipping ${pendingDownCount} files` : null}
        </Alert>
      )}
      <CssBaseline />
      <Draggable
        cancel=".MuiSlider-root,.hue-picker,.github-picker,.chrome-picker,.sidenav-nodrag"
        grid={[50, 50]}
      >
        <div
          style={{
            top: openInfoDrawer ? 150 : 308,
            right: openInfoDrawer ? 410 : 57,
          }}
          className={`sidenav ${
            editSelected && sideNav ? " sidenavEpand" : ""
          }`}
        >
          <span>
            <div className="topbar">
              <h4>Create {layerTypeSelected}</h4>
              <a
                onTouchStart={() => {
                  setSideNav(false);
                }}
                class="closebtn"
                onClick={() => {
                  setSideNav(false);
                }}
              >
                &times;
              </a>
            </div>
            <span className="sidenav-nodrag">
              <div style={{ margin: "10px 15px 0px" }}>
                <strong style={{ marginRight: 5 }}>Name:</strong>
                <Input
                  // disabled={editEntity}
                  id={"vectorNameVal"}
                  onKeyPress={(e) => {
                    if (
                      e.key === "Enter" &&
                      (positions.length || points.length)
                    ) {
                      createLayerHandler(
                        document.getElementById("vectorNameVal").value
                      );
                    }
                  }}
                  style={{ fontSize: 14 }}
                />
              </div>
              <div
                style={{
                  margin:
                    layerTypeSelected == "point"
                      ? "0px 15px 0px"
                      : "0px 15px 10px",
                }}
              >
                <strong style={{ marginRight: 5 }}>Unit:</strong>
                <Select
                  style={{ fontSize: 14 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={measureUnit.length}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  onChange={(a, b, c) => {
                    let lengthCoe;
                    let areaCoe;
                    if (b.props.value == "m") {
                      lengthCoe = 1;
                      areaCoe = 1;
                    }
                    if (b.props.value == "km") {
                      lengthCoe = 0.001;
                      areaCoe = 1000000;
                    }
                    if (b.props.value == "miles") {
                      lengthCoe = 0.00062150403;
                      areaCoe = 2590000;
                    }
                    if (b.props.value == "ft") {
                      lengthCoe = 3.28084;
                      areaCoe = 0.0929031299;
                    }
                    if (b.props.value == "acres") {
                      lengthCoe = 3.28084;
                      areaCoe = 4047;
                    }
                    if (b.props.value == "hectares") {
                      lengthCoe = 3.28084;
                      areaCoe = 10000;
                    }
                    setMeasureUnit({
                      length: b.props.value,
                      area:
                        b.props.value == "acres" || b.props.value == "hectares"
                          ? b.props.value
                          : b.props.value + "²",
                      lengthCoe: lengthCoe,
                      areaCoe: areaCoe,
                    });
                  }}
                >
                  <MenuItem value="m">Meters</MenuItem>
                  <MenuItem value="km">Kilometers</MenuItem>
                  <MenuItem value="miles">Miles</MenuItem>
                  <MenuItem value="ft">Feet</MenuItem>
                  <MenuItem value="acres">Acres</MenuItem>
                  <MenuItem value="hectares">Hectares</MenuItem>
                </Select>
              </div>
              {layerTypeSelected == "polygon" ? (
                <>
                  <div style={{ margin: "0px 15px 0px" }}>
                    <strong style={{ marginRight: 5 }}>Fill Color:</strong>
                    <GithubPicker
                      colors={[
                        "#B80000",
                        "#DB3E00",
                        "#FCCB00",
                        "#008B02",
                        "#006B76",
                        "#1273DE",
                        "#004DCF",
                        "#5300EB",
                      ]}
                      width={212}
                      color={polygonBackground}
                      onChangeComplete={handleChangeComplete}
                      disableAlpha={true}
                    />
                    <CustomPicker
                      color={polygonBackground}
                      onChangeComplete={handleChangeComplete}
                    />
                  </div>
                </>
              ) : null}
              {layerTypeSelected == "polygon" ? (
                <>
                  <div style={{ margin: "0px 15px 0px" }}>
                    <strong style={{ marginRight: 5 }}>Transparency:</strong>
                    <Slider
                      value={fillTrans}
                      onChangeCommitted={(event, value) => {
                        const setColor =
                          "rgba(" +
                          polygonBackground.r +
                          "," +
                          polygonBackground.g +
                          "," +
                          polygonBackground.b +
                          "," +
                          value +
                          ")";
                        let color = polygonBackground;
                        color["setColor"] = setColor;
                        setPolygonBackground(color);
                        setFillTrans(value);
                      }}
                      min={0}
                      step={0.1}
                      max={1}
                      style={{ width: "220px" }}
                      valueLabelDisplay="auto"
                      aria-labelledby="continuous-slider"
                    />
                  </div>
                </>
              ) : null}
              {layerTypeSelected == "line" || layerTypeSelected == "polygon" ? (
                <>
                  <div style={{ margin: "0px 15px 0px" }}>
                    <strong style={{ marginRight: 5 }}>Line Color:</strong>
                    <GithubPicker
                      colors={[
                        "#B80000",
                        "#DB3E00",
                        "#FCCB00",
                        "#008B02",
                        "#006B76",
                        "#1273DE",
                        "#004DCF",
                        "#5300EB",
                      ]}
                      width={212}
                      color={lineColor}
                      onChangeComplete={handleChangeCompleteLine}
                      disableAlpha={true}
                    />
                    <CustomPicker
                      color={lineColor}
                      onChangeComplete={handleChangeCompleteLine}
                    />
                  </div>
                  <div style={{ margin: "0px 15px 0px" }}>
                    <strong style={{ marginRight: 5 }}>Line Thickness:</strong>
                    <Slider
                      value={lineWidth}
                      onChangeCommitted={(event, value) => {
                        setLineWidth(value);
                      }}
                      min={1}
                      step={1}
                      max={10}
                      valueLabelDisplay="auto"
                      aria-labelledby="continuous-slider"
                      style={{ width: "220px" }}
                    />
                  </div>
                </>
              ) : null}
              <div style={{ margin: "0px 15px 0px" }}>
                <strong style={{ marginRight: 5 }}>Elevation:</strong>
                <Select
                  style={{ fontSize: 14 }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={altMode}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  onChange={(a, b, c) => {
                    setAltMode(b.props.value);
                  }}
                >
                  <MenuItem value={"absolute"} key={1}>
                    Absolute
                  </MenuItem>
                  <MenuItem value={"clampToGround"} key={2}>
                    Clamp To Ground
                  </MenuItem>
                </Select>
              </div>
              {/* {editEntity ? null : ( */}
              <div style={{ margin: "0px 15px 0px" }}>
                <strong style={{ marginRight: 5 }}>Attach Links:</strong>
                <Autocomplete
                  multiple
                  onChange={(a, b, c) => {
                    if (b.length < 6) {
                      if (
                        validURL(b[b.length - 1]) ||
                        c == "remove-option" ||
                        c == "clear"
                      ) {
                        setInfoLayerLinks(b);
                      } else {
                        errorDisplay("error", "Please add a valid link");
                      }
                    } else {
                      errorDisplay("error", "Maximum 5 Links can be added");
                    }
                  }}
                  onBlur={(e) => {
                    if (
                      validURL(e.target.value) &&
                      !infoLayerLinks.includes(e.target.value)
                    ) {
                      setInfoLayerLinks([
                        ...infoLayerLinks,
                        ...[e.target.value],
                      ]);
                    } else {
                      // errorDisplay("error", "Please add a valid link");
                    }
                  }}
                  id="links-filled"
                  options={[].map((option) => option.title)}
                  freeSolo
                  size="small"
                  value={infoLayerLinks ? infoLayerLinks : []}
                  renderTags={(value, getLinkProps) => {
                    return value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getLinkProps({ index })}
                      />
                    ));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Links"
                      placeholder="Press enter to add link"
                    />
                  )}
                />
              </div>
              {/* )} */}
              {layerTypeSelected == "point" ? (
                <span className="editStylesIcon">
                  <Radio.Group
                    size="large"
                    onChange={(event) => {
                      let preUrl =
                        API_URL == "https://devapi.metageo.io/"
                          ? "https://dev.metageo.io/images/"
                          : "https://map.metageo.io/images/";
                      setMarkerURL(preUrl + event.target.value + ".png");
                    }}
                  >
                    <IconStyles />
                  </Radio.Group>
                </span>
              ) : null}
              {editEntity ? null : (
                <Button
                  onClick={() => {
                    createLayerHandler(
                      document.getElementById("vectorNameVal").value
                    );
                  }}
                  disabled={positions.length || points.length ? false : true}
                  type="submit"
                  variant="contained"
                  className="btn-block btnTheme buttonSaveVector"
                >
                  Save
                </Button>
              )}
            </span>
          </span>
        </div>
      </Draggable>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Divider />
        <Box className="Relative">
          <Box className="MarginFixtop PaddingFixtop">
            <ListItem
              button
              onClick={!open ? handleDrawerOpen : handleDrawerClose}
            >
              <ListItemIcon>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              </ListItemIcon>
              <ListItemText primary="Menu" />
            </ListItem>

            <ListItem button onClick={() => Sabar("layers")}>
              <Tooltip title="Layers" placement="right">
                <ListItemIcon>
                  <LayersIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Layers" />
            </ListItem>
            {newUploadFiles.length ? (
              <ListItem button onClick={() => Sabar("upload")}>
                <Tooltip title="Uploads" placement="right">
                  <ListItemIcon>
                    <CloudUploadIcon />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Uploads" />
              </ListItem>
            ) : null}

            {positions.length ? (
              <ListItem button onClick={() => setSideNav(true)}>
                <Tooltip title="Paint" placement="right">
                  <ListItemIcon>
                    <ColorLensOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Paint" />
              </ListItem>
            ) : null}
          </Box>
        </Box>
      </Drawer>
      {open ? (
        <Box
          className={
            open
              ? "Text-right Text-right-visible"
              : "Text-right Text-right-invisible"
          }
        >
          <IconButton onClick={Sabar2}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </Box>
      ) : null}
      <Box id="myDIV" style={{ overflowX: "auto" }}>
        {section == "search" && (
          <Box className="SearchAside">
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
            />
            <SearchIcon className="SearchIconAside" />
          </Box>
        )}
        {section == "upload" && (
          <>
            <MetaUploader
              backLayerPanel={() => {
                Sabar("layers");
              }}
              openAccordiansUploader={openAccordiansUploader}
              accordianChange={(item) => {
                if (openAccordiansUploader.includes(item)) {
                  openAccordiansUploader = openAccordiansUploader.filter(
                    (fil) => fil !== item
                  );
                } else {
                  openAccordiansUploader.push(item);
                }
              }}
              accordianChange1={(item) => {
                if (!openAccordiansUploader.includes(item)) {
                  openAccordiansUploader.push(item);
                }
              }}
              stateChecker={() => {
                if (currentSection == "upload") {
                  Sabar("");
                  Sabar("upload");
                }
              }}
              failedUploads={failedUploads}
              doneUploads={doneUploads}
              setDoneUploads={setDoneUploads}
              appState={appState}
              allFolders={allFolders}
              filePath={filePath}
              newUploadFiles={newUploadFiles}
              setNewUploadfiles={(newfiles) => {
                setNewUploadfiles(newfiles);
              }}
              updateFunction={(layerResponse, dataObject, newFileName) => {
                let appStateTemp = appState;
                appStateTemp.projects.forEach((project) => {
                  if (project.id === dataObject.projectId) {
                    insertItemInProject(
                      project.children,
                      dataObject.parentId,
                      layerResponse?.data?.layers[0]
                    );
                    if (
                      dataObject.layerType == 4 &&
                      !newFileName.includes(".zip") &&
                      !newFileName.includes(".dxf") &&
                      !newFileName.includes(".json") &&
                      !newFileName.includes(".gpx") &&
                      !newFileName.includes(".geojson")
                    ) {
                      allLayers.push({
                        id: layerResponse?.data?.layers[0].id,
                        name: newFileName,
                        feature_attributes: [],
                        feature_media: [],
                        folderId: layerResponse?.data?.layers[0].folderId,
                        folder_id: layerResponse?.data?.layers[0].folderId,
                        projectId: dataObject.projectId,
                      });
                      let oldImagery = uploadFilesImagery;
                      oldImagery.push({
                        type: 6,
                        id: layerResponse?.data?.layers[0].id,
                        projectId: dataObject.projectId,
                        folderId: layerResponse?.data?.layers[0].folderId,
                        url: layerResponse?.data?.layers[0].signedUrl,
                        show: layerResponse?.data?.layers[0].displayStatus
                          ? true
                          : false,
                      });
                      setUploadFilesImagery(oldImagery);
                      // pendingImagery.push(layerResponse?.data?.layers[0].id);
                    } else {
                      pendingImagery.push(layerResponse?.data?.layers[0].id);
                    }
                  }
                });
                setAppState({
                  ...appStateTemp,
                  projectId: cloneprojid,
                  folderId: clonefolderid,
                });
              }}
            />
          </>
        )}
        {section == "support" && <UserSupport />}
        {/* Info: layers section contain all projects and its items*/}
        {section == "layers" && (
          <span className="layerBox">
            {secureLocalStorage.getItem("user") !== "share" ? (
              <Progress
                strokeColor={
                  ((
                    (Number(secureLocalStorage.getItem("storageCapacity")) -
                      Number(secureLocalStorage.getItem("availableStorage"))) /
                    1024
                  ).toFixed(0) /
                    (
                      Number(secureLocalStorage.getItem("storageCapacity")) /
                      1024
                    ).toFixed(0)) *
                    100 <
                  90
                    ? "#901da1"
                    : "red"
                }
                className="storagePrrogress"
                strokeWidth={25}
                format={(percent) =>
                  `${(
                    (Number(secureLocalStorage.getItem("storageCapacity")) -
                      Number(secureLocalStorage.getItem("availableStorage"))) /
                    1024
                  ).toFixed(2)} GB of ${(
                    Number(secureLocalStorage.getItem("storageCapacity")) / 1024
                  ).toFixed(2)} GB`
                }
                percent={100}
                success={{
                  percent:
                    ((
                      (Number(secureLocalStorage.getItem("storageCapacity")) -
                        Number(
                          secureLocalStorage.getItem("availableStorage")
                        )) /
                      1024
                    ).toFixed(2) /
                      (
                        Number(secureLocalStorage.getItem("storageCapacity")) /
                        1024
                      ).toFixed(2)) *
                    100,
                }}
              />
            ) : null}

            {/* <RenderActions /> */}
            {/* <RenderActionMenu /> */}
            <FileActionMenu
              pushPendingDownload={(itemDwd) => {
                if (!pendingShareDownloads.includes(itemDwd)) {
                  pendingShareDownloads.push(itemDwd);
                  setPendingDownCount(pendingShareDownloads.length);
                }
              }}
              updateMapFeatures={updateMapFeatures}
              pendingUpdate={(id) => {
                pendingVectors = pendingVectors.filter((item) => item != id);
                pendingImagery = pendingImagery.filter((item) => item != id);
              }}
              actionState={actionState}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              setAppState={setAppState}
              setDisplayItems={setDisplayItems}
              setFilePath={setFilePath}
              findPath={findPath}
              setSection={setSection}
              setCurrentSection={(curr) => {
                currentSection = curr;
              }}
              iconStyle={iconStyle}
              displayItems={displayItems}
              setAddWMSDialog={setAddWMSDialog}
              dispatch={dispatch}
              deleteFolder={deleteFolder}
              updateLocalStorage={updateLocalStorage}
              LOADING_MESSAGE={LOADING_MESSAGE}
              errorDisplay={errorDisplay}
              authHeader={authHeader}
              appState={appState}
              deleteLayer={deleteLayer}
              setRightDrawer={setRightDrawer}
              handleInfoDrawerClose={handleInfoDrawerClose}
              removeItemFromProject={removeItemFromProject}
              setUploadFilesImagery={setUploadFilesImagery}
              //mapRef={mapRef}
              viewer={viewer}
              uploadFilesImagery={uploadFilesImagery}
              fileAccordianOnDoubleClick={fileAccordianOnDoubleClick}
            />

            <div
              style={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "right",
              }}
            >
              <ToggleButton
                className="mobileBack"
                value="check"
                onClick={Sabar2}
                // selected={selected}
                // onChange={() => {
                //   setSelected(!selected);
                // }}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </ToggleButton>
            </div>
            <input
              defaultValue={searchVal}
              id="layerPanelSearch"
              style={{
                height: "28px",
                fontSize: "15px",
                width: "100%",
                marginBottom: "10px",
                paddingLeft: "5px",
              }}
              placeholder="Search by name or tags"
              onChange={(e) => debouncedSetState(e.target.value)}
            />
            {searchVal && (
              <CloseIcon
                onClick={() => {
                  debouncedSetState("");
                  document.getElementById("layerPanelSearch").value = "";
                }}
                className="resetSearch"
              />
            )}
            <Box className="TitleSection">
              <h3>Projects</h3>
              <div>
              <Tooltip title={"Sort by Name"} placement="top">
                  <IconButton
                    onClick={() => {
                      localStorage.setItem("sortType", sortType == 1 ? 2 : 1);
                      setSortType(sortType == 1 ? 2 : 1);
                    }}
                  >
                    {sortType == 1 ? (
                      <SortIcon2
                        style={{
                          height: 33,
                          fill: sortType == 1 ? "#901da1" : "black",
                        }}
                      />
                    ) : (
                      <SortIcon1
                        style={{
                          height: 33,
                          fill: sortType == 2 ? "#901da1" : "black",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Sort by Date"} placement="top">
                  <IconButton
                    onClick={() => {
                      localStorage.setItem("sortType", sortType == 3 ? 4 : 3);
                      setSortType(sortType == 3 ? 4 : 3);
                    }}
                  >
                    {sortType == 3 ? (
                      <SortIcon4
                        style={{
                          height: 33,
                          fill: sortType == 3 ? "#901da1" : "black",
                        }}
                      />
                    ) : (
                      <SortIcon3
                        style={{
                          height: 33,
                          fill: sortType == 4 ? "#901da1" : "black",
                        }}
                      />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Refresh project"} placement="top">
                  <IconButton
                    onClick={() => {
                      dispatch({ type: LOADING_MESSAGE, isLoading: true });
                      setAppState({ ...appState, reloadProjects: true });
                    }}
                  >
                    <RefreshIcon style={{ fontSize: 33, color: "#901da1" }} />
                  </IconButton>
                </Tooltip>
                {secureLocalStorage.getItem("user") !== "share" ? (
                  <Tooltip title={"Create / Upload"} placement="top">
                    <IconButton
                      onClick={(event) => {
                        try {
                          event.stopPropagation();

                          setAnchorElProjBtn(event.currentTarget);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      <AddCircleOutlineIcon
                        style={{ fontSize: 33, color: "#901da1" }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : null}

                <ProjectActionMenu
                  setDisplayItems={setDisplayItems}
                  setFilePath={setFilePath}
                  findPath={findPath}
                  setSection={setSection}
                  setCurrentSection={(curr) => {
                    currentSection = curr;
                  }}
                  iconStyle={iconStyle}
                  displayItems={displayItems}
                  dispatch={dispatch}
                  appState={appState}
                  SET_MESSAGE={SET_MESSAGE}
                  anchorElProjBtn={anchorElProjBtn}
                  setAnchorElProjBtn={setAnchorElProjBtn}
                />
              </div>
            </Box>
            {/* <LayerComponent classes={classes} appState={appState} displayItems={displayItems} isOrganizationAdmin={isOrganizationAdmin} actionState={actionState} setAppState={setAppState} setDisplayItems={setDisplayItems} setFilePath={setFilePath} deleteProjectFunction={deleteProjectFunction} /> */}
            {appState.projects == [] && <span>loading...</span>}
            <ReactSortable
              style={{
                // backgroundColor:"red",
                marginLeft: "-21px",
                marginRight: "-17px",
              }}
              handle={".handle"}
              id={`projectList`}
              list={appState.projects}
              setList={(newState, sortable, store) => {
                if (
                  store.dragging &&
                  store.dragging.props &&
                  JSON.stringify(store.dragging.props.list) !==
                    JSON.stringify(newState)
                ) {
                  setTimeout(() => {
                    setAppState({ ...appState, projects: newState });
                  }, 0);
                }
              }}
              {...sortableOptions}
            >
              {appState.projectsFilter
                ? sortFnc(appState.projectsFilter, sortType)?.map(
                    (project, blockIndex) => {
                      // console.log("project", project);
                      // return (
                      //   <RenderChildren
                      //     key={`Project_${project.id}`}
                      //     item={project}
                      //     blockIndex={[blockIndex]}
                      //     project={project}
                      //   />
                      // );

                      return (
                        <RenderChildrenComp
                          sortType={sortType}
                          addOrVisibility={addOrVisibility}
                          searchVal={searchVal}
                          updateMapFeatureShow={updateMapFeatureShow}
                          setInfoLayerTags={setInfoLayerTags}
                          setInfoLayerDes={setInfoLayerDes}
                          setDateInfoPanel={setDateInfoPanel}
                          key={`Project_${project.id}`}
                          // infoIconHandler={infoIconHandler}
                          item={project}
                          blockIndex={[blockIndex]}
                          project={project}
                          openAccordians1={openAccordians1}
                          classes={classes}
                          handleClickCheck={handleClickCheck}
                          device={device}
                          projectAccordianOnClick={projectAccordianOnClick}
                          appState={appState}
                          setSection={setSection}
                          setCurrentSection={(curr) => {
                            currentSection = curr;
                          }}
                          actionStateProj={actionStateProj}
                          setAnchorElProj={setAnchorElProj}
                          anchorElProj={anchorElProj}
                          deleteProjectFunction={deleteProjectFunction}
                          setFilePath={setFilePath}
                          setDisplayItems={setDisplayItems}
                          displayItems={displayItems}
                          setAppState={setAppState}
                          iconStyle={iconStyle}
                          sortableOptions={sortableOptions}
                          allFolders={allFolders}
                          openAccordians2={openAccordians2}
                          handleClickCheckFolder={handleClickCheckFolder}
                          folderAccordianOnClick={folderAccordianOnClick}
                          layerPanelSelected={layerPanelSelected}
                          updateFolderShow={updateFolderShow}
                          //mapRef={mapRef.current.cesiumElement}
                          viewer={viewer}
                          setMediaAccordian={setMediaAccordian}
                          mediaAccordian={mediaAccordian}
                          uploadFilesImagery={uploadFilesImagery}
                          setUploadFilesImagery={setUploadFilesImagery}
                          actionState={actionState}
                          setAnchorEl={setAnchorEl}
                          fileAccordianOnDoubleClick={
                            fileAccordianOnDoubleClick
                          }
                          setLayerPanelSelected={setLayerPanelSelected}
                          updateLayerShow={updateLayerShow}
                          selectIcon={selectIcon}
                          selectName={selectName}
                          setInfoLayerSelected={setInfoLayerSelected}
                          setInfoLayerSelectedFeatureId={
                            setInfoLayerSelectedFeatureId
                          }
                          setInfoLayerSelectedId={setInfoLayerSelectedId}
                          getFeatureMediaFunction={getFeatureMediaFunction}
                          setInfoLayerSelectedMedia={setInfoLayerSelectedMedia}
                          setInfoLayerSelectedWMSName={
                            setInfoLayerSelectedWMSName
                          }
                          setInfoLayerSelectedName={setInfoLayerSelectedName}
                          setInfoLayerProperties={setInfoLayerProperties}
                          setRightDrawer={setRightDrawer}
                          handleInfoDrawerOpen={handleInfoDrawerOpen}
                          setDateCreated={(date) => {
                            setDateCreated(date);
                          }}
                        />
                      );
                    }
                  )
                : sortFnc(appState.projects, sortType)?.map(
                    (project, blockIndex) => {
                      // console.log("project", project);
                      // return (
                      //   <RenderChildren
                      //     key={`Project_${project.id}`}
                      //     item={project}
                      //     blockIndex={[blockIndex]}
                      //     project={project}
                      //   />
                      // );

                      return (
                        <RenderChildrenComp
                          sortType={sortType}
                          addOrVisibility={addOrVisibility}
                          searchVal={searchVal}
                          updateMapFeatureShow={updateMapFeatureShow}
                          setInfoLayerTags={setInfoLayerTags}
                          setInfoLayerDes={setInfoLayerDes}
                          setDateInfoPanel={setDateInfoPanel}
                          key={`Project_${project.id}`}
                          // infoIconHandler={infoIconHandler}
                          item={project}
                          blockIndex={[blockIndex]}
                          project={project}
                          openAccordians1={openAccordians1}
                          classes={classes}
                          handleClickCheck={handleClickCheck}
                          device={device}
                          projectAccordianOnClick={projectAccordianOnClick}
                          appState={appState}
                          setSection={setSection}
                          setCurrentSection={(curr) => {
                            currentSection = curr;
                          }}
                          actionStateProj={actionStateProj}
                          setAnchorElProj={setAnchorElProj}
                          anchorElProj={anchorElProj}
                          deleteProjectFunction={deleteProjectFunction}
                          setFilePath={setFilePath}
                          setDisplayItems={setDisplayItems}
                          displayItems={displayItems}
                          setAppState={setAppState}
                          iconStyle={iconStyle}
                          sortableOptions={sortableOptions}
                          allFolders={allFolders}
                          openAccordians2={openAccordians2}
                          handleClickCheckFolder={handleClickCheckFolder}
                          folderAccordianOnClick={folderAccordianOnClick}
                          layerPanelSelected={layerPanelSelected}
                          updateFolderShow={updateFolderShow}
                          //mapRef={mapRef.current.cesiumElement}
                          viewer={viewer}
                          setMediaAccordian={setMediaAccordian}
                          mediaAccordian={mediaAccordian}
                          uploadFilesImagery={uploadFilesImagery}
                          setUploadFilesImagery={setUploadFilesImagery}
                          actionState={actionState}
                          setAnchorEl={setAnchorEl}
                          fileAccordianOnDoubleClick={
                            fileAccordianOnDoubleClick
                          }
                          setLayerPanelSelected={setLayerPanelSelected}
                          updateLayerShow={updateLayerShow}
                          selectIcon={selectIcon}
                          selectName={selectName}
                          setInfoLayerSelected={setInfoLayerSelected}
                          setInfoLayerSelectedFeatureId={
                            setInfoLayerSelectedFeatureId
                          }
                          setInfoLayerSelectedId={setInfoLayerSelectedId}
                          getFeatureMediaFunction={getFeatureMediaFunction}
                          setInfoLayerSelectedMedia={setInfoLayerSelectedMedia}
                          setInfoLayerSelectedWMSName={
                            setInfoLayerSelectedWMSName
                          }
                          setInfoLayerSelectedName={setInfoLayerSelectedName}
                          setInfoLayerProperties={setInfoLayerProperties}
                          setRightDrawer={setRightDrawer}
                          handleInfoDrawerOpen={handleInfoDrawerOpen}
                          setDateCreated={(date) => {
                            setDateCreated(date);
                          }}
                        />
                      );
                    }
                  )}
            </ReactSortable>
            {/* <RenderMoveMenu/> */}
            <MoveMenu
              displayItems={displayItems}
              setDisplayItems={setDisplayItems}
              allFolders={allFolders}
              moveLayer={moveLayer}
              appState={appState}
              errorDisplay={errorDisplay}
              setAppState={setAppState}
              dispatch={dispatch}
              LOADING_MESSAGE={LOADING_MESSAGE}
              moveFolder={moveFolder}
            />
          </span>
        )}
        {/* Info: layers section End*/}
      </Box>
      <InfoPanelComp
        editEntity={editEntity}
        setEditEntity={setEditEntity}
        createLayerHandler={createLayerHandler}
        handleDateCollectedDrawer={handleDateCollectedDrawer}
        dateCollectedDrawer={dateCollectedDrawer}
        setDateCollectedDrawer={setDateCollectedDrawer}
        infoPanelDateCollectedHandler={infoPanelDateCollectedHandler}
        dateInfoPanel={dateInfoPanel}
        setLineWidth={setLineWidth}
        setPolygonBackground={setPolygonBackground}
        setFillTrans={setFillTrans}
        setLineColor={setLineColor}
        editVector={(type, cords, ent) => {
          if (!type) {
            setMeasureSelected(false);
            initialMeasurePoints = [];
            initialMeasurePositions = [];
            setMeasurePositions([]);
            setMeasurePoints([]);
            setEditSelected(false);
            setEditSelectedDouble(false);
            //const viewer = mapRef.current.cesiumElement;
            let ellipsoid = viewer.scene.globe.ellipsoid;
            viewer.scene.selectionIndicator = false;
            initialPoints = [];
            initialPositions = [];
            initialPositionsNew = [];
            setPositions([]);
            deleteDragPoints();
            setPoints([]);
            setLayerTypeSelected(type);
            setSideNav(false);
            setEditEntity(null);
            return;
          }
          let selected = allLayers.filter((layer) => layer.id == vectorLayerId);
          let templinks =
            getNullFeature(selected[0].feature_attributes) == -1
              ? []
              : selected[0].feature_attributes[
                  getNullFeature(selected[0].feature_attributes)
                ].links;
          setInfoLayerLinks(templinks);
          editCheck = true;
          setEditEntity(ent);
          if (type == "point")
            setMarkerURL(
              ent._entityCollection._entities._array[0]._billboard._image._value
                ._url
            );
          setMeasureSelected(false);
          initialMeasurePoints = [];
          initialMeasurePositions = [];
          setMeasurePositions([]);
          setMeasurePoints([]);
          setEditSelected(true);
          setEditSelectedDouble(true);
          //const viewer = mapRef.current.cesiumElement;
          let ellipsoid = viewer.scene.globe.ellipsoid;
          viewer.scene.selectionIndicator = false;
          initialPoints = [];
          initialPositions = [];
          initialPositionsNew = [];
          setPositions([]);
          deleteDragPoints();
          setPoints([]);
          setLayerTypeSelected(type);
          setSideNav(true);
          cords.forEach((pos) => {
            let cartesian = pos;
            const cartographic = ellipsoid.cartesianToCartographic(cartesian);
            let longitudeString = CesMath.toDegrees(
              cartographic.longitude
            ).toFixed(15);
            longitudeString = Number(longitudeString);
            let latitudeString = CesMath.toDegrees(
              cartographic.latitude
            ).toFixed(15);
            latitudeString = Number(latitudeString);
            let lineCords = Cartographic.fromCartesian(cartesian);
            let lineCords2 = new Cartesian3.fromRadians(
              lineCords.longitude,
              lineCords.latitude,
              lineCords.height
            );
            initialPositionsNew.push(lineCords2);
            initialPositions.push(radiansToDegrees(lineCords.longitude));
            initialPositions.push(radiansToDegrees(lineCords.latitude));
            initialPoints.push({
              x: radiansToDegrees(lineCords.longitude),
              y: radiansToDegrees(lineCords.latitude),
              z: lineCords.height,
            });
            setPoints(initialPoints);
            const tempinitialPositionsNew = [...initialPositionsNew];
            setPositions(tempinitialPositionsNew);
            // setEditSelectedDouble(false);
            routeEntity = createWaypoint(pos, viewer);
            allEntitiesDrag.push(routeEntity);
          });
        }}
        editableVector={editableVector}
        // updateAttachment={(state,layerId)=>{
        //   updateItemInProjectAttachment(
        //     appState.projects.filter((filItem) => filItem.id == layer.projectId)[0]
        //       .children,
        //     layer.folderId,
        //     layerId,
        //     entity
        //   );
        // }}
        classes={classes}
        handleInfoDrawerClose={handleInfoDrawerClose}
        openInfoDrawer={openInfoDrawer}
        infoLayerDes={infoLayerDes}
        fillColor={fillColor}
        strokeColor={strokeColor}
        strokeTrans={strokeTrans}
        fillTrans={fillTrans}
        infoLayerTags={infoLayerTags}
        infoLayerLinks={infoLayerLinks}
        infoLayerSelected={infoLayerSelected}
        pointCloudSize={pointCloudSize}
        tileSetHeight={tileSetHeight}
        setTileSetHeight={setTileSetHeight}
        tileSetHeightDefault={tileSetHeightDefault}
        setTileSetHeightDefault={setTileSetHeightDefault}
        styleMode={styleMode}
        styleClass={styleClass}
        selectedColorPallet={selectedColorPallet}
        heightSliderVal={heightSliderVal}
        updatePointCloudLayerAttributes={updatePointCloudLayerAttributes}
        infoLayerSelectedId={infoLayerSelectedId}
        infoLayerSelectedFeatureId={infoLayerSelectedFeatureId}
        selectedPrimitive={selectedPrimitive}
        errorDisplay={errorDisplay}
        updateLayerAttributesFolder={updateLayerAttributesFolder}
        updateAllLayer={(id, item) => {
          allLayers = allLayers.map((thisInfo) => {
            if (thisInfo.id == id) {
              if (thisInfo.feature_attributes.length) {
                if (infoLayerSelectedFeatureId) {
                  let isVector = "" + infoLayerSelectedFeatureId;
                  isVector = isVector.replaceAll(",", "");
                  let index = thisInfo.feature_attributes.findIndex(
                    (x) => x.featureId?.replaceAll(",", "") === isVector
                  );
                  if (index < 0) {
                    thisInfo.feature_attributes.push({
                      featureId: isVector,
                      description: item.description,
                      links: item.links,
                      tags: item.tags,
                    });
                  } else {
                    thisInfo.feature_attributes[index].description =
                      item.description;
                    thisInfo.feature_attributes[index].links = item.links;
                    thisInfo.feature_attributes[index].tags = item.tags;
                  }
                } else {
                  if (thisInfo.feature_attributes.length == 1) {
                    thisInfo.feature_attributes[0].description =
                      item.description;
                    thisInfo.feature_attributes[0].links = item.links;
                    thisInfo.feature_attributes[0].tags = item.tags;
                  } else {
                    thisInfo.feature_attributes[
                      getNullFeature(thisInfo.feature_attributes)
                    ].description = item.description;
                    thisInfo.feature_attributes[
                      getNullFeature(thisInfo.feature_attributes)
                    ].links = item.links;
                    thisInfo.feature_attributes[
                      getNullFeature(thisInfo.feature_attributes)
                    ].tags = item.tags;
                  }
                }
              } else {
                let isVector = "" + infoLayerSelectedFeatureId;
                isVector = isVector.replaceAll(",", "");
                thisInfo.feature_attributes = [
                  {
                    featureId: isVector,
                    description: item.description,
                    links: item.links,
                    tags: item.tags,
                  },
                ];
              }
            }
            return thisInfo;
          });
          infoLayerId = null;
          infoLayerFeatureId = null;
        }}
        updateAllLayerMedia={(id, mediaId) => {
          allLayers = allLayers.map((thisInfo) => {
            if (thisInfo.id == id) {
              thisInfo.feature_media = thisInfo.feature_media.filter(
                (item) => item.id != mediaId
              );
            }
            return thisInfo;
          });
          infoLayerId = null;
          infoLayerFeatureId = null;
        }}
        updateLayerAttributes={updateLayerAttributes}
        infoLayerSelectedName={infoLayerSelectedName}
        dateCreated={dateCreated}
        // setInfoLayerDes={setInfoLayerDes}
        setInfoLayerTags={setInfoLayerTags}
        mediaAccordian={mediaAccordian}
        setMediaAccordian={setMediaAccordian}
        infoLayerSelectedMedia={infoLayerSelectedMedia}
        setUploadMediaDrawer={setUploadMediaDrawer}
        setImageViewer={setImageViewer}
        dispatch={dispatch}
        deleteMedia={deleteMedia}
        setInfoLayerSelectedMedia={setInfoLayerSelectedMedia}
        updateLocalStorage={updateLocalStorage}
        infoLayerSelectedWMSName={infoLayerSelectedWMSName}
        getUserId={getUserId}
        updateVectorStyles={updateVectorStyles}
        uploadFilesImagery={uploadFilesImagery}
        layerPanelSelected={layerPanelSelected}
        setUploadFilesImagery={setUploadFilesImagery}
        setVectorEditted={setVectorEditted}
        vectorEditted={vectorEditted}
        setFillColor={setFillColor}
        setPointCloudSize={setPointCloudSize}
        changeClassificationStyleMode={changeClassificationStyleMode}
        chkPointCloudClassStyle={chkPointCloudClassStyle}
        setHeightSliderVal={setHeightSliderVal}
        colorPalletChangeHandler={colorPalletChangeHandler}
        heightColorList={heightColorList}
        setIsShownClassList={setIsShownClassList}
        handleChangeSwitch={handleChangeSwitch}
        isShownClassList={isShownClassList}
        styleClassDeleteHandler={styleClassDeleteHandler}
        styleClassEdit={styleClassEdit}
        closeStyleClassHandler={closeStyleClassHandler}
        onChangeClassEditColorHandler={onChangeClassEditColorHandler}
        setStrokeColor={setStrokeColor}
        setStrokeTrans={setStrokeTrans}
        handleSelectedIconValue={handleSelectedIconValue}
        ICON_URL={ICON_URL}
        uploadFiles={uploadFiles}
        setUploadfiles={setUploadfiles}
        infoLayerProperties={infoLayerProperties}
        linkAccordian={linkAccordian}
        setLinkAccordian={setLinkAccordian}
        attrAccordian={attrAccordian}
        setAttrAccordian={setAttrAccordian}
        validURL={validURL}
        setInfoLayerLinks={setInfoLayerLinks}
        getYoutubeId={getYoutubeId}
        showPointCloudStyleChkBx={showPointCloudStyleChkBx}
        minzMaxz={minzMaxz}
        showTerrain={showTerrain}
        selectedAltitudeMode={selectedAltitudeMode}
        setSelectedAltitudeMode={setSelectedAltitudeMode}
        setSelectedAltitudeModeKml={setSelectedAltitudeModeKml}
        selectedAltitudeModeKml={selectedAltitudeModeKml}
        setHeight3DTileset={setHeight3DTileset}
        styleClassEditHandler={styleClassEditHandler}
        rayCastCalculation={rayCastCalculation}
        rayCastLoader={rayCastLoader}
      />

      <DialogComp
        newMediaUploadFiles={newMediaUploadFiles}
        deleteDrawer={deleteDrawer}
        dateCollectedDrawer={dateCollectedDrawer}
        Transition={Transition}
        handleCloseDeleteDialog={handleCloseDeleteDialog}
        handleDateCollectedDrawer={handleDateCollectedDrawer}
        displayItems={displayItems}
        handleUploadClose={handleUploadClose}
        setFolderfiles={setFolderfiles}
        setNewUploadfiles={setNewUploadfiles}
        errorDisplay={errorDisplay}
        localStorage={localStorage}
        newUploadFiles={newUploadFiles}
        handleDrawerUploadOk={handleDrawerUploadOk}
        uploadMediaDrawer={uploadMediaDrawer}
        handleMediaUploadClose={handleMediaUploadClose}
        classes={classes}
        infoLayerSelectedMedia={infoLayerSelectedMedia}
        dispatch={dispatch}
        handleMediaFilesChange={handleMediaFilesChange}
        handleMediaDrawerUploadOk={handleMediaDrawerUploadOk}
        handleCloseFolderDialog={handleCloseFolderDialog}
        handleSharingDialog={handleSharingDialog}
        handleSubmitFolder={handleSubmitFolder}
        handleSubmitEdit={handleSubmitEdit}
        handleCloseEditDialog={handleCloseEditDialog}
        handleCloseProjectDialog={handleCloseProjectDialog}
        handleSubmitProject={handleSubmitProject}
        tipsDialog={tipsDialog}
        handleCloseTipsDialog={handleCloseTipsDialog}
        LOADING_MESSAGE={LOADING_MESSAGE}
        SET_MESSAGE={SET_MESSAGE}
        disableTips={disableTips}
        eulaDialog={eulaDialog}
        handleCloseEulaDialog={handleCloseEulaDialog}
        enableEula={enableEula}
        setEulaDialog={setEulaDialog}
        storageDialog={storageDialog}
        setStorageDialog={setStorageDialog}
        addWMSDialog={addWMSDialog}
        setAddWMSDialog={setAddWMSDialog}
        uploadWmsLayerFn={uploadWmsLayerFn}
      />

      {/* *** contain uploadFilesImagery on globe */}
      <main className={classes.content}>
        <div
          // className={classes.toolbar}
          id="toolbarHidden"
        />
        <div className="marginbelow">
          {measureSelected && (
            <div
              id="measureContainer"
              style={{
                right: rightDrawer ? rightDrawer - 35 : rightDrawer + 60,
              }}
            >
              <Card className={classes.root}>
                <CardContent style={{ width: "auto", minWidth: 200 }}>
                  <CloseIcon
                    className="measureClose"
                    onClick={() => {
                      setDistance(0);
                      totalDist = 0;
                      if (newMeasurePoints._pointPrimitives) {
                        newMeasurePoints.removeAll();
                        polylines.removeAll();
                        if (labels.length) {
                          labels.forEach((labelItem) => {
                            viewer.entities.remove(labelItem);
                          });
                        }
                      }
                      setMeasureSelected(false);
                      initialMeasurePoints = [];
                      initialMeasurePositions = [];
                      setMeasurePositions([]);
                      setMeasurePoints([]);
                    }}
                  />
                  <Typography gutterBottom variant="h6" component="h2">
                    Measure Tool
                  </Typography>
                  <strong>Unit: </strong>
                  <Select
                    style={{ fontSize: 14 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select1"
                    inputRef={measureRef}
                    value={measureUnit.length}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    onChange={(a, b, c) => {
                      let lengthCoe;
                      let areaCoe;
                      if (b.props.value == "m") {
                        lengthCoe = 1;
                        areaCoe = 1;
                      }
                      if (b.props.value == "km") {
                        lengthCoe = 0.001;
                        areaCoe = 1000000;
                      }
                      if (b.props.value == "miles") {
                        lengthCoe = 0.00062150403;
                        areaCoe = 2590000;
                      }
                      if (b.props.value == "ft") {
                        lengthCoe = 3.28084;
                        areaCoe = 0.0929031299;
                      }
                      if (b.props.value == "acres") {
                        lengthCoe = 3.28084;
                        areaCoe = 4047;
                      }
                      if (b.props.value == "hectares") {
                        lengthCoe = 3.28084;
                        areaCoe = 10000;
                      }
                      setMeasureUnit({
                        length: b.props.value,
                        area:
                          b.props.value == "acres" ||
                          b.props.value == "hectares"
                            ? b.props.value
                            : b.props.value + "²",
                        lengthCoe: lengthCoe,
                        areaCoe: areaCoe,
                      });
                      setDistance(0);
                      totalDist = 0;
                      if (newMeasurePoints._pointPrimitives) {
                        newMeasurePoints.removeAll();
                        polylines.removeAll();
                        if (labels.length) {
                          labels.forEach((labelItem) => {
                            viewer.entities.remove(labelItem);
                          });
                        }
                      }
                      initialMeasurePoints = [];
                      initialMeasurePositions = [];
                      setMeasurePositions([]);
                      setMeasurePoints([]);
                    }}
                  >
                    <MenuItem value="m">Meters</MenuItem>
                    <MenuItem value="km">Kilometers</MenuItem>
                    <MenuItem value="miles">Miles</MenuItem>
                    <MenuItem value="ft">Feet</MenuItem>
                  </Select>
                  {measureUnit.length != "acres" &&
                    measureUnit.length != "hectares" && (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        <strong>Distance: </strong>
                        {distance > 0 || totalDist > 0
                          ? measureUnit.length == "m"
                            ? totalDist.toFixed(5)
                            : measureUnit.length == "km"
                            ? (totalDist / 1000).toFixed(5)
                            : measureUnit.length == "miles"
                            ? (totalDist / 1609.34).toFixed(5)
                            : measureUnit.length == "ft"
                            ? (totalDist * 3.2808).toFixed(5)
                            : ""
                          : "n/a"}{" "}
                        {measureUnit.length}
                      </Typography>
                    )}
                  {/* <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    <strong>Area: </strong>
                    {area.toFixed(3) > 0 ? area.toFixed(3) : "n/a"}{" "}
                    {measureUnit.area}
                  </Typography> */}
                  {newMeasurePoints.length ? (
                    <UndoIcon
                      className="measureUndoIcon"
                      onClick={() => {
                        lastNodeRemoveMeasureToolHandler();
                      }}
                    />
                  ) : null}
                </CardContent>
              </Card>
            </div>
          )}
          <MapTopButtons
            initMeasure={() => {
              labels = [];
              newMeasurePoints = viewer.scene.primitives.add(
                new PointPrimitiveCollection()
              );
              polylines = viewer.scene.primitives.add(new PolylineCollection());
            }}
            removeMeasure={() => {
              setDistance(0);
              totalDist = 0;
              if (newMeasurePoints._pointPrimitives) {
                newMeasurePoints.removeAll();
                polylines.removeAll();
                if (labels.length) {
                  labels.forEach((labelItem) => {
                    viewer.entities.remove(labelItem);
                  });
                }
              }
            }}
            editEntity={editEntity}
            setEditEntity={setEditEntity}
            popDragPoints={popDragPoints}
            VRenable={VRenable}
            rightDrawer={rightDrawer}
            initialMeasurePoints={initialMeasurePoints}
            initialMeasurePositions={initialMeasurePositions}
            setMeasurePositions={setMeasurePositions}
            setMeasurePoints={setMeasurePoints}
            setEditSelected={setEditSelected}
            initialPoints={initialPoints}
            initialPositions={initialPositions}
            initialPositionsNew={initialPositionsNew}
            setPoints={setPoints}
            setPositions={setPositions}
            setMeasureSelected={(state) => {
              if (
                measureUnit.length === "acres" ||
                measureUnit.length === "hectares"
              ) {
                setMeasureUnit({
                  length: "m",
                  area: "m",
                  lengthCoe: 1,
                  areaCoe: 1,
                });
              }
              setMeasureSelected(state);
            }}
            measureSelected={measureSelected}
            editSelected={editSelected}
            positions={positions}
            points={points}
            Cartesian3={Cartesian3}
            //mapRef={mapRef}
            viewer={viewer}
            setLayerTypeSelected={setLayerTypeSelected}
            handleCloseLayer={handleCloseLayer}
            errorDisplay={errorDisplay}
            setEditSelectedDouble={setEditSelectedDouble}
            setSideNav={setSideNav}
            setAppState={setAppState}
            appState={appState}
            createLayerHandler={createLayerHandler}
          />

          <MapExpandButtons
            transparencyEnabled={transparencyEnabled}
            setTransparencyEnabled={setTransparencyEnabled}
            rightDrawer={rightDrawer}
            //mapRef={mapRef}
            viewer={viewer}
            showTerrain={showTerrain}
            terrainShowHandler={terrainShowHandler}
            setShowTerrain={setShowTerrain}
            buildings={buildings}
            setBuildings={setBuildings}
            tileset={tileset}
            vrCardboard={vrCardboard}
            locationIntervalFlag={locationIntervalFlag}
            isIOS={isIOS}
            navigator={navigator}
            clearInterval={() => clearInterval(locationInterval)}
            setLocationIntervalFlag={setLocationIntervalFlag}
            success={success}
            showCompBtn={showCompBtn}
            setShowCompBtn={setShowCompBtn}
            device={device}
          />

          <div ref={mapRef}>
            {showCompBtn && (
              <SplitScreen
                mapFeatures={mapFeatures}
                viewer={viewer}
                layerArray={appState.layerArray}
                filterObjectsInIdList={filterObjectsInIdList}
                filterObjectsNotInIdList1={filterObjectsNotInIdList1}
                SplitScreenUnMountFn={SplitScreenUnMountFn}
                dateInfoPanel={dateInfoPanel}
              />
            )}
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>
        {/* 360 Tests */}
        {/* {true && (
          <div
            style={{
              // backgroundColor: "blue",
              position: "fixed",
              top: "20%",
              left: device === "Mobile" ? "10%" : "30%",
              // width: "40vw",
              zIndex: 9999,
            }}
            onClick={() => {
              //setSelectedImgViewerId(k.id);
            }}
            // onClick={(e) => {
            //   if (childRef.current && !childRef.current.contains(e.target)) {
            //     setImageViewer360(false);
            //   }

            // }}
          >
            <Image360
              //VRenable={VRenable}
              //childRef1={childRef1}
              setRightDrawer={setRightDrawer}
              handleInfoDrawerOpen={handleInfoDrawerOpen}
              setMediaAccordian={setMediaAccordian}
              setResizeBtn={setResizeBtn}
              rightDrawer={rightDrawer}
              resizeBtn={resizeBtn}
              setImageViewer360={setImageViewer360}
              // selectedImage={imagesArray[0]}
              closeImageViewerHandler={closeImageViewerHandler}
              // cubeMap={imagesArray[0].cubeMap}
              setImageFolderArray={setImageFolderArray}
              authHeader={authHeader}
              dispatch={dispatch}
              LOADING_MESSAGE={LOADING_MESSAGE}
              sidePanelInfoUpdate={sidePanelInfoUpdate}
              device={device}
            />
          </div>
        )} */}
        {linkIframe && (
          <div>
            <div
              style={{
                // backgroundColor: "blue",
                position: "fixed",
                top: "20%",
                left: "30%",
                width: "40vw",
                zIndex: 9999,
              }}
              className="image-annotate-container"

              // ref={childRef}
            >
              <HoverIframe
                linkIframe={linkIframe}
                onClose={() => {
                  setLinkIframe(null);
                }}
                setResizeBtn={setResizeBtn}
                resizeBtn={resizeBtn}
              />
            </div>
          </div>
        )}
        {imageFolderArray.length > 0 &&
          // device === "Desktop" &&
          imageFolderArray.map((k, i) => {
            // Conditionally render only the last updated element

            const imagesArray = [];
            imagesArray.push(k);

            return (
              <div key={"imageViewer" + k.id}>
                {k.show && k.type === "image2D" && device === "Desktop" && (
                  <div
                    style={{
                      // backgroundColor: "blue",
                      position: "fixed",
                      top: "20%",
                      left: "30%",
                      width: "40vw",
                      zIndex: selectedImgViewerId === k.id ? 9999 : 9998,
                    }}
                    className="image-annotate-container"
                    onClick={() => {
                      setSelectedImgViewerId(k.id);
                    }}
                    // ref={childRef}
                  >
                    <ImageAnnotate
                      images={imagesArray}
                      imageViewer={imageViewer}
                      // selectedImage={k[i].src}
                      VRenable={VRenable}
                      // childRef1={childRef1}
                      setImageViewer={setImageViewer}
                      setRightDrawer={setRightDrawer}
                      handleInfoDrawerOpen={handleInfoDrawerOpen}
                      setMediaAccordian={setMediaAccordian}
                      setResizeBtn={setResizeBtn}
                      rightDrawer={rightDrawer}
                      resizeBtn={resizeBtn}
                      setImageFolderArray={setImageFolderArray}
                      closeImageViewerHandler={closeImageViewerHandler}
                      saved={(annotationData) => {
                        postImgTag(annotationData)
                          .then((res) => {
                            if (res.status === 200) {
                              console.log("imageTagResp", res);
                              const arrayList = imageFolderArray.map(
                                (n) => n.id
                              );

                              const filteredList = filterObjectsInIdList(
                                appState.layerArray,
                                arrayList
                              );

                              //Remove Entities
                              mapFeatures = mapFeatures.filter((ent) => {
                                if (
                                  ent.entity instanceof Model &&
                                  arrayList.includes(ent.id)
                                ) {
                                  viewer.scene.primitives.remove(ent.entity);

                                  return false; // Remove this entity from the array
                                }
                                return true; // Keep this entity in the array
                              });

                              //Add Entities
                              filteredList.forEach((layer) => {
                                const matchingObj = annotationData.images.find(
                                  (obj) => obj.id === layer.id
                                );

                                if (matchingObj) {
                                  layer.image_taggings = matchingObj.regions;
                                  loadFrustum(layer);
                                }
                              });

                              dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                  message: "Image annotation success",
                                  severity: "success",
                                  isOpen: true,
                                },
                              });
                            } else {
                              dispatch({
                                type: SET_MESSAGE,
                                payload: {
                                  message: "Image annotation failed",
                                  severity: "error",
                                  isOpen: true,
                                },
                              });
                            }
                          })
                          .catch((error) => {
                            dispatch({
                              type: SET_MESSAGE,
                              payload: {
                                message: "Image annotation failed",
                                severity: "error",
                                isOpen: true,
                              },
                            });
                          });
                      }}
                      sidePanelInfoUpdate={sidePanelInfoUpdate}
                    />
                  </div>
                )}
                {k.show && k.type === "image3D" && (
                  <div
                    style={{
                      // backgroundColor: "blue",
                      position: "fixed",
                      top: "20%",
                      left: device === "Mobile" ? "10%" : "30%",
                      // width: "40vw",
                      zIndex: selectedImgViewerId === k.id ? 9999 : 9998,
                    }}
                    onClick={() => {
                      setSelectedImgViewerId(k.id);
                    }}
                  >
                    <Image360
                      appStateProjects={appState.projects}
                      VRenable={VRenable}
                      childRef1={childRef1}
                      setRightDrawer={setRightDrawer}
                      handleInfoDrawerOpen={handleInfoDrawerOpen}
                      setMediaAccordian={setMediaAccordian}
                      setResizeBtn={setResizeBtn}
                      rightDrawer={rightDrawer}
                      resizeBtn={resizeBtn}
                      setImageViewer360={setImageViewer360}
                      selectedImage={imagesArray[0]}
                      closeImageViewerHandler={closeImageViewerHandler}
                      cubeMap={imagesArray[0].cubeMap}
                      setImageFolderArray={setImageFolderArray}
                      authHeader={authHeader}
                      // dispatch={dispatch}
                      imgLoader={imgLoader}
                      setImgLoader={setImgLoader}
                      LOADING_MESSAGE={LOADING_MESSAGE}
                      sidePanelInfoUpdate={sidePanelInfoUpdate}
                      device={device}
                      saved={(annotationData, layerId) => {
                        const payLoad = {
                          images: annotationData,
                          is360: true,
                          layerId: layerId,
                        };
                        console.log("payLoad360", payLoad);
                        postImgTag(payLoad).then((res) => {
                          if (res.status === 200) {
                            console.log("imageTagResp", res);
                            dispatch({
                              type: SET_MESSAGE,
                              payload: {
                                message: "Image annotation success",
                                severity: "success",
                                isOpen: true,
                              },
                            });
                          } else {
                            dispatch({
                              type: SET_MESSAGE,
                              payload: {
                                message: "Image annotation failed",
                                severity: "error",
                                isOpen: true,
                              },
                            });
                          }
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        {imageViewer && imageFolderArray.length > 0 && device === "Mobile" && (
          <Backdrop
            className={classes.backdropImage}
            open={true}
            invisible={true}
            onClick={(e) => {
              if (
                childRef.current &&
                !childRef.current.contains(e.target) &&
                !childRef1.current.contains(e.target)
              ) {
                setImageFolderArray([]);
                setImageViewer(false);
                const elements = document.querySelectorAll([
                  ".image-annotate-container .MuiButtonBase-root",
                ]);

                if (elements.length > 0) {
                  for (let i = elements.length - 1; i >= 0; i--) {
                    if (elements[i].innerText === "Save") {
                      elements[i].click();
                    }
                  }
                }
              }
            }}
          >
            <div
              style={{
                width: "70%",
              }}
              className="image-annotate-container"
              ref={childRef}
            >
              <Lightbox
                image={imageFolderArray[imageIndexState].src}
                title={imageFolderArray[imageIndexState].name}
                onClose={() => {
                  setImageFolderArray([]);
                  temImgArray = [];
                  setImageViewer(false);
                }}
              />
            </div>
          </Backdrop>
        )}
      </main>
    </div>
  );
};
const Map = (props) => (
  <DataContext.Consumer>
    {(mainConsumer) => <MapFn {...mainConsumer} {...props} />}
  </DataContext.Consumer>
);

export default Map;
